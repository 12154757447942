import numeral from 'numeral';

export function formatComma(value: number) {
  if (value === null || value === undefined) {
    return "";
  }
  return value.toLocaleString();
}

const OverviewPercentValuesIndexName =  [
  "기간수익률",
  "연수익률(ret)",
  "연변동성(std)",
  "최대낙폭(MDD)",
  "연매매회전율",
  "매매수수료비중",
  "증권거래세비중",
  "보유기간과제비중",
  "무위험이자율",
  "세후수익율",
];

export const ConvertToPercentTextByName = (rows) => {
  if (!rows) {
    return rows
  }
  return rows.map((v) => {
    if (OverviewPercentValuesIndexName.includes(v.index)) {
      const row = {
        index: v.index
      }
      for (const col of Object.keys(v)) {
        if (col !== "index") {
          row[col] = `${(v[col] * 100).toFixed(2)} %`;
        }
      }
      return row;
    } else {
      return v;
    }
  });
}

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
  return numeral(number / 100).format('0.00%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
