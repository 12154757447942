import { FormGroup, FormLabel, Stack, TextField } from "@mui/material";

type ValueItem = any;
type DataProps = {
  item: ValueItem;
  onChange: (e) => void;
};

export const SeriesDataOption = ({ item, onChange }: DataProps) => {
  return (
    <>
      <Stack spacing={1} sx={{ my: 1 }}>
        <div style={{ height: "40px", padding: "4px 0px" }}>
          <FormLabel htmlFor="line-color">stroke-color </FormLabel>
          <input
            id="line-color"
            type="color"
            value={item.color}
            onChange={(e) => {
              onChange({ ...item, color: e.target.value });
            }}
          />
        </div>
        <FormGroup row>
          <TextField
            type={"number"}
            size="small"
            label="stroke-width"
            value={item.stroke}
            onChange={(e) => {
              onChange({ ...item, stroke: e.target.value });
            }}
          />
        </FormGroup>
        <FormGroup row>
          <TextField
            type="text"
            size="small"
            label="Formula"
            value={item.formula}
            onChange={(e) => {
              onChange({ ...item, formula: e.target.value });
            }}
          />
        </FormGroup>
      </Stack>
    </>
  );
};

export default SeriesDataOption;