import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
import Page from "../Page";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

export default function Page500() {
  return (
    <RootStyle title="프리즘 백테스트 시스템">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h3" paragraph>
            서비스에 접속할 수 없습니다.
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            현재 서비스에 접속할 수 없습니다. 잠시 후 다시 이용 부탁 드리며
            이용에 불편을 드려 죄송합니다.
          </Typography>
          <Button
            size="large"
            variant="contained"
            onClick={(e) => (window.location.href = "/")}
          >
            홈으로
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}

export function ErrorBaseComponent({ errorInfo, refresh = false }) {
  return (
    <RootStyle title="프리즘 백테스트 시스템">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h5" paragraph>
            서비스 로딩중 오류가 발생했습니다.
          </Typography>
          {refresh && (
            <Button
              size="large"
              variant="contained"
              onClick={(e) => window.location.reload()}
            >
              다시 시작
            </Button>
          )}
        </Box>
      </Container>
    </RootStyle>
  );
}
