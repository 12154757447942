import {
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { useRecoilState } from "recoil";
import {
  staticBacktest2ConfigState,
  staticBacktest2StrategiesState,
} from "@states/staticBacktest2";
import _ from "lodash";

import { STATIC_STRATEGY_DEFAULT_3 } from "../../define";
import { StyledLabel } from "@components/ui/styles";

function BacktestMarketRadio() {
  const [backtestConfig, setBacktestConfig] = useRecoilState(staticBacktest2ConfigState);
  const setMarketRadio = (value) => {
    setBacktestConfig({ ...backtestConfig, market: value });
  }

  const [strategies, setStrategies] = useRecoilState(staticBacktest2StrategiesState);
  const handleChange = (e) => {
    const resetValue = [_.cloneDeep(STATIC_STRATEGY_DEFAULT_3)];
    if (!_.isEqual(resetValue, strategies)) {
      if (window.confirm("전략을 리셋 하시겠습니까??")) {
        setStrategies(resetValue);
        setMarketRadio(e.target.value);
      }
    } else {
      setMarketRadio(e.target.value);
    }
  };
  return (
    <Card sx={{ p: 3, m: 1 }}>
      <FormControl>
        <Stack direction="row">
          <StyledLabel
            sx={{ mr: 2 }}
            style={{
              display: "inline-flex",
              verticalAlign: "middle",
              alignItems: "center",
            }}
          >
            국가
          </StyledLabel>
          <RadioGroup row value={backtestConfig.market || 'kr'} onChange={handleChange}>
            <FormControlLabel
              value="kr"
              control={<Radio color="success" />}
              label="KR"
            />
            <FormControlLabel
              value="us"
              control={<Radio color="success" />}
              label="US"
            />
          </RadioGroup>
        </Stack>
      </FormControl>
    </Card>
  );
}

export default BacktestMarketRadio;
