import { atom, selector } from "recoil";
import axios from "../utils/axios";
import realAxios from "axios";
import { AssetType } from "types/common";
import { reducePriceValue } from "@api/asset";

// const splitBaseDate = (value) => value.map((v) => v.split("T")[0]);

export const valueState = atom({
  key: "fundKRValueState",
  default: 0,
});

export const fundKRChartDateState = atom({
  key: "fundKRChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const enableFilterState = atom({
  key: "fundKREnableFitlerState", 
  default: false,
})

export const familyTickerFilterState = atom({
  key: "familyTickerFilterState", 
  default: false,
})

export const selectedRowState = atom<AssetType|null>({
  key: "fundKRSelectedRow",
  default: null,
});

export const simpleOperationListState = selector({
  key: "fundKRSimpleOperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`fund/kr/simple_operations`);
    return response.data.data;
  },
});

export const paginationState = atom({
  key: "fundKRPaginationState",
  default: {
    page: 1,
    page_size: 100,
    total_count: 0,
  },
});

export const fundSearchTextState = atom({
  key: "fundKRSearchState",
  default: undefined,
});

export const fundKrSelectedQueryFilterState = atom({
  key: "fundKrSelectedQueryFilterState",
  default: {},
})

export const fundKrQueryFilterState = atom<any>({
  key: "fundKrQueryFilterState",
  default: undefined,
});

export const fundKrSelectedClass = atom<any>({
  key: "fundKrSelectedClass",
  default: {
    filter: [],
  },
});

export const fundOperationListState = selector({
  key: "fundKROperationListState",
  get: async ({ get }) => {
    const pagenation = get(paginationState);
    const search = get(fundSearchTextState);
    const filter = get(fundKrQueryFilterState);
    const familyTickerChecked = get(familyTickerFilterState);

    if (familyTickerChecked) {
      const params = { search, ...pagenation, filter, familyTickerChecked };
      const response = await axios.get(`fund/kr/operations`, {
        params: params,
      });
      return response.data;
    } else {
      const params = { search, ...pagenation, filter };
      const response = await axios.get(`fund/kr/operations`, {
        params: params,
      });
      return response.data;
    }
  },
});

const selectArtificailIndexData = async (ticker, params) => {
  try {
    const response = await axios.get(`fund/kr/artificial_prices/${ticker}`, {
      params,
    });
    const responseValue = response.data.data;
    let indexDatareduce = reducePriceValue(responseValue);
    indexDatareduce = { ...indexDatareduce, is_missing: response.data.data.is_missing}
    return indexDatareduce
  } catch (e) {
    console.error(`/// fund/kr/artificial_prices/${ticker}`, e);
  }
  return;
};

export const artificailIndexDataState = selector({
  key: "fundKRArtificailIndexDataState",
  get: async ({ get }) => {
    const selected = get(selectedRowState);
    if (selected === null) return;
    const { ticker } = selected;
    const dataParam = get(fundKRChartDateState);
    return await selectArtificailIndexData(ticker, dataParam);
  },
});

export const analysisDataState = atom({
  key: "fundKRAnalysisDataState",
  default : {load:false}
})

export const fundKRFeeState = selector({
  key: "fundKRFeeState",
  get: async ({ get }) => {    
    try {      
      const selected = get(selectedRowState);
      if (selected === null) return;
      const { ticker } = selected;
      const response = await axios.get(`fund/kr/fee/${ticker}/history/`);
      const responseObj = response.data.data;
      return responseObj;
    } catch (e) {
      return;
    }
  },
});

export const selectedAssetSummaryData = async (ticker) => {
  const response = await realAxios.all(
    ticker.map((v) => axios.get(`fund/kr/operation/${v}`))
  );
  return response.map((res: any) => res.data.data);
};

export const fundKrOperationFilterCountState = selector({
  key: "fundKrOperationFilterCountState",
  get: async ({get}) => {
    const familyTickerChecked = get(familyTickerFilterState);
    const params = { familyTickerChecked };
    const response = await axios.get("fund/kr/operation_filter_count", {
      params: params,
    });
    return response.data.data
  }
})