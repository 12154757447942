import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Box, Stack } from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

export const Header4 = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export const FundHeader = styled(Typography)(({ theme }) => ({
  ...theme.typography.h4,
  color: "red",
  marginBottom: theme.spacing(1),
}));

export const Header5Style = styled(Typography)(({ theme }) => ({
  ...theme.typography.h5,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

export const Title = ({ text }) => {
  return <Header4>{text}</Header4>;
};

export const AssetTitle = ({ ticker, name }) => (
  <>
    {ticker && (
      <Box sx={{ marginTop: 2 }}>
        <Header4>
          [{ticker}] {name}
        </Header4>
      </Box>
    )}
  </>
);

export const FundAssetTitle = ({ ticker, name, is_missing }) => (
  <>
    {ticker && (
      <Box sx={{ marginTop: 2 }}>
        <Stack direction="row" spacing={3}>
          {is_missing && (
            <FundHeader style={{ color: "red" }}>[데이터 누락 주의]</FundHeader>
          )}
          <Header4>
            [{ticker}] {name}
          </Header4>
        </Stack>
      </Box>
    )}
  </>
);

export function tabProps(index: number): Object {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 12,
  },
  "&:before": {
    display: "none",
  },
  "& ": {
    marginTop: 0,
    borderRadius: 0,
    boxShaddow: "none",
  },
  "& .Mui-expanded": {
    borderRadius: 0,
  },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  "& .MuiAccordion-root": {
    marginTop: 0,
    minHeight: "20px",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0px 0",
  },
  "& .MuiAccordionSummary-root": {
    marginTop: 0,
  },
  "& .Mui-expanded": {
    borderRadius: 0,
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderRadius: 0,

  "& ": {
    padding: 0,
  },
}));

export const CountBadge = styled(Box)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 3px;
  color: #fff;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 2px 5px;
  margin: 0;
  line-height: 18px;
  height: 20px;
`;
