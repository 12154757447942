import PropTypes from "prop-types";
// material
import { alpha, styled } from "@mui/material/styles";
import { AppBar, Toolbar, IconButton } from "@mui/material";
// components
import Iconify from "../../components/asset/Iconify";
import AccountPopover from "./AccountPopover";

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 45;
const APPBAR_DESKTOP = 45;

const RootStyle = styled(AppBar)(({ theme }) => ({
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  // [theme.breakpoints.up("lg")]: {
  //   width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  // },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("sm")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 1),
  },
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function DashboardNavbar({ onOpenSidebar }) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ color: "text.primary", left: 0 }}
        >
          <Iconify icon="eva:menu-fill" sx={{}} />
        </IconButton>
        <AccountPopover />
      </ToolbarStyle>
    </RootStyle>
  );
}
