import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { useCallback, useState } from "react";
import { generateDefaultOptions } from "./modules";
import SeriesDataOption from "./SeriesDataOptions";
import { ChartOptionProps, ValueItem } from "./type";

export const OptionContainer = ({
  config,
  value,
  onChange,
  onClose,
}: ChartOptionProps) => {
  const [chartConfig, setChartConfig] = useState<any>(
    generateDefaultOptions(config, value)
  );
  const [selectedDataItem, setSelectedDataItem] = useState(
    chartConfig.dataOptions[0].name
  );

  const handleReset = useCallback(() => {
    if (onChange) {
      onChange({});
    }
  }, [onChange]);

  const handleSubmit = () => {
    if (onChange) {
      onChange(chartConfig);
    }
  };

  const handleSelectChange = (value) => {
    setSelectedDataItem(value);
  };

  const handleConfigChange = (key: string, value: any) => {
    setChartConfig((prevState) => ({ ...prevState, [key]: value }));
  };

  const handleChangeDataOption = (targetConfig) => {
    const dataOptions = chartConfig.dataOptions.map((subConfig) =>
      subConfig.id === targetConfig.id ? targetConfig : subConfig
    );
    setChartConfig({ ...chartConfig, dataOptions });
  };

  return (
    <Box sx={{ my: 1.5, px: 2.5 }}>
      <Typography variant="h6">Global Option</Typography>
      <Stack spacing={0.5} sx={{ mt: 1 }}>
        <FormGroup row sx={{ alignItems: "center" }}>
          <FormLabel sx={{ mr: 2 }}> Shape Type </FormLabel>
          <Select
            size="small"
            value={chartConfig.type}
            sx={{ width: "100px" }}
            onChange={(e) => {
              handleConfigChange("type", e.target.value);
            }}
          >
            <MenuItem value={"line"}>line</MenuItem>
            <MenuItem value={"area"}>area</MenuItem>
          </Select>
        </FormGroup>

        <FormGroup row sx={{ alignItems: "center" }}>
          <FormLabel sx={{ mr: 1.1 }}> Stroke curve </FormLabel>

          <Select
            size="small"
            value={chartConfig.curve}
            sx={{ width: "100px" }}
            onChange={(e) => {
              handleConfigChange("curve", e.target.value);
            }}
          >
            <MenuItem value={"smooth"}>smooth</MenuItem>
            <MenuItem value={"straight"}>straight</MenuItem>
            <MenuItem value={"stepline"}>stepline</MenuItem>
          </Select>
        </FormGroup>
        <FormGroup row sx={{ alignItems: "center" }}>
          <FormLabel sx={{ mr: 4.7 }}> LogBase </FormLabel>
          <TextField
            type="number"
            size="small"
            sx={{ width: "100px" }}
            value={chartConfig.logBase}
            onChange={(e) => handleConfigChange("logBase", e.target.value)}
          ></TextField>
        </FormGroup>
      </Stack>
      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              checked={chartConfig.gridShow}
              onChange={(e) => {
                handleConfigChange("gridShow", e.target.checked);
              }}
            />
          }
          label="Show grid"
        />
      </Stack>
      <Divider />
      <Box sx={{ py: 1 }}>
        <Typography variant="h6">Data Option</Typography>
        <Select
          labelId="chart-select-series"
          id="chart-select-series"
          label="Series"
          size="small"
          fullWidth
          value={selectedDataItem}
          onChange={(e) => {
            handleSelectChange(e.target.value);
          }}
        >
          {chartConfig.dataOptions.map((item, key) => (
            <MenuItem key={key} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        {chartConfig.dataOptions
          .filter((v) => v.name === selectedDataItem)
          .map((v: ValueItem) => (
            <SeriesDataOption
              item={v}
              onChange={(e) => {
                handleChangeDataOption(e);
              }}
            />
          ))}
      </Box>
      <Stack direction="row" spacing={2} sx={{ pt: 1 }} justifyContent="end">
        <Button variant="outlined" onClick={handleReset}>
          reset
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          OK
        </Button>
      </Stack>
    </Box>
  );
};
