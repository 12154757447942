import React from "react";
import { selectedRealtyIndexTickerState } from "@states/realtyIndex";
import { useRecoilValue } from "recoil";
import IndexPanel from "./Panel/IndexPanel";
import AnalysisPanel from "./Panel/AnalysisPanel";
import { TabContents } from "@components/tab/TabPanel";

const Contents = [
  {
    label: "가상 지수",
    component: <IndexPanel />,
  },
  {
    label: "분석",
    component: <AnalysisPanel />,
  },
];

function RealtyIndexTabs() {
  const selectedRealtyIndexTicker = useRecoilValue(selectedRealtyIndexTickerState);
  return (
    <>
      {selectedRealtyIndexTicker && (
        <TabContents tabs={Contents} />
        )}
    </>
  );
}

export default RealtyIndexTabs;
