import { Box, Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import PriceLineChart from "@components/chart/PriceLineChart";
import { useRecoilValue } from "recoil";
import { analysisAnnotation } from "@utils/chart";
import { useMemo, useState } from "react";
import { cloneDeep } from 'lodash';
import { assetCompareAnalysisState, assetComparePriceState } from "@states/asset";

const AnnotationList = ({ options, onChange }) => {
  return (
    <Grid container spacing={1}>
    
      {options.map((item, key) => {
        return (
            <FormGroup key={key}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="info"
                    checked={item.checked}
                    onChange={(e) => {
                      onChange(item.name, e.target.checked );
                    }}
                  />
                }
                label={item.label}
              />
            </FormGroup>
        );
      })}
    </Grid>
  );
};

const defaultOptions = [
  { 
    name: 'mdd',
    label: '최대낙폭',
    checked: true,
  },
  {
    name: 'uwp',
    label: '최대손실',
    checked: true,
  },
  {
    name: 'uws',
    label: '최장손실',
    checked: true,
  },
  {
    name: 'high',
    label: '최고점',
    checked: true,
  },
  {
    name: 'low',
    label: '최저점',
    checked: true,
  }
];

export default function AnalyticsChart() {
  const artificailIndexData = useRecoilValue(assetComparePriceState);
  const analysisData: any = useRecoilValue(assetCompareAnalysisState); 
  const [options, setOption] = useState(cloneDeep(defaultOptions));
  
  const handleOnChange = (name: string, checked: boolean) => {
    setOption(options.map( (value) => {
      if (value.name === name) {
        value.checked = checked
      } 
      return value
    }))
  }

  const enabledNames = useMemo(()=> {
    return options.filter((o)=> o.checked).map(o=>o.name)
  }, [options]);
  
  
  if (!artificailIndexData) {
    return <></>;
  }
  if (!analysisData) {
    return <></>;
  }

  const { values, index } = artificailIndexData;
  const indexChartOptions = analysisAnnotation(analysisData.values, artificailIndexData, enabledNames);
  indexChartOptions["stroke"] = {
    width: [2,1,1,1,1,1,1,1]
  }
  
  return (
    <>
      <Box sx={{ height: 650, m: 0, my: 2 }}>
        <PriceLineChart
          showNavigator={true}
          index={index}
          data={values}
          options={indexChartOptions}
          height={400}
        />
        <AnnotationList options={options} onChange={handleOnChange}/>
      </Box>
    </>
  );
}

