import React, { ReactElement, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

type TabContentItem = {
  id?: number
  label: string | undefined
  component: ReactElement
}

type TabContentsType = TabContentItem[];

type Props = {
  tabs: TabContentsType
}

export const TabContents = ({ tabs }: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{
          display: "inline-block",
          borderBottom: 1,
          borderColor: "divider",
          width: "100%",
        }}
      >
        <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="auto" aria-label="scrollable auto tabs example">
          {tabs?.map((item, index) => (
            <Tab label={item.label} key={index} />
          ))}
        </Tabs>
      </Box>
      {tabs?.map((item, index) => (
        <TabPanel value={value} index={index} key={index}>
          {item.component}
        </TabPanel>
      ))}
    </>
  );
};

const TabPanel = ({ children, value, index, ...other }) => (
  <Box
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && <Box sx={{ pt: 1 }}>{children}</Box>}
  </Box>
);

export function simpeTabProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default TabPanel;