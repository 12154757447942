import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "@utils/axios";
import { Typography, Stack } from "@mui/material";
import Overview from "@components/Overview";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { TabContents } from "@components/tab/TabPanel";
import PriceLineChart from "@components/chart/PriceLineChart";
import Page from "@components/Page";
import { createStartAnnotation } from "@utils/chart";
import { addHyphen } from "@utils/formatTime";
import { makeName } from "@utils/proxyConfig";

async function getAssetChart(ticker, dataFreq) {
  const response = await axios.get(
    `proxy/analysis/?rf_id=EK-${ticker}&freq=${dataFreq}`
  );
  const responseObj = response.data.data.data;
  return responseObj;
}

async function getSelectedAssetOverview(ticker, market) {
  try {
    const response = await axios.get(`etf/${market}/operation/${ticker}`);
    return response.data.data;
  } catch (e) {
    return;
  }
}

type ChartDateType = {
  index: string[];
  values: any;
  option: object;
};

function DetailContents({ market, ticker }) {
  const [chartData, setChartData] = useState<ChartDateType | null>(null);
  const [overViewData, setOverViewData] = useState(null);
  const [dataFreq, setDataFreq] = useState("M");

  const fecther = async (dataFreq) => {
    const backtestDetailChartData = await getAssetChart(ticker, dataFreq);

    let chartData: object[] = [];
    const filteredCahrtData = backtestDetailChartData.timeseries.values.filter(
      (value) => value["name"].includes("asset") || value["name"] === "undl"
    );
    filteredCahrtData.map((v, i) => {
      chartData.push({
        name: makeName(v["name"], backtestDetailChartData.info),
        data: v["data"],
      });
    });
    const dates = backtestDetailChartData.timeseries.dates.map((date) =>
      addHyphen(date)
    );
    const chartOption = createStartAnnotation(
      new Date(addHyphen(backtestDetailChartData.period.asset[0])).getTime(),
      "asset 운용시작일"
    );

    setChartData({
      index: dates,
      values: chartData,
      option: chartOption,
    });
    const data = await getSelectedAssetOverview(ticker, market);
    setOverViewData(data);
  };

  useEffect(() => {
    fecther(dataFreq);
  }, [ticker, dataFreq]);

  if (!chartData) {
    return <></>;
  }

  const TAB_DATA = chartData && [
    {
      id: 0,
      component: <Overview country={market} overViewData={overViewData} />,
      label: "Overview",
    },
    {
      id: 1,
      component: (
        <>
          <Stack justifyContent="flex-end" direction="row">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={dataFreq}
              onChange={(e) => setDataFreq(e.target.value)}
            >
              <FormControlLabel value="D" control={<Radio />} label="일간" />
              <FormControlLabel value="M" control={<Radio />} label="월간" />
            </RadioGroup>
          </Stack>
          <PriceLineChart
            index={chartData.index}
            data={chartData.values}
            options={chartData.option}
            height={400}
          />
        </>
      ),
      label: "Price Index Chart",
    },
  ];
  return chartData && <TabContents tabs={TAB_DATA} />;
}

export function AssetPopUpV2({ market, ticker, asset, show, onClose }) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={show}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{`종목 상세정보 [${ticker} | ${asset.name}]`}</DialogTitle>
      <DialogContent style={{ height: 600 }}>
        {show && <DetailContents market={market} ticker={ticker} />}
      </DialogContent>
    </Dialog>
  );
}

export default function AssetDetailPage({ market }) {
  const { ticker } = useParams();
  return (
    <Page title="ETF 종목 상세보기" sx={{ minWidth: "750px" }}>
      <Stack spacing={1}>
        <Typography variant="h5" component="h1" paragraph>
          ETF 종목 상세보기
        </Typography>
        <DetailContents market={market} ticker={ticker} />
      </Stack>
    </Page>
  );
}
