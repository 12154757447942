import { atom, selector } from "recoil";
import axios from "../utils/axios";
import { AssetType } from "types/common";
import { reducePriceValue } from "@api/asset";

export const valueState = atom({
  key: "indexGlobalValueState",
  default: 0,
});

export const realtyIndexChartDateState = atom({
  key: "realtyIndexChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const selectedRealtyIndexTickerState = atom<AssetType | null>({
  key: "selectedRealtyIndexTickerState",
  default: null,
});

export const analysisDataState = atom({
  key: "realtyIndexAnalysisDataState",
  default: { load: false }
})

export const realtyIndexListState = selector({
  key: "realtyIndexListState",
  get: async ({ get }) => {
    const response = await axios.get(`realty/realty_indexes`);
    return response.data.data;
  },
});

export const realityIndexPriceState = selector({
  key: "realityIndexPriceState",
  get: async ({ get }) => {
    try {
      const selected = get(selectedRealtyIndexTickerState);
      if (selected === null) return;
      const { ticker } = selected;
      const dataParam = get(realtyIndexChartDateState);

      const response = await axios.get(`realty/realty_indexes/${ticker}/prices`, {
        params: dataParam,
      });
      const responseObj = response.data.data;
      return reducePriceValue(responseObj);
    } catch (e) {
    }
  },
});
