import React from "react";
import { selectedEtfTickerState } from "@states/etfUs";
import { useRecoilValue } from "recoil";
import UndlPanel from "./Panel/UndlPanel";
import AnalysisPanel from "./Panel/AnalysisPanel";
import { TabContents } from "@components/tab/TabPanel";

const Contents = [
  {
    label: "가상 지수",
    component: <UndlPanel />,
  },
  {
    label: "분석",
    component: <AnalysisPanel />,
  },
];

function EtfTabs() {
  const selectedEtfTicker = useRecoilValue(selectedEtfTickerState);

  return <>{selectedEtfTicker && <TabContents tabs={Contents} />}</>;
}

export default EtfTabs;
