// material
import { Typography, Stack } from "@mui/material";
import Page from "@components/Page";
import React from "react";
import MypageTab from "./MypageTab";

export default function User() {
  return (
    <Page title="마이페이지">
      <Stack spacing={3}>
        <Typography variant="h5" component="h1" paragraph>
          마이페이지
        </Typography>
        <MypageTab />
      </Stack>
    </Page>
  );
}
