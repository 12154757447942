import { Box, SxProps } from '@mui/material';

type Props = {
  sx?: SxProps
}

export default function Logo({ sx }: Props) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <img alt="RICHGO" src="/static/Frism_symbol.png" />
    </Box>
  )
}