import { Popover } from '@mui/material';
export function OptionPopover({ children, sx, open, ...other }) {
    return (
      <Popover
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.5,
            overflow: "inherit",
            boxShadow: (theme) => theme.shadows[20],
            border: (theme) => `solid 1px ${theme.palette.grey[500]}`,
            width: 200,
            ...sx,
          },
        }}
        {...other}
      >
        
        {children}
      </Popover>
    );
  }
  