import { DataGridPro } from "@mui/x-data-grid-pro";
import { ETFKR, ETFUS } from "@pages/asset/define";
import { Stack } from "@mui/material";

function Overview({ overViewData, country }) {
  if (overViewData === null) {
    return null;
  }

  const column: any = country === "kr" ? ETFKR : ETFUS;

  return (
    <Stack style={{ height: "140px", width: "100%", marginTop: "20px" }}>
      <DataGridPro
        columns={column}
        rows={[overViewData]}
        getRowId={(row) => row.ticker}
        hideFooter={true}
        density="comfortable"
        disableSelectionOnClick
      />
    </Stack>
  );
}

export default Overview;
