import { useEffect, useMemo, useState } from "react";
import CartMenu from "@components/CartMenu";
import AssetDiffView from "@pages/asset/comparison/PopupView";
import _ from "lodash";

type SideBarProps = {
  checkedAssets: object[],
  assetType: string,
  market: string,
  onChange: Function,
  assetFilter?: Function,
  enableDownload?: boolean
}

export function SideBar({ checkedAssets, assetType, market, onChange, assetFilter, enableDownload = true }: SideBarProps) {
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const [compareTicker, setCompareTicker] = useState([]);
  const [selectedData, setSelectedData] = useState<Array<any> | any>(null);
  const [marketSelect, setMarketSelect] = useState("");

  const saveKey = useMemo(() => {
    return `${market}-${assetType}`;
  }, [market, assetType]);

  useEffect(() => {
    setMarketSelect(saveKey);
  }, []);

  useEffect(() => {
    // 첫 로딩
    if (selectedData === null) {
      loadData();
    }
    if (saveKey === marketSelect) {
      const newData = _.uniqBy([...checkedAssets, ...selectedData], "ticker");
      saveData(newData);
    }
  }, [checkedAssets]);

  useEffect(() => {
    loadData();
  }, [marketSelect]);

  const handleShow = (ticker) => {
    if (ticker.length > 1) {
      if (assetFilter) {
        ticker = assetFilter(ticker)
      }
      setCompareTicker(ticker);
      setIsCompareOpen(true);
    } else {
      alert("2개이상 선택해 주세요");
    }
  };

  const handleChange = (e) => {
    if (onChange !== null) {
      onChange(e);
      saveData(e);
    }
  };

  const saveData = (objArray) => {
    if (saveKey === marketSelect) {
      setSelectedData(objArray);
      localStorage.setItem(marketSelect, JSON.stringify(objArray));
    }
  };

  const loadData = () => {
    const value = localStorage.getItem(marketSelect);
    let loadList = [];

    if (typeof value === "string") {
      loadList = JSON.parse(value) || []; // ok
    }
    setSelectedData(loadList);
  };

  return (
    <>
      <CartMenu
        data={selectedData}
        onSubmit={handleShow}
        onChange={(e) => handleChange(e)}
        marketSelect={marketSelect}
      />
      {isCompareOpen && (
        <AssetDiffView
          show={isCompareOpen}
          assetType={marketSelect.split("-")[1]}
          market={marketSelect.split("-")[0]}
          ticker={compareTicker}
          enableDownload={enableDownload}
          onClose={() => {
            setIsCompareOpen(false);
          }}
        />
      )}
    </>
  );
}
