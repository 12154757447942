import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export function ComparedTimeChart({ chartValue }) {
  const { values, index } = chartValue;
  if (!values) {
    return <></>;
  }

  const dateWindow = index.slice(index.length - 60, index.length);
  const v = values[0];
  const modifyValue = [
    {
      name: v.name,
      data: v.data.slice(v.data.length - 60, v.data.length),
    },
  ];

  const options: ApexOptions = {
    title: {},
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: 0,
              to: 100000,
              color: "#0ea600",
            },
            {
              from: -100,
              to: 0,
              color: "#FF0000",
            },
          ],
        },
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: "12px",
        colors: ["rgb(55, 61, 63)"],
      },
      offsetY: -20,
    },
    stroke: {
      show: false,
      width: 0,
      colors: ["#fff"],
    },
    tooltip: {
      x: {
        format: "yyyy.MM",
      },
      y: {
        formatter: function (val) {
          return (val * 100).toFixed(2) + "%";
        },
      },
    },
    xaxis: {
      categories: dateWindow,
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy.MM",
          month: "yyyy.MM",
          day: "MM.dd",
          hour: "HH:mm",
        },
      },
    },
    yaxis: {
      labels: {
        formatter: (v) =>
          typeof v === "number" ? (v * 100).toFixed() + "%" : v,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={modifyValue}
      type="bar"
      width="100%"
      height="400px"
    />
  );
}
