import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

// mui
import { styled } from "@mui/material/styles";
import { Typography, Box, Drawer } from "@mui/material";

// hooks
import useResponsive from "../../hooks/useResponsive";

// components
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import Logo from "../../components/asset/Logo";

import sidebarConfig from "./SidebarConfig";
import { DRAWER_WIDTH } from "./define";


const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const SideHeader = () => (
  <Box sx={{ px: 2.5, py: 2.5 }}>
    <Box
      component={RouterLink}
      to="/"
      style={{ textDecoration: "none" }}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Logo />
      <Typography variant="h4" sx={{ ml: 2, color: "black" }}>
        BTS
      </Typography>
    </Box>
  </Box>
)

const SideMenu = () => {
  return (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <SideHeader/>
      <NavSection navConfig={sidebarConfig} />
    </Scrollbar>
  )
}

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <RootStyle>
      { (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          <SideMenu/>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "solid",
            },
          }}
        >
          <SideMenu/>
        </Drawer>
      )}
    </RootStyle>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

