import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { analysisDataState, selectedEtfTickerState } from "@states/etfUs";
import AnalysisDetail from "@pages/asset/detail/AnalysisDetail";
import { DateRange } from "@components/DateRange";
import { getAnalysis } from "@api/asset";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";

function AnalysisPanel() {
  const selectedEtfTicker = useRecoilValue(selectedEtfTickerState);
  const [analysisData, setAnalysisData] = useRecoilState(analysisDataState);
  const [movingAvgChecked, setMovingAvgChecked] = useState(false);

  const handleSubmit = async (date) => {
    const data = await getAnalysis(
      selectedEtfTicker?.ticker,
      "etf",
      "us",
      date.begin,
      date.end,
      movingAvgChecked
    );
    setAnalysisData(data);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <DateRange onSubmit={handleSubmit} />
        </Grid>
        <Grid item>
          <FormGroup sx={{ marginLeft: "5px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="info"
                  checked={movingAvgChecked}
                  onChange={(e) => {
                    setMovingAvgChecked(e.target.checked);
                  }}
                />
              }
              label={"이동평균선"}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <AnalysisDetail
        ticker={selectedEtfTicker?.ticker}
        analysisData={analysisData}
      />
    </>
  );
}

export default AnalysisPanel;
