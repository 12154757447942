import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
} from "@mui/material";
import {
  usEtfAssetClassFilterState,
  usEtfSubFilterState,
  usEtfQueryFilterState,
  usEtfSelectedQueryFilterState,
  enableFilterState
} from "@states/etfUs";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { cloneDeep } from "lodash";
import { Accordion, AccordionDetails, AccordionSummary, CountBadge } from "@pages/asset/styles";
import { styled } from "@mui/material/styles";
import { Suspense } from "react";
import {LoadingContainer} from "@components/LoadingScreen";
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

type AccordionProps = {
  open: boolean
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SimpleAccordian  = styled(Box)<AccordionProps>`
  ${({open}) => open ? "": "height: 0"};
  overflow: hidden;
  transition: height 0.5s ease;
`

function SubCategoryFilter({ filter }) {
  const [queryFilter, setQueryFilter] = useRecoilState(usEtfSelectedQueryFilterState);
  
  const handleChange = (category) => (e) => {
    /**
     * todo 코드 개선 필요
     */
    const filterParam = cloneDeep(queryFilter);
    if (e.target.checked) {
      if (filterParam[category]) {
        filterParam[category].push(e.target.name);
      } else {
        filterParam[category] = [e.target.name];
      }
    } else {
      if (filterParam[category]) {
        const index = filterParam[category].indexOf(e.target.name);
        if (index > -1) {
          filterParam[category].splice(index, 1);
          if (filterParam[category].length === 0) delete filterParam[category];
        }
      }
    }

    setQueryFilter(filterParam);
  };

  return (
    <>
      {Object.keys(filter).map((category) => (
        <Box sx={{ px: 0, m: 0, borderBottom: 1, borderColor: "divider" }}>
          <Box sx={{ mx: 1.5, my: 0.5 }}>
            <Typography variant="overline">
              {category.replace(/_/g, " ")}
            </Typography>
          </Box>
          <Stack sx={{ px: 2, mt: 0, maxHeight: 150, overflow: "scroll" }}>
            {Object.keys(filter[category]).map((filterName) => (
                <Stack direction="row" justifyContent="space-between"  sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}>
                  <FormControlLabel
                    label={filterName}
                    value={filterName}
                    sx={{ fontSize: 1 }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{ padding: "5px" }}
                        name={filterName}
                        onChange={handleChange(category)}
                      />
                    }
                  />
                  <CountBadge>{filter[category][filterName]}</CountBadge>
                </Stack>
            ))}
          </Stack>
        </Box>
      ))}
    </>
  );
}

function AssetClassFilterContent() {
  const [queryFilters, setQeuryFilter] = useRecoilState(usEtfSelectedQueryFilterState);
  const assetClassFilters = useRecoilValue(usEtfAssetClassFilterState);
  const usETtfSubFilter = useRecoilValue(usEtfSubFilterState);

  const handleChange = (e) => {
    setQeuryFilter({ asset_class: e.target.value });
  };
  
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={queryFilters?.asset_class || ""}
        onChange={handleChange}
      >
        <Stack>
          {Object.keys(assetClassFilters).map((assetClass, i) => (
            <Box key={i}>
              <Stack
                direction="row"
                justifyContent="space-between"
                sx={{
                  borderBottom: 1,
                  borderColor: "divider",
                  width: "100%",
                  p: 0.5,
                  px: 1.5,
                }}
              >
                <FormControlLabel
                  label={assetClass}
                  value={assetClass}
                  control={<Radio size="small" />}
                />
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    verticalAlign: "middle",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", color: "gray" }}
                  >
                    {assetClassFilters[assetClass]}
                  </Typography>
                </Box>
              </Stack>
              {usETtfSubFilter[assetClass] && (
                <SimpleAccordian  className="open"
                  open={queryFilters?.asset_class === assetClass}
                >
                  <SubCategoryFilter filter={usETtfSubFilter[assetClass]} />
                </SimpleAccordian>
              )}
            </Box>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

function AssetClassFilter() {
  const [enableFilter, setEnableFilter] = useRecoilState(enableFilterState);
  const setQueryFilter = useSetRecoilState(usEtfQueryFilterState);
  const [selectedEtfTicker, setSelectedEtfTicker] = useRecoilState(
    usEtfSelectedQueryFilterState
  );
  const handleReset = () => {
    setQueryFilter({});
    setSelectedEtfTicker({});
  };
  const handleSubmit = () => {
    setQueryFilter(selectedEtfTicker);
    setEnableFilter(false);
  };
  const handleClose = () => {
    setEnableFilter(false);
  };
  return (
    <Dialog
      onClose={handleClose}
      open={enableFilter}
      hideBackdrop={true}
      PaperComponent={PaperComponent}
      sx={{ maxWidth: "310px", my: "auto", maxHeight: "650px" }}
      aria-labelledby="draggable-dialog-title"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <DialogTitle style={{ cursor: "move" }} sx={{ m: "auto", mb: 2 }}>
          상세검색
        </DialogTitle>
        <Icon
          icon={closeFill}
          onClick={handleClose}
          width={20}
          height={20}
          style={{ marginRight: 10, marginTop: 14 }}
        />
      </Stack>
      <Accordion sx={{ mx: 1, mt: 1 }} defaultExpanded>
        <AccordionSummary sx={{ margin: 0 }}>
          <Typography>테마</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Suspense fallback={<LoadingContainer />}>
            <AssetClassFilterContent />
          </Suspense>
        </AccordionDetails>
      </Accordion>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        sx={{ my: 2, px: 1 }}
      >
        <Grid item>
          <Button
            variant="contained"
            size="medium"
            sx={{ width: "100%" }}
            onClick={handleSubmit}
          >
            검색결과 보기
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="medium"
            sx={{ width: "100%" }}
            onClick={handleReset}
          >
            조건 초기화
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AssetClassFilter;
