import React, { useState } from "react";
import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { useSetRecoilState, useRecoilValue } from "recoil";
import {
  selectedRealtyIndexTickerState,
  realtyIndexListState,
} from "@states/realtyIndex";
import { COLUMNS } from "@pages/asset/define";

import { Toolbar } from "./Toolbar";
import useSearchHook from "../../../detail/useSearchHook";
import { Grid } from "@mui/material";
// import OperationDownload from "../OperationDownload";

function RealityIndexTable({ onChangeChecked, onSelectAsset }) {
  const [searchText, setSearch] = useState("");
  const setSelectedTicker = useSetRecoilState(selectedRealtyIndexTickerState);
  const operationList = useRecoilValue(realtyIndexListState);
  const [gridData, setGridData] = useState(operationList);

  const [updateSearch] = useSearchHook(operationList, setSearch, setGridData);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={6} md={4}>
          <Toolbar
            text={searchText}
            onChangeText={updateSearch}
            market={""}
          />
        </Grid>
        {/* <Grid item>
          <OperationDownload />
        </Grid> */}
      </Grid>
      <AssetInformationGrid
        market={"REALTYINDEX"}
        onSelectAsset={(asset) => {
          setSelectedTicker(asset);
          if (onSelectAsset) {
            onSelectAsset(asset);
          }
        }}
        columns={COLUMNS.REALTYINDEX}
        data={gridData}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export default RealityIndexTable;
