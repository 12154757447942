import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  staticBacktest2BacktestDetailState,
  staticBacktest2MemoState,
  staticBacktest2PortPriceIndexState,
  staticBacktest2OverviewState,
  staticBacktest2YearlyReturnState,
  staticBacktest2PortDrawDownState,
  staticBacktest2TotalCorrelationDailyState,
  staticBacktest2TotalCorrelationWeeklyState,
  staticBacktest2TotalCorrelationMonthlyState,
  staticBacktest2AssetCorrelationDailyState,
  staticBacktest2AssetCorrelationWeeklyState,
  staticBacktest2AssetCorrelationMonthlyState,
  staticBacktest2LoadingState
} from "@states/staticBacktest2";
import { IResBacktest2 } from "types/backtest2";
import { ConvertToPercentTextByName } from "@utils/formatNumber";

export const ResultLoader = () => {
  const setBacktestMemo = useSetRecoilState(staticBacktest2MemoState);
  const setPortPriceIndex = useSetRecoilState(staticBacktest2PortPriceIndexState);
  const setOverview = useSetRecoilState(staticBacktest2OverviewState);
  const setYearlyReturn = useSetRecoilState(staticBacktest2YearlyReturnState);
  const setPortDrawDown = useSetRecoilState(staticBacktest2PortDrawDownState);
  const setDynamicLoading = useSetRecoilState(staticBacktest2LoadingState);
  const setTotalCorrelationDaily = useSetRecoilState(staticBacktest2TotalCorrelationDailyState);
  const setTotalCorrelationWeekly = useSetRecoilState(staticBacktest2TotalCorrelationWeeklyState);
  const setTotalCorrelationMonthly = useSetRecoilState(staticBacktest2TotalCorrelationMonthlyState);
  const setAssetCorrelationDaily = useSetRecoilState(staticBacktest2AssetCorrelationDailyState);
  const setAssetCorrelationWeekly = useSetRecoilState(staticBacktest2AssetCorrelationWeeklyState);
  const setAssetCorrelationMonthly = useSetRecoilState(staticBacktest2AssetCorrelationMonthlyState);
  const backtestDetail = useRecoilValue(staticBacktest2BacktestDetailState);

  useEffect(() => {
    if (backtestDetail) setBacktestResultData(backtestDetail);
  }, [backtestDetail]);

  function setBacktestResultData(backtestData: IResBacktest2) {
    setBacktestMemo(backtestData.warn);
    
    if (backtestData.result_timeseries) {
      setPortPriceIndex(backtestData.result_timeseries);
    }
    if (backtestData.performance) {
      const analysis_table = backtestData.performance;
      const overview = ConvertToPercentTextByName(analysis_table);
      setOverview(overview);
    }
    if (backtestData.yearly) {
      setYearlyReturn(backtestData.yearly);
    }
    if (backtestData.drawdown) {
      setPortDrawDown(backtestData.drawdown);
    }
    if (backtestData.st_corr_m) {
      setTotalCorrelationMonthly(backtestData.st_corr_m);
    }
    if (backtestData.st_corr_w) {
      setTotalCorrelationWeekly(backtestData.st_corr_w);
    }
    if (backtestData.st_corr_d) {
      setTotalCorrelationDaily(backtestData.st_corr_d);
    }
    if (backtestData.as_corr_m) {
      setAssetCorrelationMonthly(backtestData.as_corr_m);
    }
    if (backtestData.as_corr_w) {
      setAssetCorrelationWeekly(backtestData.as_corr_w);
    }
    if (backtestData.as_corr_d) {
      setAssetCorrelationDaily(backtestData.as_corr_d);
    }
    setDynamicLoading(false)

  }
  return <></>;
};
