import React, { useState, useEffect } from "react";
import { Stack } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
import { Icon } from "@iconify/react";
import MIconButton from "@components/button/MIconButton";
import { useSnackbar } from "notistack";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { ColumnSettingToolbar } from "@components/grid/ColumnSettingToolbar";

const MAX_SELECT_ROW = 20;

const MARKET_DEFINE = {
  ETFKR: "국내 ETF",
  ETFUS: "미국 ETF",
  FUNDKR: "국내 펀드",
  INDEXGLOBAL: "지수 종목",
  REALTYINDEX: "부동산 지수",
}

type Props = {
  onSelectAsset: any;
  columns: any;
  data: any;
  loading?: boolean | undefined;
  pagination?: {} | undefined;
  onChangeChecked?: (e)=> void;
  market: any;
}

export function AssetInformationGrid({
  onSelectAsset,
  columns,
  data,
  loading = false,
  pagination = {},
  onChangeChecked,
  market,
}: Props) {
  const [checked, setChecked] = useState<string[]>([]);
  const [newColumns, setNewColumns] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const userApi = useGridApiRef()

  useEffect(() => {
    if (onChangeChecked) {
      const filteredRow = data
        .filter((v) => checked.includes(v.ticker))
        .slice(0, MAX_SELECT_ROW);
      onChangeChecked(filteredRow);
    }
  }, [checked]);
  
  useEffect(()=> {
    setNewColumns(columns)
  },[columns])
  
  const handleSave = () => {
    const columnInfo = userApi.current.getAllColumns();
    const savedColumns = columnInfo.map((data, idx) => ({
      order: idx,
      field: data.field,
      width: data.computedWidth,
      hide: data.hide,
    }))
    localStorage.setItem(market, JSON.stringify(savedColumns))
    enqueueSnackbar(`${MARKET_DEFINE[market]}의 Column 세팅이 저장되었습니다.🥳`, {
      variant: "success",
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      ),
    });
  }
  
  const handleLoad = () => {
    const arr: Array<object> = [];
    
    let savedColumOrder ;
    const value = localStorage.getItem(market)
    if (typeof value === "string") {
      savedColumOrder = JSON.parse(value) || []; // ok
    }
    

    let updateNewColumn = savedColumOrder;

    if (savedColumOrder === null) {
      updateNewColumn = columns;
      setNewColumns(updateNewColumn);
      return;
    }
    const defineColumnKey = columns.map((obj) => obj.field);
    const updateRow = savedColumOrder.filter((obj) => defineColumnKey.includes(obj.field));
    const updateColumn = columns.map((data) => {
      const target = updateRow.filter((obj) => obj.field === data.field)[0];
      if (target.field === data.field){
        arr.push({
          ...data,
          order: target.order,
          width: target.width,
          hide: target.hide,
        });
      }
      return arr[arr.length - 1];
    });
    const sortedUpdateColumn = updateColumn.sort(function (a,b){
      return a.order - b.order
    })

    updateNewColumn = sortedUpdateColumn;
    setNewColumns(updateNewColumn);
  }

  const handleReset = () => {
    setNewColumns(columns);
    localStorage.removeItem(market)
  }

  const CSSettingToolbar = ()=>(
    <ColumnSettingToolbar 
      onColumnsLoad={()=> handleLoad() }
      onColumnsSave={()=> handleSave() }
      onColumnsReset={()=> handleReset() }
      />
  )

  return (
    <Stack style={{ height: "550px", width: "100%" }} spacing={1}>
      <DataGridPro
        initialState={{ pinnedColumns: { left: ['ticker'] } }}
        apiRef={userApi} 
        loading={loading}
        rows={data}
        columns={newColumns}
        getRowId={(row) => row.ticker}
        onRowClick={(param) => onSelectAsset(param.row, param)}
        density="compact"
        components={{ Toolbar: CSSettingToolbar }}
        selectionModel={checked}
        onSelectionModelChange={(model, detail) => {
          setChecked(model as string[]);
        }}
        {...pagination}
      />
    </Stack>
  );
}

export default AssetInformationGrid;
