import { useSetRecoilState } from "recoil";
import {
  staticBacktest2StrategiesState,
} from "@states/staticBacktest2";
import {
  Stack,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import { StyledLabel } from "@components/ui/styles";
import producer from "immer";

export default function BacktestOption({ strategy, strategyIndex }) {
  const setStrategies = useSetRecoilState(staticBacktest2StrategiesState);
  return (
    <Stack spacing={2}>
      <StyledLabel>리밸런싱 방식</StyledLabel>
      <Select
        sx={{width:"200px"}}
        labelId="rebal-type-select-label"
        id="rebal-type-select"
        size="small"
        value={strategy.rebal_method}
        onChange={(e) => {
          setStrategies((prevState) =>
            producer(prevState, (draft) => {
              draft[strategyIndex]["rebal_method"] = e.target.value;
              if (draft[strategyIndex]["rebal_method"] === "Dynamic") {
                draft[strategyIndex]["threshold"] = 8;
              }
            })
          );
        }}
      >
        <MenuItem value="Static">정기</MenuItem>
        <MenuItem value="Dynamic">수시</MenuItem>
        <MenuItem value="None">없음</MenuItem>
      </Select>
      {strategy.rebal_method === "Static" && (
        <Select
          sx={{width:"200px"}}
          labelId="rebal-type-select-label"
          id="rebal-type-select"
          size="small"
          value={strategy.rebal_freq}
          onChange={(e) =>
            setStrategies((prevState) =>
              producer(prevState, (draft) => {
                draft[strategyIndex]["rebal_freq"] = e.target.value;
              })
            )
          }
        >
          <MenuItem value="M">월</MenuItem>
          <MenuItem value="Q">분기</MenuItem>
          <MenuItem value="Y">년</MenuItem>
        </Select>
      )}
      {strategy.rebal_method === "Dynamic" && (
        <>
          <StyledLabel>임계치</StyledLabel>
          <TextField
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            value={strategy.threshold}
            size="small"
            type="number"
            sx={{ width: "100%" }}
            onChange={(e) =>
              setStrategies((prevState) =>
                producer(prevState, (draft) => {
                  draft[strategyIndex]["threshold"] = Number(e.target.value);
                })
              )
            }
          />
        </>
      )}
    </Stack>
  );
}
