import React, { Suspense, useState } from "react";
// @material
import { Stack, Box } from "@mui/material";

// components
import AssetClassFilter from "./AssetListViewer/AssetClassFilter";
import AssetViewerTable from "./AssetListViewer/AssetTable";
import AssetViewerHeader from "./Header/Title";
import AssetViewerTabs from "./Tabs";
import Page from "@components/Page";
import LoadingScreen from "@components/LoadingScreen";
import { SideBar } from "../comparison/SideBar";
import { PageTitle } from "@components/ui/styles";

// ----------------------------------------------------------------------

function FundAssetOperationsPage() {
  const [checkedAssets, setCheckedAsset] = useState<Array<Object>>([]);

  return (
    <>
      <Page title="펀드 종목 탐색">
        <Stack spacing={2}>
          <PageTitle>펀드 종목 탐색</PageTitle>
          <Stack direction="row" sx={{ pl: -10 }}>
            <AssetClassFilter />
            <Box sx={{ width: "100%" }}>
              <Stack spacing={2}>
                <AssetViewerTable
                  onSelectAsset={(e) => {
                    setCheckedAsset([e, ...checkedAssets]);
                  }}
                />
                <Suspense fallback={<LoadingScreen />}>
                  <AssetViewerHeader />
                  <AssetViewerTabs />
                </Suspense>
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <SideBar
          assetType="fund"
          market="kr"
          checkedAssets={checkedAssets.map((v) => {
            return { ...v, name: v["종목명"] };
          })}
          onChange={setCheckedAsset}
        />
      </Page>
    </>
  );
}

export default React.memo(FundAssetOperationsPage);
