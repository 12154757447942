import { useRecoilValue } from "recoil";
import { selectedIndexTickerState } from "@states/indexGlobal";
import { AssetTitle } from "@pages/asset/styles"

function Header({ name = "종목명" }) {
  const selectedRow = useRecoilValue(selectedIndexTickerState);
  return (
    selectedRow && (
      <AssetTitle
        ticker={selectedRow.ticker}
        name={selectedRow[name]}
      />
    )
  );
}

export default Header;
