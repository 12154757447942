import { useEffect } from "react";
import { Box } from "@mui/material";
import { Stack } from "@mui/material";
import { useRecoilValue } from "recoil";
import { useSetRecoilState } from "recoil";
import { assetCompareTicker, assetInformationState } from "../../../states/asset";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { CSToolbar } from "@components/grid/CSToolbar";

export default function Information({ ticker, market, assetType }) {
  const setValueState = useSetRecoilState(assetCompareTicker);

  useEffect(() => {
    setValueState(ticker);
  }, []);

  const gridData = useRecoilValue(
    assetInformationState({ market, assetType, ticker })
  );

  if (!gridData.length) {
    return <></>;
  }

  let columns = gridData.map((data) => ({
    field: data["ticker"],
    headerName: data["ticker"],
    width: 200,
  }));
  const overViewColumns = [
    { field: "index", headerName: "ticker", width: 150, type: "number" },
    ...columns,
  ];

  const formatData = Object.keys(gridData[0]).map((value) => {
    let temp = {};
    gridData.forEach((data) => {
      let c = data["ticker"];
      temp[c] = data[value];
    });

    return temp;
  });
  const indexData = Object.keys(gridData[0]).map((data) => ({ index: data }));
  const rows = formatData
    .map((data, idx) => ({ ...data, ...indexData[idx] }))
    .filter((value) => value.index !== "ticker");

  return (
    <Box sx={{ height: 400, m: 0, my: 2 }}>
      <Stack style={{ height: "500px", width: "100%" }} spacing={1}>
        <DataGridPro
        initialState={{ pinnedColumns: { left: ['index'] } }}
          components={{ Toolbar: CSToolbar }}
          columns={overViewColumns}
          rows={rows}
          density="compact"
          getRowId={(row) => row.index}
        />
      </Stack>
    </Box>
  );
}
