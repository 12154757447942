import {
  useRecoilState,
  useRecoilValue,
  useRecoilRefresher_UNSTABLE,
  useRecoilValueLoadable,
  Loadable,
} from "recoil";
import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import {
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { DataGridPro, GridColDef, gridClasses } from "@mui/x-data-grid-pro";
import {
  ALL_USER,
  staticBacktest2HistoriesQueryUser,
  staticBacktest2HistoriesQueryPage,
  staticBacktest2HistoriesQueryPageSize,
  staticBacktest2HistoriesQuery,
} from "@states/staticBacktest2";
import useAuth from "@hooks/useAuth";
import axios from "@utils/axios";
import { CSToolbar } from "@components/grid/CSToolbar";
import { IPagination } from "types/api";
import { IResBacktestV2History } from "types/backtest2";

export default function BacktestHistoryDialog({ open, onSelect, onClose }) {
  const { user: me } = useAuth();
  const [user, setUser] = useRecoilState(staticBacktest2HistoriesQueryUser);
  const [page, setPage] = useRecoilState(staticBacktest2HistoriesQueryPage);
  const [pageSize, setPageSize] = useRecoilState(staticBacktest2HistoriesQueryPageSize);

  useEffect(() => {
    setUser(me?.email);
  }, []);

  const backtest2HistoriesLoadable: Loadable<IPagination<IResBacktestV2History>> = useRecoilValueLoadable<IPagination<IResBacktestV2History>>(
    staticBacktest2HistoriesQuery
  );
  const backtest2Histories: IPagination<IResBacktestV2History> = backtest2HistoriesLoadable.contents;

  //https://mui.com/x/react-data-grid/pagination/#basic-implementation
  const [rowCountState, setRowCountState] = useState(
    backtest2Histories.total || 0,
  );
  useEffect(() => {
    setRowCountState((prevRowCountState) =>
    backtest2Histories.total !== undefined
        ? backtest2Histories.total
        : prevRowCountState,
    );
  }, [backtest2Histories.total, setRowCountState]);

  const backtest2HistoriesRefresher = useRecoilRefresher_UNSTABLE(staticBacktest2HistoriesQuery);

  const backtestHistoryColumns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "data_freq",
      headerName: "주기",
      width: 70,
      type: "string",
      renderCell: (params) => (
        <Chip
          label={params.row.config.data_freq}
          size="small"
          color="secondary"
          variant="outlined"
        />
      ),
    },
    { field: "name", headerName: "이름", width: 150 },
    { field: "bt_start", headerName: "시작날짜", width: 100, valueGetter: (params) => params.row.config.bt_start },
    { field: "bt_end", headerName: "종료날짜", width: 100, valueGetter: (params) => params.row.config.bt_end },
    { field: "cost", headerName: "거래비용(%)", width: 100, valueGetter: (params) => `${params.row.config.cost * 100}%` },
    { field: "benchmark", headerName: "벤치마크 지수", width: 120, valueGetter: (params) => params.row.config.benchmark },
    {
      field: "strategies-assets",
      headerName: "전략-포트폴리오",
      width: 300,
      renderCell: (params) => (
        <List className="flex">
          {params.row.config.strategies.map((strategy, index) => (
            <ListItem key={`${index}`}>{JSON.stringify(strategy.assets)}</ListItem>
          ))}
        </List>
      ),
    },
    {
      field: "execution_time",
      headerName: "소요시간(초)",
      width: 100,
      valueGetter: (params) => params.row?.execution_log?.execution_time,
    },
    {
      field: "started_at",
      headerName: "시작시간",
      width: 150,
      valueGetter: (params) => params.row?.execution_log?.started_at,
      renderCell: (params) =>
        params.value ? moment(new Date(params.value), "h:mm:ss A")
          .format("YYYY-MM-DD HH:mm:ss"): null,
    },
    {
      field: "ended_at",
      headerName: "종료시간",
      width: 150,
      valueGetter: (params) => params.row?.execution_log?.ended_at,
      renderCell: (params) =>
        params.value ? moment(new Date(params.value), "h:mm:ss A")
          .format("YYYY-MM-DD HH:mm:ss") : null,
    },
    {
      field: "created_at",
      headerName: "실행일시",
      width: 150,
      renderCell: (params) =>
        moment(new Date(params.value), "h:mm:ss A")
          .add(9, "hour")
          .format("YYYY-MM-DD HH:mm:ss"),
    },
    { field: "user", headerName: "사용자", width: 150, type: "string" },
    ... (user !== ALL_USER) ? [{
      field: "action",
      headerName: "삭제",
      width: 100,
      renderCell: (params) =>
        (params.row.user === user) ? (
          <Button
            variant="contained"
            color="error"
            size="small"
            onClick={(e) => {
              const deleteConfirm = window.confirm(
                `"${params.row.name}" 기록을 삭제하시겠습니까?`
              );
              if (deleteConfirm) {
                deleteBacktestHistory(params.row.id);
              }
              e.stopPropagation();
            }}
          >
            삭제
          </Button>
        ) : null,
    }] : [],
  ];

  const deleteBacktestHistory = async (id: number) => {
    await axios.delete(`/backtest_2/histories/${id}`);
    backtest2HistoriesRefresher();
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={() => {
        setPage(0);
        onClose();
      }}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">내 백테스트 실행 기록</DialogTitle>
      <DialogContent style={{ height: 600, width: "100%" }}>
        {/* <ToggleButtonGroup
          color="primary"
          value={user}
          exclusive
          size="small"
          onChange={(e, v) => {
            setUser(v);
          }}
        >
          <ToggleButton value={me?.email || ""}>내 백테스트 보기</ToggleButton>
          <ToggleButton value={ALL_USER}>전체 백테스트 보기</ToggleButton>
        </ToggleButtonGroup> */}
        <DataGridPro
          rows={backtest2Histories.items || []}
          rowCount={rowCountState}
          loading={backtest2HistoriesLoadable.state == "loading"}
          rowsPerPageOptions={[5, 10, 50]}
          pagination
          page={page}
          pageSize={pageSize}
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          columns={backtestHistoryColumns}
          initialState={{
            pinnedColumns: { left: ["id"] }
          }}
          density="compact"
          components={{ Toolbar: CSToolbar }}
          getRowId={(row) => row.id}
          onRowClick={(param, evt) => {
            setPage(0);
            onSelect(param.row.config);
          }}
          getEstimatedRowHeight={() => 200}
          getRowHeight={() => 'auto'}
          sx={{
            [`& .${gridClasses.cell}`]: {
              py: 1,
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
  