import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Box } from "@mui/material";
import { assetCompareTicker, assetInformationState } from "@states/asset";
import TreeMapChart from "@components/chart/TreeMapChart";

export default function TreeMap({ ticker, market, assetType }) {
  const setValueState = useSetRecoilState(assetCompareTicker);

  useEffect(() => {
    setValueState(ticker);
  }, []);

  const gridData = useRecoilValue(
    assetInformationState({ market, assetType, ticker })
  );

  if (!gridData.length) {
    return <></>;
  }

  let chartData = [];

  gridData.map((data) => {
    chartData = [
      ...chartData,
      {
        x: data["종목명"],
        y: data["거래량(20영업일평균)"],
      },
    ];
  });

  return (
    <Box sx={{ pt: 5 }}>
      {chartData.length > 0 && (
        <TreeMapChart title="최근 20일 평균 거래량" data={chartData} />
      )}
    </Box>
  );
}
