import React, { Suspense, useState } from "react";
// @material
import { Stack } from "@mui/material";
// components
import AssetViewerTable from "./AssetListViewer/AssetTable";
import AssetViewerHeader from "./Header/Title";
import AssetViewerTabs from "./Tabs";
import Page from "@components/Page";
import LoadingScreen from "@components/LoadingScreen";
import { SideBar } from "../../comparison/SideBar";
import { PageTitle } from "@components/ui/styles";

// ----------------------------------------------------------------------

function RealityIndexPage() {
  const [checkedAssets, setCheckedAsset] = useState<Array<Object>>([]);

  return (
    <>
      <Page title="부동산 지수 종목 탐색">
        <Stack spacing={2}>
          <PageTitle>부동산 지수 종목 탐색</PageTitle>
          <AssetViewerTable
            onSelectAsset={(e) => {
              setCheckedAsset([e, ...checkedAssets]);
            } } onChangeChecked={()=> {}}          />
          <Suspense fallback={<LoadingScreen />}>
            <AssetViewerHeader />
            <AssetViewerTabs />
          </Suspense>
        </Stack>
        <SideBar
          assetType="realty"
          market="realty_indexes"
          checkedAssets={checkedAssets.map((v) => {
            return { ...v, name: v["asset_name"] };
          })}
          enableDownload={false}
          onChange={setCheckedAsset}
        />
      </Page>
    </>
  );
}

export default React.memo(RealityIndexPage);
