import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export const SectionList = ({ sections }) => {
  return (
    <>
      {sections?.map((item, key) => (
        <Box key={key}>
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1">{item.label}</Typography>
          </Box>
          <div key={item.id}>{item.children}</div>
        </Box>
      ))}
    </>
  );
};
