import ReactApexChart from "react-apexcharts";
import { Card, CardHeader } from "@mui/material";
import { ApexOptions } from "apexcharts";

export default function TreeMapChart({ title, data }) {
  const chartOption: ApexOptions = {
    legend: {
      show: false,
    },
    title: {
      align: "center",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "50px",
      },
      offsetY: -4,
    },
    colors: ["#142A4F"],
    plotOptions: {
      treemap: {
        enableShades: true,
        shadeIntensity: 0.4,
        reverseNegativeShade: true,
      },
    },
  };

  return (
    <Card sx={{ px: 4, pt: 2, pb: 2, textAlign: "center" }}>
      <CardHeader title={title} />
      <ReactApexChart
        type="treemap"
        series={[{ data: data }]}
        options={chartOption}
        height={450}
        width={"100%"}
      />
    </Card>
  );
}
