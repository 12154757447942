import React, { useRef, useState } from "react";
import { Typography, Stack,Paper } from "@mui/material";
import axiosInstance from "@utils/axios";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function ChangePwPanel() {
  const pwRef = useRef<HTMLInputElement>(null);
  const newPwRef = useRef<HTMLInputElement>(null);
  const secondNewPwRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState("");

  const postChangePassword = async (password, newPassword) => {
    try {
      await axiosInstance.post("user/change_password", null, {
        params: { password: password, new_password: newPassword },
      });
      alert("비밀번호가 변경되었습니다.");
      setError("");
    } catch (e) {
      setError("현재 비밀번호가 맞지 않습니다.");
    }
  };

  const isValidation = (oldPassword, newPassword1, newPassword2) => {
    if (newPassword1 !== newPassword2) {
      setError("변경될 비밀번호가 일치하지 않습니다.");
      return false;
    }

    const regExp = /^(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (!regExp.test(newPassword1)) {
      setError(
        "비밀번호는 최소 8자 이상, 특수문자를 하나이상 포함하여야 합니다."
      );
      return false;
    }
    return true;
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      isValidation(
        pwRef.current?.value,
        newPwRef.current?.value,
        secondNewPwRef.current?.value
      )
    ) {
      postChangePassword(pwRef.current?.value, secondNewPwRef.current?.value);
    }
  };

  return (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 500 }}>
    <Stack
      component="form"
      sx={{ maxWidth: 500 }}
      spacing={2}
      onSubmit={handleSubmit}
    >
      <TextField
        inputRef={pwRef}
        id="password"
        label="현재 비밀번호"
        name="password"
        type="password"
        required
        fullWidth
        margin="normal"
      />
      <TextField
        inputRef={newPwRef}
        id="new_password_one"
        label="새 비밀번호"
        name="new_password_one"
        type="password"
        required
        fullWidth
        margin="normal"
      />
      <TextField
        inputRef={secondNewPwRef}
        id="new_password_two"
        label="새 비밀번호 확인"
        name="new_password_two"
        type="password"
        required
        fullWidth
        margin="normal"
      />
      <Typography style={{ color: "red", fontWeight: "bold" }}>
        {error}
      </Typography>
      <Button
        type="submit"
        variant="contained"
        style={{
          fontSize: "18px",
          fontWeight: "700",
        }}
        fullWidth
      >
        확인
      </Button>
    </Stack>
    </Paper>
  );
}

export default ChangePwPanel;
