import { Card, FormLabel, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledCard = ({ children, ...other }) => (
  <Card sx={{ p: 3, m: 1 }} {...other}>
    {children}
  </Card>
);

export const StyledLabel = styled(FormLabel)(({ theme }) => ({
  ...theme.typography.subtitle1,
  fontWeight: 750,
  color: theme.palette.text.secondary,
}));

export const StyledStepLabel = styled(FormLabel)(({ theme }) => ({
  ...theme.typography.h6,
  fontWeight: 750,
  color: "#41B369",
}));

export const PageTitle = ({ children, ...other }) => (
  <Typography variant="h5"  sx={{mb:1}} paragraph {...other}>
    {children}
  </Typography>
);
