import { atom, selector } from "recoil";
import axios from "../utils/axios";
import { AssetType } from "types/common";
import { reducePriceValue } from "@api/asset";

export const valueState = atom({
  key: "etfUSValueState",
  default: 0,
});

export const enableFilterState = atom({
  key: "etfUSEnableFitlerState",
  default: false,
});

export const extraInfoState = atom({
  key: "etfUSExtraInfoState",
  default: false,
});

export const etfUSChartDateState = atom({
  key: "etfUSChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const selectedEtfTickerState = atom<AssetType | null>({
  key: "etfUSSelectedTickerState",
  default: null,
});

export const usEtfSelectedQueryFilterState = atom<any| null>({
  key: "usEtfSelectedQueryFilterState",
  default: null,
});

export const usEtfQueryFilterState = atom<any | null>({
  key: "etfUSQueryFilterState",
  default: null,
});

export const analysisDataState = atom({
  key: "etfUSAnalysisDataState",
  default: {load:false},
});

export const usSimpleOperationListState = selector({
  key: "etfUSSimpleOperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`etf/us/simple_operations`);
    return response.data.data;
  },
});

export const usOperationListState = selector({
  key: "etfUSOperationListState",
  get: async ({ get }) => {
    const usFilter = get(usEtfQueryFilterState);
    const extra = get(extraInfoState);
    const params = { extra };
    if (usFilter) {
      params["filter"] = usFilter;
    }

    const response = await axios.get(`etf/us/operations`, {
      params: params,
    });
    return response.data.data;
  },
});

export const artificailIndexDataState = selector({
  key: "etfUSArtificailIndexDataState",
  get: async ({ get }) => {
    try {
      const selected = get(selectedEtfTickerState);
      if (selected === null) return;
      const { ticker } = selected;
      const dataParam = get(etfUSChartDateState);
      const response = await axios.get(`etf/us/artificial_prices/${ticker}`, {
        params: dataParam,
      });
      const responseObj = response.data.data;
      return reducePriceValue(responseObj);
    } catch (e) {
      return;
    }
  },
});

export const usEtfOperationFilterCountState = selector({
  key: "usEtfOperationFilterCountState",
  get: async ({ get }) => {
    const response = await axios.get("etf/us/operation_filter_count");
    return response.data.data;
  },
});

export const usEtfAssetClassFilterState = selector({
  key: "usEtfAssetClassFilterState",
  get: async ({ get }) => {
    const filterCount = get(usEtfOperationFilterCountState);
    return filterCount.asset_class;
  },
});

export const usEtfSubFilterState = selector({
  key: "usEtfSubFilterState",
  get: async ({ get }) => {
    const filterCount = get(usEtfOperationFilterCountState);
    return filterCount.theme;
  },
});
