import Fade from "@mui/material/Fade";
import { useState } from "react";
import Iconify from "../asset/Iconify";
import { Button, Menu, MenuItem } from "@mui/material";
import { CSToolbar } from "./CSToolbar";

export const ColumnSetting = ({
  onColumnsLoad,
  onColumnsSave,
  onColumnsReset,
}) => {
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Iconify
          icon="eva:settings-2-outline"
          sx={{ p: 0, width: 20, height: 20, mr: 1 }}
        />
        Setting
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={onColumnsLoad}>Load Columns Setting</MenuItem>
        <MenuItem onClick={onColumnsSave}>Save Columns Setting </MenuItem>
        <MenuItem onClick={onColumnsReset}>Reset Columns Setting</MenuItem>
      </Menu>
    </>
  );
};

export const ColumnSettingToolbar = (props) => {
  return <CSToolbar extendComponent={<ColumnSetting {...props} />} />;
};
