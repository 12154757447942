import dayjs from "dayjs";
import { format, formatDistanceToNow } from "date-fns";

// ----------------------------------------------------------------------

export function fDate(date: number) {
  return format(new Date(date), "dd MMMM yyyy");
}

export function fDateTime(date: number) {
  return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function fDateTimeSuffix(date: number) {
  return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function fToNow(date: number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function rfDate(date: number) {
  return format(new Date(date), "yyyymmdd");
}

export function ffDate(date: number) {
  return format(new Date(date), "yyyy-MM-dd");
}

export const responseDateValidate = (queryDate: Array<string|null>,  targetDate: Array<string|null> ) => {
  let resultMessage = "";
  const [begin, end] = queryDate;
  if (begin && targetDate) {
    const queryBegin = dayjs(begin);
    const targetBegin = dayjs(targetDate[0]);
    if (queryBegin.diff(targetBegin)) {
      resultMessage += `시작 날짜 변경됨: ${targetBegin.format("YYYY-MM-DD")}. `;
    }
  }

  if (end && targetDate) {
    const queryEnd = dayjs(end);
    const targetEnd = dayjs(targetDate[targetDate.length-1]);
    if (queryEnd.diff(targetEnd)) {
      resultMessage += ` 종료 날짜 변경됨: ${targetEnd.format("YYYY-MM-DD")}. `;
    }
  }
  
  return resultMessage
}

export const addHyphen = (date:any) => {
  if(date){
    return date.replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
  }
}