export const END_DATE = new Date();
export const CURRENT_YEAR = END_DATE.getFullYear();

export const PERIOD_TIME_MAP = {
  all: {
    min: undefined,
  },
  "2000": {
    min: new Date("2000.01.01").getTime(),
  },
  "ytd": {
    min: new Date(`${CURRENT_YEAR}.01.01`).getTime(),
  },
    "20y": {
    min: new Date().setFullYear(END_DATE.getFullYear() - 20),
  },
  "10y": {
    min: new Date().setFullYear(END_DATE.getFullYear() - 10),
  },
  "5y": {
    min: new Date().setFullYear(END_DATE.getFullYear() - 5),
  },
  "3y": {
    min: new Date().setFullYear(END_DATE.getFullYear() - 3),
  },
  "1y": {
    min: new Date().setFullYear(END_DATE.getFullYear() - 1),
  },
  "6m": {
    min: new Date().setMonth(END_DATE.getMonth() - 6),
  },
  "3m": {
    min: new Date().setMonth(END_DATE.getMonth() - 3),
  },

};

export const PERIOD_BUTTON = [
  { id: 1, value: "all", label: "전체" },
  { id: 2, value: "10y", label: "10년" },
  { id: 3, value: "3y", label: "3년" },
  { id: 4, value: "1y", label: "1년" },
  { id: 5, value: "3m", label: "3개월" },
];
