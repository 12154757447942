import { debounce } from "lodash";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
}

const useSearchHook = (operationData, setSearch, setGridData) => {
  const targetField = [
    "ticker",
    "isin",
    "종목명",
    "기초시장",
    "기초자산",
    "지초지수명",
    "기초지수코드",
    "기초지수통화",
    "rf_id",
    "name",
    "source_ticker",
    "undl_source",
    "undl_rf_id",
    "undl_name",
    "source",
  ];

  const debounceOnChange = debounce((searchValue) => {
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = operationData.filter((row) =>
      Object.keys(row).some((field) => {
        if (!row[field] || !targetField.includes(field)) return false;
        return searchRegex.test(row[field].toString());
      })
    );

    setGridData(filteredRows);
  }, 500);
  const requestSearch = (searchValue) => {
    setSearch(searchValue);
    debounceOnChange(searchValue);
  };
  return [requestSearch];
};

export default  useSearchHook