import * as Yup from "yup";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik, Form, FormikProvider } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  Stack,
  Alert,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

import { useSetRecoilState } from "recoil";
import { authStateAtom } from "../../states/auth";

import axios from "../../utils/axios";
import { setSession } from "../../utils/jwt";
import useIsMountedRef from "@hooks/useIsMountedRef";
//
import MIconButton from "@components/button/MIconButton";

const login = async (email, password) => {
  const loginFormData = new FormData();
  loginFormData.append("username", email);
  loginFormData.append("password", password);

  const response = await axios({
    method: "post",
    url: "user/signin",
    data: loginFormData,
    headers: { "Content-Type": "multipart/form-data" },
  });

  const user = response.data;
  setSession(user.access_token);

  return user;
};

export default function LoginForm() {
  const setAuthStates = useSetRecoilState(authStateAtom);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("이메일이 유효하지 않습니다.")
      .required("이메일을 입력해주세요."),
    password: Yup.string().required("비밀번호를 입력해주세요."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
      afterSubmit: ""
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const user = await login(values.email, values.password);
        setAuthStates({
          isInitialized: true,
          isAuthenticated: true,
          user: {
            email: user.email,
            name: user.name,
          },
        });

        enqueueSnackbar(user.name + "님, 반갑습니다.🥳", {
          variant: "success",
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          ),
        });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error: any) {
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.detail });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>


          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="이메일"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="비밀번호"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          {errors.afterSubmit && (
            <Alert severity="error">{errors.afterSubmit}</Alert>
          )}
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          {/* <FormControlLabel
            control={
              <Checkbox
                {...getFieldProps("remember")}
                checked={values.remember}
              />
            }
            label="로그인 유지"
          /> */}
                    
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          로그인
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
