import { TextField, IconButton } from "@mui/material";
import { Search as SearchIcon, Clear as ClearIcon } from "@mui/icons-material";

type Props = {
  text: string;
  onChangeText: (e: string) => void;
  market: string;
};

export const Toolbar = ({ text, onChangeText, market }: Props) => {
  return (
    <>

      <TextField
        value={text}
        onChange={(event) => onChangeText(event.target.value)}
        size="small"
        sx={{ width: "100%" }}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              onClick={() => onChangeText("")}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </>
  );
};
