
export const useLoading = (callback, fecther, errorCallback:Function|null=null) => {
  return async () => {
    try {
      callback(true);
      await fecther();
    } catch (e) {
      if (errorCallback !== null) {
        errorCallback(e)
      }
    } finally {
      callback(false);
    }
  };
};