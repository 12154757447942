import { useMemo, useState } from "react";
import _ from "lodash";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  staticBacktest2StrategiesState,
  staticBacktest2ConfigMarketQuery,
} from "@states/staticBacktest2";
import producer from "immer";
import {
  Button,
  Card,
  Stack,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";

import { AssetPopUpV2 } from "@pages/asset/detail/AssetDetailV2";
import BacktestOption from "./BacktestOptions";
import { AssetType } from "types/common";
import StrategyAssetControl from "./StrategyAssetWeight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

function BacktestStrategy() {
  const [strategies, setStrategies] = useRecoilState(staticBacktest2StrategiesState);
  const market = useRecoilValue(staticBacktest2ConfigMarketQuery);

  const [selectedAsset, setSelectedAsset] = useState<AssetType>();
  const [openDialog, setOpenDialog] = useState(false);

  const selectedTicker = useMemo(() => {
    if (selectedAsset) {
      return selectedAsset.id?.split("-")[1];
    } else {
      return "";
    }
  }, [selectedAsset]);

  const handleChangeName = (strategyIndex) => (e) => {
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        draft[strategyIndex].name = e.target.value;
      })
    );
  };

  const handleDelete = (strategyIndex) => (e) => {
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        draft.splice(strategyIndex, 1);
      })
    );
  };

  const handleCopy = (strategyIndex) => (e) => {
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        draft.push(_.cloneDeep(draft[strategyIndex]));
      })
    );
  };

  const handleOpenDialog = (asset) => {
    setSelectedAsset(asset);
    setOpenDialog(true);
  };

  const handleMoveIndex =
    (pos, dir = 1) =>
    (e) => {
      const next = pos + dir;
      setStrategies((prevState) =>
        producer(prevState, (draft) => {
          [draft[next], draft[pos]] = [draft[pos], draft[next]];
        })
      );
    };
    
  return (
    <>
      {strategies?.map((strategy, strategyIndex) => (
        <Card key={strategyIndex} sx={{ p: 3, m: 1 }}>
          <Stack direction="row" justifyContent="space-between">
            <Grid container direction="row" spacing={4}>
              <Grid item container spacing={4} md={8} direction="column">
                <Grid item container direction="row" spacing={1}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      label="전략 이름"
                      size="small"
                      sx={{ width: "100%" }}
                      value={strategy.name}
                      onChange={handleChangeName(strategyIndex)}
                    />
                  </Grid>
                  <Grid container item md spacing={1}>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleDelete(strategyIndex)}
                      >
                        전략 삭제
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="info"
                        onClick={handleCopy(strategyIndex)}
                      >
                        전략 복사
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container>
                  <StrategyAssetControl
                    strategy={strategy}
                    strategyIndex={strategyIndex}
                    onOpenDialog={handleOpenDialog}
                  />
                </Grid>
              </Grid>
              <Grid container item md={4}>
                <BacktestOption strategy={strategy} strategyIndex={strategyIndex}/>
              </Grid>
            </Grid>
            <Stack justifyContent="space-between">
              <IconButton
                sx={{ m: 0, p: 0 }}
                style={{ marginLeft: "0px" }}
                onClick={handleMoveIndex(strategyIndex, -1)}
                disabled={strategyIndex === 0}
              >
                <KeyboardArrowUpIcon style={{ marginBottom: "-3px" }} />
              </IconButton>
              <IconButton
                sx={{ m: 0, p: 0 }}
                style={{ marginLeft: "0px" }}
                onClick={handleMoveIndex(strategyIndex, +1)}
                disabled={strategies.length - 1 === strategyIndex}
              >
                <KeyboardArrowDownIcon style={{ marginTop: "-3px" }} />
              </IconButton>
            </Stack>
          </Stack>
        </Card>
      ))}

      {selectedAsset && (
        <AssetPopUpV2
          market={market}
          ticker={selectedTicker}
          asset={selectedAsset}
          show={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      )}
    </>
  );
}
export default BacktestStrategy;
