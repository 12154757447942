import React, { useEffect, useState } from "react";
import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { selectedEtfTickerState, operationListState, enableFilterState, extraInfoState } from "@states/etfKr";
import { ETFKR } from "@pages/asset/define";
import useSearchHook from "../../../detail/useSearchHook";
import { Toolbar } from "@pages/asset/detail/Toolbar";
import OperationDownload from "../OperationDownload";
import { Checkbox, Grid, FormControlLabel } from "@mui/material";
import { ETFKRSIMPLE } from '../../../define';

type Props = {
  onChangeChecked?: (e) => void;
  onSelectAsset?: Function;
};

function EtfTable({ onChangeChecked, onSelectAsset }: Props) {
  
  const setSelectedEtfTicker = useSetRecoilState(selectedEtfTickerState);
  const etfKrxOperations = useRecoilValue(operationListState);
  
  const [enableFilter, setEnableFilter] = useRecoilState(enableFilterState);
  const [enableExtra, setEnableExtra] = useRecoilState(extraInfoState);
  
  const [searchText, setSearch] = useState("");
  const [gridData, setGridData] = useState(etfKrxOperations);

  const [updateSearch] = useSearchHook(
    etfKrxOperations,
    setSearch,
    setGridData
  );

  useEffect(()=>{
    setGridData(etfKrxOperations)
  }, [etfKrxOperations])
  
  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <Toolbar text={searchText} onChangeText={updateSearch} />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableFilter}
                  onChange={(e) => setEnableFilter(e.target.checked)}
                />
              }
              label="상세검색"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableExtra}
                  onChange={(e) => setEnableExtra(e.target.checked)}
                />
              }
              label="확장모드 보기"
            />
          </Grid>
        </Grid>
        {/* <Grid item>
          <OperationDownload />
        </Grid> */}
      </Grid>
      <AssetInformationGrid
        market={"ETFKR"}
        onSelectAsset={(asset) => {
          setSelectedEtfTicker(asset);
          if (onSelectAsset) {
            onSelectAsset(asset);
          }
        }}
        columns={enableExtra ? ETFKR : ETFKRSIMPLE}
        data={gridData}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export default EtfTable;
