import ReactApexChart from "react-apexcharts";

const createBrushOption = (defaultOption, data) => {
  let xaxis = {};
  if (data.length > 1) {
    xaxis = {
      min: new Date(data[0]).getTime(),
      max: new Date(data[data.length - 1]).getTime(),
    };
  }
  if (defaultOption.chart?.selection?.xaxis) {
    xaxis = defaultOption.chart.selection.xaxis;
  }

  const options = {
    chart: {
      id: "chart1",
      type: "area",
      brush: {
        target: "chart#1",
        enabled: true,
      },
      animations: {
        enabled: false,
      },
      selection: {
        enabled: true,
        xaxis: xaxis,
      },
    },
    colors: ["#93adcc"],
    xaxis: {
      type: "datetime",
      categories: data,
    },
    legend: {
      show: false,
    },
    yaxis: {
      show: true,
      labels: {
        formatter: (v) => (typeof v === "number" ? v.toFixed(2) : v),
        show: true,
        style: {
          colors: ["#fff"],
        },
      },
      tickAmount: 2,
    },
  };

  return options;
};

export const ChartNavigator = ({ data, options }) => {
  if (data.length > 0) {
    data = [data[0]];
  }
  const dates = options.xaxis.categories;
  const brushOption = createBrushOption(options, dates);
  return (
    <ReactApexChart
      type="area"
      series={data}
      options={brushOption}
      height={100}
      style={{ marginTop: "-35px" }}
    />
  );
};
