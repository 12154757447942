import { atom, selector } from "recoil";
import axios from "../utils/axios";
import { AssetType } from "types/common";
import { reducePriceValue } from "@api/asset";

export const valueState = atom({
  key: "etfKRValueState",
  default: 0,
});

export const enableFilterState = atom({
  key: "etfKREnableFitlerState", 
  default: false,
})

export const extraInfoState = atom({
  key: "etfKRextraInfoState",
  default: false,
})


export const etfKRChartDateState = atom({
  key: "etfKRChartDateState",
  default:{
    begin:null, 
    end:null
  },
});

export const etfKRDailyDataDateState = atom({
  key: "etfKRDailyDataDateState",
  default:{
    begin:null, 
    end:null
  },
});

export const selectedEtfTickerState = atom<AssetType|null>({
  key: "etfKRSelectedTickerState",
  default: null,
});

export const krEtfSelectedQueryFilterState = atom<any| null>({
  key: "krEtfSelectedQueryFilterState",
  default: null
})

export const krEtfQueryFilterState = atom<any| null>({
  key: "krEtfQueryFilterState",
  default: null
})


export const simpleOperationListState = selector({
  key: "etfKRSimpleOperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`etf/kr/simple_operations`);
    return response.data.data;
  },
});

export const operationListState = selector({
  key: "etfKROperationListState",
  get: async ({ get }) => {
    const categoryFilter = get(krEtfQueryFilterState);
    const extra = get(extraInfoState);
    const params = {extra};
    if (categoryFilter){ 
      params['filter'] = categoryFilter
    }
    const response = await axios.get(`etf/kr/operations`, {
      params: params
    });
    
    return response.data.data;
  },
});

export const analysisDataState = atom({
  key: "etfKRAnalysisDataState",
  default : {load:false}
})


export const artificailIndexDataState = selector({
  key: "etfKRArtificailIndexDataState",
  get: async ({ get }) => {    
      const selected = get(selectedEtfTickerState);
      if (selected === null)
        return;
      const { ticker } = selected;
      const dataParam = get(etfKRChartDateState);
      const response = await axios.get(`etf/kr/artificial_prices/${ticker}`, {params: dataParam});
      const responseObj = response.data.data;
      const indexData = reducePriceValue(responseObj);
      return indexData;
  }
});


export const etfKRFeeState = selector({
  key: "etfKRFeeState",
  get: async ({ get }) => {    
    try {      
      const selected = get(selectedEtfTickerState);
      if (selected === null)
        return;
      const { ticker } = selected;
      const response = await axios.get(`etf/kr/fee/${ticker}/history/`);
      const responseObj = response.data.data;
      return responseObj;
    } catch (e) {
      return;
    }
  },
});

export const etfKrDailyState = selector({
  // DateRange 넣고
  key: "etfKrDailyState",
  get: async ({ get }) => {    
    try {      
      const selected = get(selectedEtfTickerState);
      if (selected === null)
        return;
      const { ticker } = selected;
      const dataParam = get(etfKRDailyDataDateState);
      const response = await axios.get(`etf/kr/daily/${ticker}/history/`, {params: dataParam});
      const responseObj = response.data.data;
      return responseObj;
    } catch (e) {
      return;
    }
  },
});

export const etfKrPortfolioState = selector({
  key: "etfKrPortfolioState",
  get: async ({ get }) => {
    const selected = get(selectedEtfTickerState);
    if (selected === null)
      return 
    const { ticker } = selected;
    const response = await axios.get(`etf/kr/portfolio/${ticker}/20210929`);
    return response.data.data;
  },
});

export const krEtfOperationFilterCountState = selector({
  key: "etfKROperationFilterCountState",
  get: async ({get}) => {
    const response = await axios.get('etf/kr/operation_filter_count')
    return response.data.data
  }
})

export const krEtfAssetClassFilterState = selector({
  key: "etfKRAssetClassFilterState",
  get: async ({get}) => {
    const filterCount = get(krEtfOperationFilterCountState)
    return filterCount.asset_class
  }
})

export const krEtfSubFilterState = selector({
  key: "etfKRSubFilterState",
  get: async ({get}) => {
    const filterCount = get(krEtfOperationFilterCountState)
    return filterCount.theme
  }
})
