import { Navigate, useRoutes } from "react-router-dom";

// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";

// Auth
import Login from "../pages/auth/Login";

// ETF
import EtfKrx from "@pages/asset/etf/krx";
import EtfUs from "@pages/asset/etf/us";
import Fundkr from "@pages/asset/fund";
import IndexGlobal from "@pages/asset/indexAsset/global/index";
// import IndexMacro from "@pages/asset/indexAsset/macro/index";

// Stock
import StockKr from "@pages/asset/stock/kr";

import AssetDetailPage from "../pages/asset/detail/AssetDetail";
// import AssetComparison from "@pages/asset/comparison/PageView";
// import ExcelAnalysis from "@pages/asset/comparison/ExcelAnalysis";

// Realty
import RealtyIndexPage from "@pages/asset/realty/realty_index";

// Backtest
import StaticBacktest2 from "../pages/backtest/StaticBacktest2"
// import DynamicBacktest2 from "../pages/backtest/DynamicBacktest2"

// Product Monitor
// import ProductChangeLog from "../pages/productMonitor/changeLog";
// import ProductDividendHistory from "../pages/productMonitor/dividendHistory";

// Data Monitor
// import DailyTimeseriesData from "../pages/dataMonitor/dailyTimeseries";
// import ProxyData from "../pages/proxyData"

// User
import User from "../pages/user";

// ETC
import NotFound from "../pages/Page404";
import Page500 from "../components/error/Page500";
import DashHome from "@pages/home";
import SuspenseContainer from "@components/SuspenseContainer";
import { PATH_ASSET_INDEX, PATH_REALTY } from "./paths";
// import IndexMSCI from "@pages/asset/indexAsset/msci";
// import MSCIBand from "@pages/msciBand/index";

function Router() {
  const routes = useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
      ],
    },
    {
      path: "/",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: <Navigate replace to="/etf/kr" />
        },
        {
          path: "etf/kr",
          element: (
            <SuspenseContainer>
              <EtfKrx />
            </SuspenseContainer>
          ),
        },
        {
          path: "etf/kr/:ticker",
          element: (
            <SuspenseContainer>
              <AssetDetailPage market={"kr"} />
            </SuspenseContainer>
          ),
        },
        {
          path: "etf/us",
          element: (
            <SuspenseContainer>
              <EtfUs />
            </SuspenseContainer>
          ),
        },
        {
          path: "etf/us/:ticker",
          element: (
            <SuspenseContainer>
              <AssetDetailPage market={"us"} />
            </SuspenseContainer>
          ),
        },
        {
          path: "fund/kr",
          element: (
            <SuspenseContainer>
              <Fundkr />
            </SuspenseContainer>
          ),
        },
        // {
        //   path: "data/comparison",
        //   element: (
        //     <SuspenseContainer>
        //       <AssetComparison />
        //     </SuspenseContainer>
        //   ),
        // },
        // {
        //   path: "data/excel_analysis",
        //   element: (
        //     <SuspenseContainer>
        //       <ExcelAnalysis />
        //     </SuspenseContainer>
        //   ),
        // },
        {
          path: "asset/index/global",
          element: (
            <SuspenseContainer>
              <IndexGlobal />
            </SuspenseContainer>
          ),
        },
        // {
        //   path: "asset/index/macro",
        //   element: (
        //     <SuspenseContainer>
        //       <IndexMacro />
        //     </SuspenseContainer>
        //   ),
        // },
        {
          path: PATH_REALTY.index,
          element: (
              <SuspenseContainer>
                <RealtyIndexPage />
              </SuspenseContainer>
          ),
        },
        {
          path: "stock/kr",
          element: (
            <SuspenseContainer>
              <StockKr/>
            </SuspenseContainer>
          ),
        },
        // {
        //   path: PATH_ASSET_INDEX.msci,
        //   element: (
        //     <SuspenseContainer>
        //       <IndexMSCI />
        //     </SuspenseContainer>
        //   ),
        // },
        // {
        //   path: PATH_ASSET_INDEX.msciBand,
        //   element: (
        //     <SuspenseContainer>
        //       <MSCIBand />
        //     </SuspenseContainer>
        //   ),
        // },
        { path: "backtest2/static_backtest", element: <StaticBacktest2/>},
        // { path: "backtest2/dynamic_backtest", element: <DynamicBacktest2/>},
        // {
        //   path: "backtest2/Proxy_data",
        //   element: (
        //     <SuspenseContainer>
        //       <ProxyData />
        //     </SuspenseContainer>
        //   ),
        // },
        // {
        //   path: "product_monitor/change_log",
        //   element: (
        //     <SuspenseContainer>
        //       <ProductChangeLog />
        //     </SuspenseContainer>
        //   ),
        // },
        // {
        //   path: "product_monitor/dividend_history",
        //   element: (
        //     <SuspenseContainer>
        //       <ProductDividendHistory />
        //     </SuspenseContainer>
        //   ),
        // },
        // {
        //   path: "data_monitor/daily_timeseries",
        //   element: (
        //     <SuspenseContainer>
        //       <DailyTimeseriesData />
        //     </SuspenseContainer>
        //   ),
        // },
        {
          path: "user",
          element: (
            <SuspenseContainer>
              <User />
            </SuspenseContainer>
          ),
        },
      ],
    },
    {
      path: "error",
      element: <LogoOnlyLayout />,
      children: [{ path: "500", element: <Page500 /> }],
    },
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);

  return routes;
}

export default Router;
