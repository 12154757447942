import { Typography, Stack, Paper, Divider } from "@mui/material";
import useAuth from "../../hooks/useAuth";

function ProfilePanel() {
  const { user } = useAuth();

  return user ? (
    <Paper elevation={3} sx={{ p: 3, maxWidth: 500 }}>
      <Stack spacing={3} divider={<Divider flexItem />}>
        <Typography variant="subtitle2" sx={{ color: "text.primary" }} noWrap={true}>
          이름 <span style={{ marginLeft: "5.5em" }}>{user.name}</span>
        </Typography>
        <Typography variant="subtitle2" sx={{ color: "text.primary" }} noWrap={true}>
          이메일<span style={{ marginLeft: "5em" }}>{user.email}</span>
        </Typography>
      </Stack>
    </Paper>
  ) : (
    <></>
  );
}

export default ProfilePanel;
