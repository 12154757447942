import { Box } from "@mui/material";
import { useRecoilValue } from "recoil";
import { assetCompareYoYState } from "../../../states/asset";
import { ComparedTimeChart } from '@components/chart/ComparedTimeChart';

export default function ChartYoY() {
  const chartData = useRecoilValue(assetCompareYoYState);

  if (!chartData) {
    return <></>;
  }
  return (
    <Box sx={{ mb:7 }}>
      <ComparedTimeChart
        chartValue={chartData}
      />
    </Box>
  );
}
