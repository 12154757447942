import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedEtfTickerState,
  artificailIndexDataState,
  etfKRChartDateState
} from "@states/etfKr";
import { useOpenSnackbar } from "@hooks/useSnackbar";
import { AssetViewerExtraUndlInfoColumns } from "@pages/asset/define";
import { UndlContent } from "../../../../detail/UndlContent";

function UndlPanel() {
  const selectedEtfTicker = useRecoilValue(selectedEtfTickerState);
  const artificailIndexData = useRecoilValue(artificailIndexDataState);
  const [chartDate, setChartDate] = useRecoilState(etfKRChartDateState);

  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    if (!artificailIndexData) {
      openSnackbar("데이터가 없습니다. 😰", "error");
    } else return;
  }, [artificailIndexData, openSnackbar]);

  return (
    <UndlContent
      columns={AssetViewerExtraUndlInfoColumns}
      indexData={artificailIndexData}
      market="kr"
      selectedAsset={selectedEtfTicker}
      date={chartDate}
      onChangeDate={setChartDate}
    />
  );
}

export default UndlPanel;
