import React from "react";
import Page500, { ErrorBaseComponent } from "./Page500";

class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };
  
  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    console.error(error,errorInfo)
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return <Page500 />;
    }
    // Normally, just render children
    // @ts-ignore
    return this.props.children;
  }
}

export class ErrorBoundaryInPage extends ErrorBoundary {
  render() {
    if (this.state.errorInfo) {
      // Error path
      return <ErrorBaseComponent errorInfo={this.state.errorInfo} />;
    }
    // Normally, just render children
    // @ts-ignore
    return this.props.children;
  }
}

export default ErrorBoundary;
