import { atom, selector } from "recoil";
import axios from "@utils/axios";
import baseAxios from "axios";
import { selectorFamily } from "recoil";
import { responseDateValidate } from "@utils/formatTime";

export const ASSET_COMPARISON_API = ["performance", "stock"];

export type AnalysisTableType = {
  values: Array<any>;
  index: string[];
};

export type DateRangeType = string | null;

export const logToggleState = atom({
  key: "logToggleState",
  default: false,
});

export const isKrwCheckedState = atom({
  key: "isKrwCheckedState",
  default: false,
});

export const KrwChartDataState = atom<any>({
  key: "KrwChartDataState",
  default: [],
});

export const KrwChartIndexState = atom<any>({
  key: "KrwChartIndexState",
  default: [],
});

export const dateRangeBeginState = atom<DateRangeType>({
  key: "dateRangeBeginState",
  default: null,
})

export const dateRangeEndState = atom<DateRangeType>({
  key: "dateRangeEndState",
  default: null,
})

export const assetCompareDateRangeState = atom({
  key: "assetCompareDateRangeState",
  default: {
    begin: null,
    end: null,
  },
});

export const assetCompareDetailState = atom({
  key: "assetComparedetail",
  default: {
    loaded: false,
    analysis_table: undefined,
    price_reindex: undefined,
    total_correlation_table: undefined,
    monthly_correlation_table: undefined,
    total_draw_down: undefined,
    total_ret_arith_yearly: undefined,
    yoy: undefined,
    mom: undefined,
    ytd: undefined,
  },
});

export const assetCompareAnalysisState = selector({
  key: "assetCompareAnalysisState",
  get: ({ get }) => {
    const { analysis_table } = get(assetCompareDetailState);
    return analysis_table;
  },
});

export const assetComparePriceState = selector({
  key: "assetComparePriceState",
  get: ({ get }) => {
    const { price_reindex } = get(assetCompareDetailState);
    return price_reindex;
  },
});

export const assetCompareCorrelationState = selector({
  key: "assetCompareCorrelationState",
  get: ({ get }) => {
    const { total_correlation_table } = get(assetCompareDetailState);
    return total_correlation_table;
  },
});

export const assetCompareMonthlyCorrelationState = selector({
  key: "assetCompareMonthlyCorrelationState",
  get: ({ get }) => {
    const { monthly_correlation_table } = get(assetCompareDetailState);
    return monthly_correlation_table;
  },
});

export const assetCompareDrawDownState = selector({
  key: "assetCompareDrawDownState",
  get: ({ get }) => {
    const { total_draw_down } = get(assetCompareDetailState);
    return total_draw_down;
  },
});

export const assetCompareYoYState = selector({
  key: "assetCompareYoYState",
  get: ({ get }) => {
    const { yoy } = get(assetCompareDetailState);
    return yoy;
  },
});

export const assetCompareYTDState = selector({
  key: "assetCompareYTDState",
  get: ({ get }) => {
    const { ytd } = get(assetCompareDetailState);
    return ytd;
  },
});

export const assetCompareMoMState = selector({
  key: "assetCompareMoMState",
  get: ({ get }) => {
    const { mom } = get(assetCompareDetailState);
    return mom;
  },
});

export const assetCompareYearlyReturnState = selector({
  key: "assetCompareYearlyReturnState",
  get: ({ get }) => {
    const { total_ret_arith_yearly } = get(assetCompareDetailState);
    return total_ret_arith_yearly;
  },
});

export const assetCompareTicker = atom({
  key: "assetCompareTickerState",
  default: [],
});

type AssetInformationStateParam = {
  market: string;
  assetType: string;
  ticker: string;
};

export const assetInformationState = selectorFamily({
  key: "assetCompareInformationState",
  get:
    ({ market, assetType, ticker }: AssetInformationStateParam) =>
    async ({ get }) => {
      if (ticker.length) {
        return await selectedAssetSummaryData(ticker, market, assetType);
      } else {
        return [];
      }
    },
});

export const selectedAssetSummaryData = async (ticker, market, assetType) => {
  let url = `${assetType}/${market}/operation`;
  const response = await baseAxios.all(
    ticker.map((v) => axios.get(`${url}/${v}`))
  );
  return response.map((res: any) => res.data.data);
};

export const selectAssetDiffData = async (
  assetType,
  market,
  tickers,
  period = "D",
  begin = null,
  end = null
) => {
  let url = `${assetType}/${market}/asset_compare`;

  if (ASSET_COMPARISON_API.includes(assetType)) {
    url = `data/asset/compare`;
  }
  const response = await axios.post(url, {
    tickers,
    period,
    begin,
    end,
  });
  if (response.data.data) {
    const data = response.data.data;
    const validationMessage = responseDateValidate(
      [begin, end],
      data.price_reindex.index
    );
    response.data.data.warning = validationMessage;
  }

  return response.data.data;
};

export const selectAssetComparisonData = async (
  tickers,
  period = "D",
  begin: null | string = "20000101",
  end = null
) => {
  const url = "data/asset/compare";
  const response = await axios.post(url, {
    tickers,
    period,
    begin,
    end,
  });

  if (response.data.data) {
    const data = response.data.data;
    const validationMessage = responseDateValidate(
      [begin, end],
      data.price_reindex.index
    );
    data.warning = validationMessage;
  }

  return response.data.data;
};
