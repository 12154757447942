// ----------------------------------------------------------------------

function path(root:string, sublink:string) {
  return `${root}${sublink}`;
}

const ROOTS = "";
const ROOTS_AUTH = "/auth";
const ROOTS_ASSET = "/asset";
// const ROOTS_DATA = "/data";
const ETF_ROOT = "/etf";
const FUND_ROOT = "/fund"
const STOCK_ROOT = '/stock';
const REALIY_ROOT = '/realty';
const BACKTEST2_ROOT = "/backtest2";

// const PRODUCT_MONITOR_ROOT = "/product_monitor";
// const DATA_MONITOR_ROOT = "/data_monitor";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  loginUnprotected: path(ROOTS_AUTH, "/login-unprotected"),
};

// export const PATH_DASHBOARD = {
//   root: ROOTS,
//   general: {
//     assets: path(ROOTS, "/service"),
//   },
// };

export const PATH_ASSET = {
  root: ROOTS_ASSET,
  index_asset: path(ROOTS_ASSET, "/index"),
}

// export const PATH_DATA = {
//   root: ROOTS_DATA,
//   comparison: path(ROOTS_DATA, "/comparison"),
//   excel_analysis: path(ROOTS_DATA, "/excel_analysis"),
// }

export const PATH_ASSET_INDEX = {
  root: PATH_ASSET,
  global: path(PATH_ASSET.index_asset, "/global"),
  // macro:  path(PATH_ASSET.index_asset, "/macro"),
  // msci: path(PATH_ASSET.index_asset, "/msci"),
  // msciBand: path(PATH_ASSET.index_asset, "/msciband"),
};


export const PATH_ETF = {
  root: ETF_ROOT,
  krx: path(ETF_ROOT, "/kr"),
  us: path(ETF_ROOT, "/us"),
};

export const PATH_FUND = {
  root: FUND_ROOT,
  kr: path(FUND_ROOT, "/kr"),
}

export const PATH_STOCK = {
  root: STOCK_ROOT,
  kr: path(STOCK_ROOT, "/kr"),
}

export const PATH_REALTY = {
  root: REALIY_ROOT,
  index: path(REALIY_ROOT, "/realty_indexes"),
}

export const PATH_BACKTEST_2 = {
  root: BACKTEST2_ROOT,
  staticBacktest2: path(BACKTEST2_ROOT, "/static_backtest"),
  // dynamicBacktest2: path(BACKTEST2_ROOT, "/dynamic_backtest"),
  // proxyData: path(BACKTEST2_ROOT, "/proxy_data")
}

// export const PATH_PRODUCT_MONITOR = {
//   root: PRODUCT_MONITOR_ROOT,
//   changeLog: path(PRODUCT_MONITOR_ROOT, "/change_log"),
//   dividendHistory: path(PRODUCT_MONITOR_ROOT, "/dividend_history"),
// };

// export const PATH_DATA_MONITOR = {
//   root: DATA_MONITOR_ROOT,
//   dailyTimeseries: path(DATA_MONITOR_ROOT, "/daily_timeseries"),
// };
