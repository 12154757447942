import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import ProfilePanel from "./ProfilePanel";
import ChangePwPanel from "./ChangePwPanel";
import TabPanel from "@components/tab/TabPanel";
import {tabProps} from "@pages/asset/styles";

function EtfTabs() {
  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: any) => {
    setValue(newValue);
  };

  const [value, setValue] = useState(0);

  return (
    <>
      <Box sx={{ marginTop: 5, borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="프로필 관리" {...tabProps(0)} />
          <Tab label="비밀번호 변경" {...tabProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ProfilePanel />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ChangePwPanel />
      </TabPanel>
    </>
  );
}

export default EtfTabs;
