import React from "react";
import { selectedEtfTickerState } from "@states/etfKr";
import { useRecoilValue } from "recoil";
import UndlPanel from "./Panel/UndlPanel";
import PortfolioPanel from "./Panel/PortfolioPanel";
import AnalysisPanel from "./Panel/AnalysisPanel";
import { TabContents } from "@components/tab/TabPanel";
import FeePanel from "./Panel/FeePanel"
import DailyPanel from "./Panel/DailyPanel"

const Contents = [
  { 
    label: "가상 지수",
    component: <UndlPanel/>,
  },
  // {
  //   label: "포트폴리오",
  //   component: <PortfolioPanel/>,
  // },
  {    
    label: "분석",
    component: <AnalysisPanel/>,
  },
  {    
    label: "수수료",
    component: <FeePanel/>,
  },
  {
    label: "일별 데이터",
    component: <DailyPanel/>,
  }
];

function EtfTabs() {  
  const selectedEtfTicker = useRecoilValue(selectedEtfTickerState);
  return (
    <>
      {selectedEtfTicker && (
       <TabContents tabs={Contents} />
      )}
    </>
  );
}

export default EtfTabs;
