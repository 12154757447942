import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
} from "@mui/material";
import {
  fundKrOperationFilterCountState,
  fundKrSelectedQueryFilterState,
  fundKrSelectedClass,
  fundKrQueryFilterState,
  enableFilterState
} from "@states/fundKr";
import { Icon } from "@iconify/react";
import closeFill from "@iconify/icons-eva/close-fill";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { cloneDeep } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CountBadge,
} from "@pages/asset/styles";
import { styled } from "@mui/material/styles";
import { LoadingContainer } from "@components/LoadingScreen";
import { Suspense } from "react";
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SimpleAccordian = styled(Box)`
  ${({ open }) => (open ? "" : "height: 0")};
  overflow: hidden;
  transition: height 0.5s ease;
`;

function SubCategoryFilter({ filter }) {
  const [queryFilter, setQueryFilter] = useRecoilState(fundKrSelectedQueryFilterState);

  const handleChange = (category) => (e) => {
    const filterParam = cloneDeep(queryFilter);
    if (e.target.checked) {
      if (filterParam["판매사"]) {
        filterParam["판매사"].push(e.target.name);
      } else {
        filterParam["판매사"] = [e.target.name];
      }
    } else {
      if (filterParam["판매사"]) {
        const index = filterParam["판매사"].indexOf(e.target.name);
        if (index > -1) {
          filterParam["판매사"].splice(index, 1);
          if (filterParam["판매사"].length === 0) delete filterParam["판매사"];
        }
      }
    }

    setQueryFilter(filterParam);
  };

  return (
    <>
      <Box sx={{ mx: 1.5, my: 0.5 }}>
        <Typography variant="overline">{"판매사"}</Typography>
      </Box>
      <Box sx={{ height: 500, overflow: "scroll" }}>
        {Object.keys(filter).map((category) => (
          <Stack sx={{ px: 2, mt: 0, maxHeight: 150, overflow: "scroll" }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                display: "inline-flex",
                alignItems: "center",
                verticalAlign: "middle",
              }}
            >
              <FormControlLabel
                label={category}
                value={category}
                sx={{ fontSize: 1 }}
                control={
                  <Checkbox
                    size="small"
                    sx={{ padding: "5px" }}
                    name={category}
                    checked={
                      queryFilter?.판매사?.filter((value) => value === category)
                        .length
                        ? true
                        : false
                    }
                    onChange={handleChange(category)}
                  />
                }
              />
              <CountBadge>{filter[category]}</CountBadge>
            </Stack>
          </Stack>
        ))}
      </Box>
    </>
  );
}

function AssetClassFilterContent() {
  const fundKrOperationFilterCount = useRecoilValue(
    fundKrOperationFilterCountState
  );
  const [radioValue, setRadioValue] = useRecoilState(fundKrSelectedClass)

  const handleChange = (e) => {
    setRadioValue(e.target.value)
  };
  const sumValue = Object.values(fundKrOperationFilterCount).reduce(
    function add(sum, curr) {
      return sum + curr;
    },
    0
  );
  return (
    <FormControl sx={{ width: "100%" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
        value={radioValue}
        onChange={handleChange}
      >
        <Stack>
          <Box>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                width: "100%",
                p: 0.5,
                px: 1.5,
              }}
            >
              <FormControlLabel
                label={"판매사"}
                value={"판매사"}
                control={<Radio size="small" />}
              />
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  verticalAlign: "middle",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", color: "gray" }}
                >
                  {sumValue}
                </Typography>
              </Box>
            </Stack>
              <SimpleAccordian className="open" open={radioValue === "판매사"}>
                <SubCategoryFilter filter={fundKrOperationFilterCount} />
              </SimpleAccordian>
          </Box>
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}

function AssetClassFilter() {
  const [enableFilter, setEnableFilter] = useRecoilState(enableFilterState);
  const setQueryFilter = useSetRecoilState(fundKrQueryFilterState);
  const [selectedEtfTicker, setSelectedEtfTicker] = useRecoilState(fundKrSelectedQueryFilterState);
  const setRadioValue = useSetRecoilState(fundKrSelectedClass)
  const handleSubmit = (fund) => {
    setQueryFilter(selectedEtfTicker)
    setEnableFilter(false)
  };
  const handleReset = () => {
    setQueryFilter({});
    setSelectedEtfTicker({});
    setRadioValue('')
  };
  const handleClose = () => {
    setEnableFilter(false)
  }
  return (
    <Dialog
      onClose={handleClose}
      open={enableFilter}
      hideBackdrop={true}
      PaperComponent={PaperComponent}
      sx={{ maxWidth: "310px", my: "auto", maxHeight: "650px" }}
      aria-labelledby="draggable-dialog-title"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <DialogTitle style={{ cursor: "move" }} sx={{ m: "auto", mb: 2 }}>
          상세검색
        </DialogTitle>
        <Icon
          icon={closeFill}
          onClick={handleClose}
          width={20}
          height={20}
          style={{ marginRight: 10, marginTop: 14 }}
        />
      </Stack>
      <Accordion sx={{ mx: 1, mt: 1 }} defaultExpanded>
        <AccordionSummary sx={{ margin: 0 }}>
          <Typography>테마</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Suspense fallback={<LoadingContainer />}>
            <AssetClassFilterContent />
          </Suspense>
        </AccordionDetails>
      </Accordion>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        sx={{ my: 2, px: 1 }}
      >
        <Grid item>
          <Button
            variant="contained"
            size="medium"
            sx={{ width: "100%" }}
            onClick={handleSubmit}
          >
            검색결과 보기
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            size="medium"
            sx={{ width: "100%" }}
            onClick={handleReset}
          >
            조건 초기화
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

export default AssetClassFilter;
