import LoadingButton from "@components/button/LoadingButton";
import PriceLineChart from "@components/chart/PriceLineChart";
import { Box, Stack, Grid } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { downloadExcel } from "@utils/download";
import { useEffect, useState } from "react";
import { Header5Style } from "../styles";
import { useRecoilValue, useRecoilState } from "recoil"
import axios from "@utils/axios";
import {
  DateRange,
  DateRangeSubmitEvent,
  DateRangeType,
} from "@components/DateRange";
import { createStartAnnotation } from "@utils/chart";
import { isKrwCheckedState, KrwChartDataState, KrwChartIndexState } from "@states/asset";

type Props = {
  selectedAsset: any;
  indexData: any;
  market: string;
  columns: any[];
  assetType?: string;
  date?: DateRangeType;
  onChangeDate?: DateRangeSubmitEvent;
  enbaleDownalod?: boolean
};

export const UndlContent = ({
  selectedAsset,
  indexData,
  market,
  columns,
  date,
  assetType = "etf",
  onChangeDate,
  enbaleDownalod = true
}: Props) => {
  const { undlInfo } = indexData;

  return (
    <Stack spacing={2}>
      <IndexChart
        assetType={assetType}
        market={market}
        date={date}
        indexData={indexData}
        selectedAsset={selectedAsset}
        onChangeDate={onChangeDate}
        enbaleDownalod={enbaleDownalod}
      />
      {undlInfo && (
        <Box sx={{ height: 250, mt: 4 }}>
          <Header5Style>확장 지수에 사용된 기초지수 정보</Header5Style>
          <DataGridPro
            columns={columns}
            rows={undlInfo}
            disableSelectionOnClick
            density="compact"
            getRowId={(row) => row.ticker}
          />
        </Box>
      )}
    </Stack>
  );
};

const IndexChart = ({
  assetType,
  market,
  date,
  indexData,
  selectedAsset,
  onChangeDate,
  enbaleDownalod = true
}) => {
  const [isDailyDownload, setIsDailyDownload] = useState(false);
  const [isWeeklyDownload, setIsWeeklyDownload] = useState(false);
  const [isMonthlyDownload, setIsMonthlyDownload] = useState(false);
  const { chartData } = indexData;
  const chartOption = createStartAnnotation(indexData.startDate);
  const isKrwChecked = useRecoilValue(isKrwCheckedState);
  const [ krwChartData, setKrwChartData] = useRecoilState(KrwChartDataState);
  const [ krwChartIndex, setKrwChartIndex] = useRecoilState(KrwChartIndexState);

  async function downloadExtraIndexExcel(period) {
    const loadFunc = (period === "D") ? setIsDailyDownload :
                    (period === "W") ? setIsWeeklyDownload :
                    (period === "M") ? setIsMonthlyDownload : () => {};
    const fileSuffix = (period === "D") ? "Daily" :
                      (period === "W") ? "Weekly" :
                      (period === "M") ? "Monthly" : "";
    const assetName = selectedAsset["종목명"];
    const filename = `${assetName}-${fileSuffix}.xlsx`;
    const url = `${assetType}/${market}/artificial_prices/${selectedAsset.ticker}/excel?period=${period}`;
    loadFunc(true);
    await downloadExcel(url, filename, date);
    loadFunc(false);
  }

  useEffect(()=>{
    if(isKrwChecked){
      const start = date.begin
      const end = date.end
      axios.get(`etf/us/artificial_prices/to_krw/${selectedAsset.rf_id}`, { params: { start, end } })
      .then((res) => {
        setKrwChartData(res.data.data.data.chart_data)
        setKrwChartIndex(res.data.data.data.base_dates)} 
        );
    }
  },[isKrwChecked])

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md>
          {enbaleDownalod && (
            <Grid container item spacing={1}>
              <Grid item>
                <LoadingButton
                  onClick={() => downloadExtraIndexExcel("D")}
                  loading={isDailyDownload}
                >
                  일일 데이터 다운로드
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={() => downloadExtraIndexExcel("W")}
                  loading={isWeeklyDownload}
                >
                  주간 데이터 다운로드
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={() => downloadExtraIndexExcel("M")}
                  loading={isMonthlyDownload}
                >
                  월간 데이터 다운로드
                </LoadingButton>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <DateRange onSubmit={onChangeDate} />
        </Grid>
      </Grid>
      <Box>
        <PriceLineChart
          market={market}
          index={isKrwChecked ? krwChartIndex : indexData.chartBaseDates}
          data={isKrwChecked ? krwChartData : chartData}
          options={chartOption}
          height={400}
        />
      </Box>
    </>
  );
};
