import { Button, TextField, Grid } from "@mui/material";
import { useRecoilState } from "recoil"
import { dateRangeBeginState, dateRangeEndState } from "@states/asset"
import SearchIcon from "@mui/icons-material/Search";

export type DateRangeSubmitEvent = (DateRangeType) => void;

export type DateRangeType = {
  begin?: string | null;
  end?: string | null;
};

export type DateRangeProps = {
  onSubmit?: DateRangeSubmitEvent;
};

export const DateRange = (props: DateRangeProps) => {
  const [begin, setBegin] = useRecoilState(dateRangeBeginState);
  const [end, setEnd] = useRecoilState(dateRangeEndState);

  return (
    <Grid container spacing={1}>
      <Grid item xs={5} md={5}>
        <TextField
          size="small"
          type="date"
          value={begin}
          onChange={(e) => {
            if(e.target.value===""){
              setBegin(null)  
            }else{
              setBegin(e.target.value);
            }
          }}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={5} md={5}>
        <TextField
          size="small"
          type="date"
          value={end}
          onChange={(e) => {
            if(e.target.value===""){
              setEnd(null);  
            }else{
              setEnd(e.target.value);
            }
          }}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={2} md={2}>
        <Button
          variant="outlined"
          size="small"
          style={{ width: "100%", minWidth: 45, height: 40 }}
          onClick={(e) => {
            if (props.onSubmit) {
              props.onSubmit({ begin, end });
            }
          }}
        >
          <SearchIcon />
        </Button>
      </Grid>
    </Grid>
  );
};

export default DateRange