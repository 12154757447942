import { useEffect, useMemo, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";
import { cloneDeep, isEmpty } from "lodash";
// material
import {
  CardHeader,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Stack,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  logToggleState,
  dateRangeBeginState,
  dateRangeEndState,
  isKrwCheckedState,
} from "@states/asset";
import AnnotationsOption, { updateAnnotations } from "./AnnotationsOption";
import { ChartNavigator } from "./ChartNavigator";
import { PERIOD_TIME_MAP } from "@components/tab/PeriodTab/define";
import ChartOptions from "./ChartOptions";
import { formulaFilter, modifiyConfig } from "./ChartOptions/modules";
import { ApexOptions } from "apexcharts";
import PriceLogChart from "./PriceLogChart";
// ----------------------------------------------------------------------

export type PriceLineChartProps = {
  title?: string;
  index?: any;
  data: any;
  options?: any;
  market?: string;
  height: number;
  showBaseDate?: boolean;
  showAnnotations?: boolean;
  showNavigator?: boolean;
  showChartOption?: boolean;
  showToolbar?: boolean;
};

export const DefaultOptions: ApexOptions = {
  chart: {
    id: "chart#1",
    animations: {
      enabled: false,
    },
    zoom: {
      autoScaleYaxis: true,
    },
    sparkline: {
      enabled: false,
    },
  },
  xaxis: {
    type: "datetime",
    labels: {
      datetimeFormatter: {
        year: "yyyy",
        month: "yyyy.MM",
        day: "MM.dd",
        hour: "HH:mm",
      },
    },
  },
  legend: {
    fontSize: "15",
    fontWeight: "800",
    fontFamily: "Pretendard",
    markers: {
      width: 13,
      height: 13,
      radius: 0,
    },
  },
  yaxis: {
    labels: {
      formatter: (v) => (typeof v === "number" ? v.toFixed(2) : v),
    },
    //라이브러리 버그로 인해 현재 적용 안하는중. (logarithmic일때 차트 min, max option 사용시 깨짐)
    min: function (min) {
      return min;
    },
    max: function (max) {
      return max;
    },
    forceNiceScale: true,
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
    show: true,
    width: [1, 1, 1],
    dashArray: 0,
  },
  tooltip: {
    enabled: true,
    x: {
      format: "yyyy-MM-dd",
    },
  },
  annotations: {},
};

const mergeOption = (extraOption) => {
  const chartOptions = { ...DefaultOptions, ...extraOption };
  return cloneDeep(chartOptions);
};

const StartEnd = ({ dates }) => {
  if (!dates) {
    return <></>;
  }
  if (dates && dates.length === 0) {
    return <></>;
  }
  return (
    <Typography variant="body1">
      {dates[0].split("T")[0]} ~ {dates[dates.length - 1].split("T")[0]}
    </Typography>
  );
};

export default function PriceLineChart({
  title,
  index,
  data,
  options,
  height,
  market,
  showAnnotations = true,
  showBaseDate = true,
  showNavigator = false,
  showChartOption = true,
  showToolbar = true,
}: PriceLineChartProps) {
  const [logToggle, setLogToggle] = useRecoilState(logToggleState);
  const [krwToggle, setKrwToggle] = useRecoilState(isKrwCheckedState);
  const setBegin = useSetRecoilState(dateRangeBeginState);
  const setEnd = useSetRecoilState(dateRangeEndState);
  const [isLog, setIsLog] = useState(false);
  const [iskrw, setIsKrw] = useState(false);
  const [isShowAnnotations, setIsShowAnnotations] = useState(showAnnotations);
  const [area, setArea] = useState("Recession");
  const [period, setPeriod] = useState("all");
  const [config, setConfig] = useState<any>({});
  const location = useLocation()
  const chartRef = useRef<any>()
  const chartOptions = useMemo(() => {
    let modifiedOption = mergeOption(options);
    modifiedOption = updateAnnotations(modifiedOption, isShowAnnotations, area);
    modifiedOption.xaxis.categories = index?.map((v) => Date.parse(v));
    modifiedOption.xaxis.min = PERIOD_TIME_MAP[period]?.min;

    if (showChartOption && !isEmpty(config)) {
      modifiyConfig(modifiedOption, config);
    }
    return modifiedOption;
  }, [options, isShowAnnotations, period, area, index, config]);

  const filterData = useMemo(() => {
    if (isEmpty(config)) {
      return data;
    }

    return formulaFilter(config, data);
  }, [config, data]);

  const handleConfigChange = (e) => {
    setConfig(e);
  };
  const toggleLogDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    const value = event.target.value ? open : false;
    setIsLog(event.target.checked);
    setLogToggle(value);
  };

  useEffect(() => {
    setIsLog(logToggle);
  }, [logToggle]);

  useEffect(() => {
    setIsKrw(krwToggle);
  }, [krwToggle]);

  useEffect(() => {
    setBegin(index[0]);
    setEnd(index[index.length - 1]);
  }, [index]);


  useEffect(()=>{
    if(location.pathname === "/backtest2/proxy_data"){
      chartRef?.current?.chart?.toggleSeries('기초지수(보수반영)')
      chartRef?.current?.chart?.toggleSeries('기초지수(최적화)')
    }
  },[])

  return (
    <Box sx={{ paddingTop: 0, mt: 2 }}>
      {title && <CardHeader title={title} />}
      {showToolbar && (
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="space-between"
        >
          <Stack direction="row">
            <FormGroup sx={{ marginLeft: "20px", marginTop: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox checked={isLog} onChange={toggleLogDrawer(true)} />
                }
                label="Log"
              />
            </FormGroup>
            <FormGroup sx={{ marginTop: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isShowAnnotations}
                    onChange={(e) => {
                      setIsShowAnnotations(e.target.checked);
                    }}
                  />
                }
                label="Area"
              />
            </FormGroup>
            {market === "us" && (
              <FormGroup sx={{ marginTop: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={iskrw}
                      onChange={(e) => setKrwToggle(e.target.checked)}
                    />
                  }
                  label="원화 환산"
                />
              </FormGroup>
            )}
            <AnnotationsOption
              value={area}
              onChange={(value) => setArea(value)}
            />
            {showChartOption && (
              <ChartOptions
                value={data}
                config={config}
                onChange={(value) => handleConfigChange(value)}
              />
            )}
          </Stack>
          <Stack direction="row" spacing={2} sx={{ p: 1 }}>
            {showBaseDate && <StartEnd dates={index} />}
          </Stack>
        </Grid>
      )}
      <ReactApexChart
        ref={chartRef}
        type={config.type ? config.type : "line"}
        series={filterData}
        options={chartOptions}
        height={height}
      />
      {showNavigator && <ChartNavigator data={data} options={chartOptions} />}
      {logToggle === true && (
        <PriceLogChart series={data} date={index} mode={"log"} />
      )}
    </Box>
  );
}
