import { useRecoilValue } from "recoil";
import { selectedRowState, artificailIndexDataState } from "@states/fundKr";
import { FundAssetTitle } from "@pages/asset/styles";
// import AssetMemo from "../../detail/AssetMemo";

function Header({ name = "종목명" }) {
  const selectedRow = useRecoilValue(selectedRowState);
  const fundKrData = useRecoilValue(artificailIndexDataState);

  return (
    selectedRow && (
      <>
        <FundAssetTitle
          ticker={selectedRow.ticker}
          name={selectedRow[name]}
          is_missing={fundKrData?.["is_missing"]}
        />
        {/* <AssetMemo rf_id={selectedRow.rf_id} memo={selectedRow["memo"]} /> */}
      </>
    )
  );
}

export default Header;
