import axios from 'axios';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  config.headers.setAuthorization(`Bearer ${token}`);
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error?.response?.status)
    if (error?.response?.status === 401) {
      localStorage.removeItem("accessToken");
      window.location.replace("/");
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  },
);

export default axiosInstance;
