import { Box } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { cloneDeep } from 'lodash';
import { useMemo } from "react";

const defaultOptions: any = {
    chart: {
      id: 'simple#1',
      animations: {
        enabled: false,
      },
      toolbar:{
        show:false
      }
    },
    xaxis: {
      labels: {
        show: true,
      }
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      show:false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    markers: {
      size: 5,
    },
    grid: {
      padding: {
        left: 80,
        right: 40,
      },
      show: false,
    },
    tooltip: {
      enabled: false,
      x: {
        format: "MM",
      },
    },
    annotations: { postion: "back" },
  };

export default function SimpleYTDChart({ data, height }) {
  const option = useMemo( () => {
    const cloneOption = cloneDeep(defaultOptions);
    cloneOption.xaxis.categories = data.index;
    return cloneOption;
  }, [data])
  
  return (
    <Box sx={{ paddingTop: 0 }}>
      <ReactApexChart
        type="line"
        series={data.values}
        options={option}
        height={height}
      />
    </Box>
  );
}
  