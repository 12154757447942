import BacktestNav from "./BacktestNav";
import BacktestMarketRadio from "./BacktestMarketRadio";
import BacktestStrategy from "./BacktestStrategy";
import { ResultLoader } from "./ResultLoader";
import BacktestRunBtn from "./BacktestRunBtn";

export default function BacktestRunForm() {
  return (
    <>
      <BacktestNav />
      <BacktestMarketRadio />
      <BacktestStrategy />
      <BacktestRunBtn />
      <ResultLoader />
    </>
  );
}
