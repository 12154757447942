// routes
import Router from "./routes";
import { BrowserRouter } from "react-router-dom";

// themes
import ThemeConfig from "./themes";

// hooks
import useAuth from "./hooks/useAuth";

// components
import LoadingScreen from "./components/LoadingScreen";
import ScrollToTop from "./components/ScrollToTop";
import NotistackProvider from "./components/NotistackProvider";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ErrorBoundary from "./components/error/ErrorBoundary"

function App() {
  const { isInitialized } = useAuth();
  return (
    
    <BrowserRouter>
      <ThemeConfig>
        <ThemePrimaryColor>
            <NotistackProvider>
              <ScrollToTop /> 
              <ErrorBoundary>
              {isInitialized ? <Router /> : <LoadingScreen />}
              </ErrorBoundary>
            </NotistackProvider> 
        </ThemePrimaryColor>
      </ThemeConfig>
    </BrowserRouter>
  );
}

export default App;
