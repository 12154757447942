export const makeName = (name,data) => {
    if (name === "asset") {
      return data.name;
    }
    if (name === "undl") {
      return "기초지수";
    }
    if (name === "undl_minimize") {
      return "기초지수(최적화)";
    }
    if (name === "undl_ter") {
      return "기초지수(보수반영)";
    }
    if (name === "a-b") {
      return `${data.name} - 지수`;
    }
  };