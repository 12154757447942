import React, { ReactElement } from "react";
import { selectedRowState } from "@states/fundKr";
import { useRecoilValue } from "recoil";
import UndlPanel from "./Panel/UndlPanel";
import AnalysisPanel from "./Panel/AnalysisPanel";
import { TabContents } from "@components/tab/TabPanel";
import FeePanel from "./Panel/FeePanel"

const Contents = [
  {
    label: "가상 지수",
    component: <UndlPanel />,
  },
  {
    label: "분석",
    component: <AnalysisPanel />,
  },
  {    
    label: "수수료",
    component: <FeePanel/>,
  },
];

function AssetViewerTabs(): ReactElement {
  const selectedRow = useRecoilValue(selectedRowState);
  if (!selectedRow) {
    return <></>;
  }

  return (
    <TabContents tabs={Contents} />
  );
}

export default AssetViewerTabs;
