import { Suspense } from "react";
import { Stack, Box } from "@mui/material";
import { PageTitle } from "@components/ui/styles";
import { useRecoilValue } from "recoil";
import Page from "@components/Page";
import {staticBacktest2LoadingState, staticBacktest2BacktestDetailState} from "@states/staticBacktest2";
import LoadingScreen from "@components/LoadingScreen";
import BacktestRunForm from "./RunForm";
import DataTabs from "./DataTabs";

export default function StaticBacktest2() {
  const dynamicLoading = useRecoilValue(staticBacktest2LoadingState);
  const backtestDetail = useRecoilValue(staticBacktest2BacktestDetailState);
  return (
    <Page title="정적 전략 백테스트">
      <Stack spacing={2}>
        <PageTitle>정적 전략 백테스트 V2</PageTitle>
        <Suspense fallback={<LoadingScreen />}>
          <BacktestRunForm />
          {dynamicLoading ? (
            <Box sx={{ height: "200px" }}>
              <LoadingScreen />
            </Box>
          ) : backtestDetail ? (
            <DataTabs />
          ) : (
            <div></div>
          )}
        </Suspense>
      </Stack>
    </Page>
  );
}
