import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useRecoilValue } from "recoil";
import {
  fundKRFeeState
} from "@states/fundKr";
import { CSToolbar } from '@components/grid/CSToolbar';

export default function Summary() {
  const feeData = useRecoilValue(fundKRFeeState);
  if (!feeData) {
    return <></>
  }
  
  const {columns, rows } = feeData;

  const portColums = columns.map((portName) => ({
    field: portName, 
    width: 150,
    align:"right"
  }));

  const overviewColumns = [
    { field: "base_dt", headerName: "날짜", width: 150},
    ...portColums,
  ];

  return (
    <Box sx={{ height: 500, m: 0, my: 2 }}>
      <DataGridPro
        components={{ Toolbar: CSToolbar }}
        columns={overviewColumns}
        rows={rows}
        density="compact"
        disableSelectionOnClick
        getRowId={(row) => row.base_dt}
        hideFooter
      />
    </Box>
  );
}
