

export const PALETTE_COLOR = [
  "#008FFB",
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#3F51B5",
  "#03A9F4",
  "#4CAF50",
  "#F9CE1D",
  "#FF9800",
  "#33B2DF",
  "#546E7A",
  "#D4526E",
  "#13D8AA",
  "#A5978B",
  "#4ECDC4",
  "#C7F464",
  "#81D4FA",
  "#546E7A",
  "#FD6A6A",
  "#2B908F",
  "#F9A3A4",
  "#90EE7E",
  "#FA4443",
  "#69D2E7",
  "#449DD1",
  "#F86624",
  "#EA3546",
  "#662E9B",
  "#C5D86D",
  "#D7263D",
  "#1B998B",
  "#2E294E",
  "#F46036",
  "#E2C044",
  "#662E9B",
  "#F86624",
  "#F9C80E",
  "#EA3546",
  "#43BCCD",
  "#5C4742",
  "#A5978B",
  "#8D5B4C",
  "#5A2A27",
  "#C4BBAF",
  "#A300D6",
  "#7D02EB",
  "#5653FE",
  "#2983FF",
  "#00B1F2",
  
  "#008FFB",  // repeat
  "#00E396",
  "#FEB019",
  "#FF4560",
  "#775DD0",
  "#3F51B5",
  "#03A9F4",
  "#4CAF50",
  "#F9CE1D",
  "#FF9800",
  "#33B2DF",
  "#546E7A",
  "#D4526E",
  "#13D8AA",
  "#A5978B",
  "#4ECDC4",
  "#C7F464",
  "#81D4FA",
  "#546E7A",
  "#FD6A6A",
  "#2B908F",
  "#F9A3A4",
  "#90EE7E",
  "#FA4443",
  "#69D2E7",
  "#449DD1",
  "#F86624",
  "#EA3546",
  "#662E9B",
  "#C5D86D",
  "#D7263D",
  "#1B998B",
  "#2E294E",
  "#F46036",
  "#E2C044",
  "#662E9B",
  "#F86624",
  "#F9C80E",
  "#EA3546",
  "#43BCCD",
  "#5C4742",
  "#A5978B",
  "#8D5B4C",
  "#5A2A27",
  "#C4BBAF",
  "#A300D6",
  "#7D02EB",
  "#5653FE",
  "#2983FF",
  "#00B1F2",
];


export function createStartAnnotation(xpos, title = "ETF 운용시작일") {
  const option = {
    annotations: {
      xaxis: [
        {
          x: xpos,
          strokeDashArray: 0,
          borderColor: "#775DD0",
          label: {
            borderColor: "#775DD0",
            style: {
              fontSize: "12px",
              color: "#fff",
              background: "#775DD0",
            },
            text: title,
          },
        },
      ],
    },
  };
  return option;
}

const generateSetting = (text) => {
  if (text in configMap) {
    return configMap[text];
  } else {
    return {
      strokeDashArray: 1,
      borderColor: "#775DD0",
      label: {
        borderColor: "#775DD0",
        orientation: "horizontal",
        rotate: 90,
        offsetY: 0,
        style: {
          fontSize: "12px",
          color: "#fff",
          background: "#775DD0",
        },
      },
    };
  }
};

const targetAnnotate = (values, chartData, enabledList) => {
  return values.map((value) => {
    const { index, ...other } = value;
    return { text: index, x: `${Object.values(other)[0]}T00:00:00` };
  })
  .map((value) => {
    const setting = generateSetting(value.text);
    const index = chartData.index.indexOf(value.x);
    setting.x = Date.parse(value.x);
    setting.y = chartData.values[0].data[index];
    setting.label.text = value.text;
    return setting;
  })
  .filter((value) => {
    return enabledList.includes(value.groupName)
  })
}

export function analysisAnnotation(data, chartData, enabledList) {
  const xTargetName = ["",
    "최장손실시작",
    "최장손실회복",
    "최대손실고통시작",
    "최대손실고통회복",
    "최대낙폭회복",
    "최대낙폭시작",
    "최대손실고통시작",
  ];
  const yTargetName = [
    "최대낙폭시작",
    "최대손실고통시작",
    "최장손실시작",
  ];

  const pointTargetName = [
  "최대낙폭정점",
  "최고점",
  "최저점"
];
  
  const option = {};

  // const xaxis = data
  //   .filter((value) => yTargetName.includes(value.index))
  const xaxis = targetAnnotate(data.filter((value) => xTargetName.includes(value.index)), chartData, enabledList);
  const yaxis = targetAnnotate(data.filter((value) => yTargetName.includes(value.index)), chartData, enabledList);
  const points = targetAnnotate(data.filter((value) => pointTargetName.includes(value.index)), chartData, enabledList);

  option["annotations"] = {
    xaxis: xaxis,
    yaxis: yaxis,
    points: points,
  };
  return option;
}


const configMap = {
  최장손실시작: {
    groupName: "uwp",
    strokeDashArray: 10,
    borderColor: "#1B998B",
    label: {
      // position: "right",
      // orientation: "vertical",
      borderColor: "#1B998B",
      rotate: 90,
      offsetX: 20,
      // offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#1B998B",
      },
    },
  },
  최장손실회복: {
    groupName: "uwp",
    strokeDashArray: 10,
    borderColor: "#1B998B",
    label: {
      borderColor: "#1B998B",
      rotate: 90,
      offsetY: 0,
      
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#1B998B",
      },
    },
  },
  최대손실고통시작: {
    groupName: "uws",
    strokeDashArray: 5,
    borderColor: "#A300D6",
    label: {
      position: "top",
      // orientation: "horizontal",
      borderColor: "#A300D6",
      rotate: 90,
      offsetX: 20,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#A300D6",
      },
    },
  },
  최대손실고통회복: {
    groupName: "uws",
    strokeDashArray: 5,
    borderColor: "#A300D6",
    label: {
      position: "top",
      // orientation: "horizontal",
      borderColor: "#A300D6",
      rotate: 90,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#A300D6",
      },
    },
  },
  최대낙폭정점: {
    groupName: "mdd",
    y: 50,
    strokeDashArray: 14,
    label: {
      position: "bottom",
      orientation: "horizontal",
      borderColor: "#5C4742",
      rotate: 90,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#5C4742",
      },
    },
  },
  최대낙폭시작: {
    groupName: "mdd",
    strokeDashArray: 14,
    borderColor: "#5C4742",
    label: {
      offsetX: 20,
      position: "right",
      // orientation: "horizontal",
      borderColor: "#5C4742",
      rotate: 90,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#5C4742"
      },
    },
  },
  최대낙폭회복: {
    groupName: "mdd",
    strokeDashArray: 14,
    borderColor: "#5C4742",
    label: {
      position: "right",
      // orientation: "horizontal",
      borderColor: "#5C4742",
      rotate: 90,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#5C4742",
      },
    },
  },
  최고점: {
    groupName: "high",
    y: 50,
    strokeDashArray: 1,
    borderColor: "#5C4742",
    label: {
      position: "right",
      // orientation: "horizontal",
      borderColor: "#5C4742",
      rotate: 90,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#5C4742",
      },
    },
  },
  최저점: {
    groupName: "low",
    y: 50,
    strokeDashArray: 1,
    borderColor: "#5C4742",
    label: {
      position: "right",
      // orientation: "horizontal",
      borderColor: "#5C4742",
      rotate: 90,
      offsetY: 0,
      style: {
        fontSize: "12px",
        color: "#fff",
        background: "#5C4742",
      },
    },
  }
};
