import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { TextField, IconButton } from '@mui/material';

type Props = {
  searchText: string;
  setSearchText: (e: string) => void;
};

export const Toolbar = ({ searchText, setSearchText }: Props) => {
  return (
    <>
      <TextField
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        size="small"
        sx={{ width: '100%' }}
        InputProps={{
          startAdornment: <SearchIcon fontSize="small" />,
          endAdornment: (
            <IconButton
              title="Clear"
              aria-label="Clear"
              size="small"
              onClick={() => setSearchText('')}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ),
        }}
      />
    </>
  );
};
