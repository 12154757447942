import React, { useState, Suspense } from "react";

// @material
import { Box, Stack } from "@mui/material";

// components
import Page from "@components/Page";
import LoadingScreen from '@components/LoadingScreen';


import AssetTable from "./AssetListViewer/AssetTable";
import EtfHeader from "./Header/Title";
import EtfTabs from "./Tabs";
import { SideBar } from '@pages/asset/comparison/SideBar';
import { PageTitle } from "@components/ui/styles";
import AssetClassFilter from "./AssetListViewer/AssetClassFilter";

// ----------------------------------------------------------------------

function EtfKROperationsPage() {
  const [checkedAssets, setCheckedAsset] = useState<Array<Object>>([]);
  return (
    <Page title="국내 ETF 종목 탐색">
      <Stack spacing={2}>
        <PageTitle>국내 ETF 종목 탐색</PageTitle>
        <Stack direction="row" sx={{ pl: -10 }}>
          <AssetClassFilter />
          <Box sx={{ width: "100%" }}>
            <Stack spacing={2}>
              <Suspense fallback={<LoadingScreen />}>
                <AssetTable
                  onSelectAsset={(select) => {
                    const updateAsset = [select].concat(checkedAssets);
                    setCheckedAsset(updateAsset);
                  }}
                />
              </Suspense>
              <Suspense fallback={<LoadingScreen />}>
                <EtfHeader />
                <EtfTabs />
              </Suspense>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <SideBar
        assetType="etf"
        market="kr"
        checkedAssets={checkedAssets.map((v) => {
          return { ...v, name: v["종목명"] };
        })}
        onChange={setCheckedAsset}
      />
    </Page>
  );
}

export default React.memo(EtfKROperationsPage);
