import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "@utils/axios";
import { Typography, Stack } from "@mui/material";
import Overview from "@components/Overview";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import {TabContents} from "@components/tab/TabPanel";
import PriceLineChart from "@components/chart/PriceLineChart";
import Page from '@components/Page';
import { createStartAnnotation } from "@utils/chart";
import { reducePriceValue } from "@api/asset";

async function getAssetChart(ticker, market) {
  const response = await axios.get(`etf/${market}/artificial_prices/${ticker}` );
  const responseObj = response.data.data;
  return reducePriceValue(responseObj);
}

async function getSelectedAssetOverview(ticker, market) {
  try {
    const response = await axios.get(`etf/${market}/operation/${ticker}`);
    return response.data.data;
  } catch (e) {
    return;
  }
}

type ChartDateType = {
  index: string[],
  values: "" | [], 
  option: object,
}

function DetailContents({ market, ticker }) {
  const [chartData, setChartData] = useState<ChartDateType|null>(null);
  const [overViewData, setOverViewData] = useState(null);

  const fecther = async () => {
    const backtestDetailChartData = await getAssetChart(ticker, market);
    const { chartData } = backtestDetailChartData;
    const chartOption = createStartAnnotation(backtestDetailChartData.startDate);
    setChartData({
      index: backtestDetailChartData.chartBaseDates as string[],
      option:chartOption,
      values:chartData,        
    });
    const data = await getSelectedAssetOverview(ticker, market);
    setOverViewData(data);
  };

  useEffect(() => {  
    fecther();
  }, [ticker]);
  
  if (!chartData)  {
    return <></>
  }
  
  const TAB_DATA = chartData && [
    {
      id: 0,
      component: <Overview country={market} overViewData={overViewData} />,
      label: "Overview",
    },
    {
      id: 1,
      component: (
        <PriceLineChart
          index={chartData.index}
          data={chartData.values}
          options={chartData.option}
          height={400}
        />
      ),
      label: "Price Index Chart",
    },
  ];
  return chartData && <TabContents tabs={TAB_DATA} />;
}

export function AssetPopUp({ market, ticker, asset, show, onClose }) {
  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={show}
      onClose={onClose}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle id="max-width-dialog-title">{`종목 상세정보 [${ticker} | ${asset.name}]`}</DialogTitle>
      <DialogContent style={{ height: 600 }}>
        {show&&<DetailContents market={market} ticker={ticker} />}
      </DialogContent>
    </Dialog>
  );
}

export default function AssetDetailPage({ market }) {
  const { ticker } = useParams();
  return (
    <Page title="ETF 종목 상세보기" sx={{ minWidth: "750px" }}>
      <Stack spacing={1}>
        <Typography variant="h5" component="h1" paragraph>
          ETF 종목 상세보기
        </Typography>
        <DetailContents market={market} ticker={ticker} />
      </Stack>
    </Page>
  );
}
