import { useRecoilValue } from "recoil";
import { selectedEtfTickerState } from "@states/etfUs";
import { AssetTitle } from "@pages/asset/styles"
// import AssetMemo from '../../../detail/AssetMemo';

function Header({ name = "종목명" }) {
  const selectedEtfTicker = useRecoilValue(selectedEtfTickerState);
  return (
    selectedEtfTicker && (
      <>
        <AssetTitle
          ticker={selectedEtfTicker.ticker}
          name={selectedEtfTicker[name]}
        />
        {/* <AssetMemo  rf_id={selectedEtfTicker.rf_id}  memo={selectedEtfTicker["memo"]} /> */}
      </>
    )
  );
}

export default Header;
