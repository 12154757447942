import { atom, selector } from "recoil";
import axios from "../utils/axios";
import { AssetType } from "types/common";
import { reducePriceValue } from "@api/asset";

export const valueState = atom({
  key: "indexGlobalValueState",
  default: 0,
});

export const indexGlobalChartDateState = atom({
  key: "indexGlobalChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const selectedIndexTickerState = atom<AssetType|null>({
  key: "indexGlobalSelectedEtfTickerState",
  default: null,
});

export const analysisDataState = atom({
  key: "indexGlobalAnalysisDataState",
  default : {load:false}
})

export const simpleOperationListState = selector({
  key: "indexGlobalSimpleOperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`index/global/simple_operations`);
    return response.data.data;
  },
});

export const operationListState = selector({
  key: "indexGlobalOperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`index/global/operations`);
    return response.data.data;
  },
});

export const artificailIndexDataState = selector({
  key: "indexGlobalArtificailIndexDataState",
  get: async ({ get }) => {
    try {
      const selected = get(selectedIndexTickerState);
      if (selected === null) return;
      const { ticker } = selected;
      const dataParam = get(indexGlobalChartDateState);

      const response = await axios.get(`index/global/artificial_prices/${ticker}`, {
        params: dataParam,
      });
      const responseObj = response.data.data;
      return reducePriceValue(responseObj);
    } catch (e) {}
  },
});
