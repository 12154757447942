import React, { useEffect, useState } from "react";
import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { enableFilterState, selectedEtfTickerState, usOperationListState, extraInfoState } from "@states/etfUs";
import { isKrwCheckedState } from "@states/asset";
import { ETFUS, ETFUSSIMPLE } from "@pages/asset/define";
import useSearchHook from "../../../detail/useSearchHook";
import { Checkbox, Grid, FormControlLabel } from "@mui/material";
import OperationDownload from "../OperationDownload";
import { Toolbar } from '@pages/asset/detail/Toolbar';

function EtfTable({ onChangeChecked, onSelectAsset }) {
  const [searchText, setSearch] = useState("");
  const setSelectedEtfTicker = useSetRecoilState(selectedEtfTickerState);
  const etfKrxOperations = useRecoilValue(usOperationListState);
  const [gridData, setGridData] = useState(etfKrxOperations);
  const [enableFilter, setEnableFilter] = useRecoilState(enableFilterState);
  const [enableExtra, setEnableExtra] = useRecoilState(extraInfoState);
  const setIsKrwChecked = useSetRecoilState(isKrwCheckedState);
  
  const [updateSearch] = useSearchHook(
    etfKrxOperations,
    setSearch,
    setGridData
  );

  useEffect(()=>{
    setGridData(etfKrxOperations)
  }, [etfKrxOperations])

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={4}>
          <Toolbar text={searchText} onChangeText={updateSearch} />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableFilter}
                  onChange={(e) => setEnableFilter(e.target.checked)}
                />
              }
              label="상세검색"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableExtra}
                  onChange={(e) => setEnableExtra(e.target.checked)}
                />
              }
              label="확장모드 보기"
            />
          </Grid>
        </Grid>
        {/* <Grid item>
          <OperationDownload />
        </Grid> */}
      </Grid>
      <AssetInformationGrid
        market={"ETFUS"}
        onSelectAsset={(asset) => {
          setSelectedEtfTicker(asset);
          setIsKrwChecked(false)
          if (onSelectAsset) {
            onSelectAsset(asset);
          }
        }}
        columns={enableExtra ? ETFUS : ETFUSSIMPLE}
        data={gridData}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export default EtfTable;
