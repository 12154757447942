import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedIndexTickerState,
  artificailIndexDataState,
  valueState,
  indexGlobalChartDateState,
} from "@states/indexGlobal";
import { useOpenSnackbar } from "@hooks/useSnackbar";
import { AssetViewerExtraUndlInfoColumns } from "@pages/asset/define";

import LoadingButton from "@components/button/LoadingButton";
import PriceLineChart from "@components/chart/PriceLineChart";
import { Box, Card, Grid } from "@mui/material";
import { downloadExcel } from "@utils/download";
import { useState } from "react";
import { DateRangeType } from "@components/DateRange";
import { DateRange } from "@components/DateRange";
import { createStartAnnotation } from "@utils/chart";

function ExtraNavMemo({ extraNavMemo }) {
  if (extraNavMemo.length === 0) {
    return null;
  }
  return (
    <Card sx={{ p: 5, m: 1 }}>
      <ul>
        {extraNavMemo.map((memo, memoIndex) => (
          <li key={memoIndex} style={{ fontWeight: "bold", color: "red" }}>
            {memo}
          </li>
        ))}
      </ul>
    </Card>
  );
}

type Props = {
  value: number;
  selectedAsset: any;
  indexData: any;
  market: string;
  columns: any[];
  assetType?: string;
  date?: DateRangeType;
  onChangeDate?: (e) => void;
};

export const UndlContent = ({
  value,
  selectedAsset,
  indexData,
  market,
  columns,
  date,
  assetType = "etf",
  onChangeDate,
}: Props) => {
  const [isDailyDownload, setIsDailyDownload] = useState(false);
  const [isWeeklyDownload, setIsWeeklyDownload] = useState(false);
  const [isMonthlyDownload, setIsMonthlyDownload] = useState(false);
  const { extraNavMemo, chartData } = indexData;

  const indexChartOptions = createStartAnnotation(indexData.startDate);

  async function downloadExtraIndexExcel(period) {
    const loadFunc = (period === "D") ? setIsDailyDownload :
                    (period === "W") ? setIsWeeklyDownload :
                    (period === "M") ? setIsMonthlyDownload : () => {};
    const fileSuffix = (period === "D") ? "Daily" :
                      (period === "W") ? "Weekly" :
                      (period === "M") ? "Monthly" : "";
    const assetName = selectedAsset["종목명"];
    const filename = `${assetName}-${fileSuffix}.xlsx`;
    const url = `index/global/artificial_prices/${selectedAsset.ticker}/excel?period=${period}`;
    loadFunc(true);
    await downloadExcel(url, filename, date);
    loadFunc(false);
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} md>
          <Grid container item spacing={1}>
            <Grid item>
              <LoadingButton
                onClick={() => downloadExtraIndexExcel("D")}
                loading={isDailyDownload}
              >
                일일 데이터 다운로드
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                onClick={() => downloadExtraIndexExcel("W")}
                loading={isWeeklyDownload}
              >
                주간 데이터 다운로드
              </LoadingButton>
            </Grid>
            <Grid item>
              <LoadingButton
                onClick={() => downloadExtraIndexExcel("M")}
                loading={isMonthlyDownload}
              >
                월간 데이터 다운로드
              </LoadingButton>{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <DateRange onSubmit={onChangeDate} />
        </Grid>
      </Grid>
      <Box>
        <PriceLineChart
          index={indexData.chartBaseDates}
          data={chartData}
          options={indexChartOptions}
          height={400}
        />
      </Box>
      </>
   
  );
};

function UndlPanel() {
  const selectedEtfTicker = useRecoilValue(selectedIndexTickerState);
  const value = useRecoilValue(valueState);
  const artificailIndexData = useRecoilValue(artificailIndexDataState);
  const [chartDate, setChartDate] = useRecoilState(indexGlobalChartDateState);
  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    if (!artificailIndexData) {
      openSnackbar("데이터가 없습니다. 😰", "error");
    } else return;
  }, [artificailIndexData, openSnackbar]);

  return (
    <UndlContent
      value={value}
      columns={AssetViewerExtraUndlInfoColumns}
      indexData={artificailIndexData}
      market="us"
      selectedAsset={selectedEtfTicker}
      date={chartDate}
      onChangeDate={setChartDate}
    />
  );
}

export default UndlPanel;
