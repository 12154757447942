import { ArtificailIndexDataType } from "types/common";
import axios from "@utils/axios";

export const getAnalysis = async (
    ticker,
    assetType,
    market,
    begin: null | string = "20000101",
    end : null | string = null,
    moving_avg: boolean = false
  ) => {
    const url = `${assetType}/${market}/analysis/${ticker}`;
    const response = await axios.get(url, { params: { begin, end, moving_avg } });
    return response.data.data;
};


export const reducePriceValue = (responseObj): ArtificailIndexDataType => {
  try {
    const indexData: ArtificailIndexDataType = {
      records: responseObj.records,
      monthlyRecords: responseObj.monthly_records,
      chartBaseDates: responseObj.base_dates,
      chartData: responseObj.chart_data,
      startDate: Date.parse(responseObj.start_date),
      extraNavMemo: responseObj.extra_nav_memo,
      undlInfo: responseObj.undl_info,
      is_missing: responseObj.is_missing
    };
    return indexData;
  } catch (e) {
    const responseObjFormat: ArtificailIndexDataType = {
      records: "",
      monthlyRecords: "",
      chartBaseDates: [],
      chartData: [],
      startDate: "",
      extraNavMemo: "",
      undlInfo: "",
      is_missing: true
    };
    return responseObjFormat;
  }
};