// routes
import {
  PATH_ASSET_INDEX,
  PATH_ASSET,
  PATH_ETF,
  PATH_FUND,
  PATH_STOCK,
  PATH_REALTY,
  // PATH_PRODUCT_MONITOR,
  // PATH_DATA_MONITOR,
  // PATH_DATA,
  PATH_BACKTEST_2
} from "../../routes/paths";

// components
import { getIcon } from "../../components/asset/SvgIconStyle";

// ----------------------------------------------------------------------


const ICONS = {
  user: getIcon("ic_user"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  stack: getIcon("ic_elements"),
  page: getIcon("ic_page"),
  monitor: getIcon("ic_kanban"),
  calendar: getIcon("ic_calendar"),
  performance: getIcon("ic_dashboard"),
  cart: getIcon("ic_cart"),
  pieChart: getIcon("ic_charts"),
  components: getIcon("ic_components"),
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "데이터",
    items: [
      {
        title: "ETF 데이터",
        path: PATH_ETF.root,
        icon: ICONS.stack,
        children: [
          { title: "국내", path: PATH_ETF.krx },
          { title: "미국", path: PATH_ETF.us },
        ],
      },
      {
        title: "주식 데이터",
        path: PATH_STOCK.root,
        icon: ICONS.stack,
        children: [
          { title: "국내", path: PATH_STOCK.kr },
        ],
      },
      {
        title: "펀드 데이터",
        path: PATH_FUND.root,
        icon: ICONS.stack,
        children: [
          { title: "국내", path: PATH_FUND.kr },
        ],
      },
      {
        title: "지수 데이터",
        path: PATH_ASSET.index_asset,
        icon: ICONS.stack,
        children: [
          { title: "Global", path: PATH_ASSET_INDEX.global },
          // { title: "Macro", path: PATH_ASSET_INDEX.macro },
          // { title: "MSCI", path: PATH_ASSET_INDEX.msci },
          // { title: "MSCI Band", path: PATH_ASSET_INDEX.msciBand },
        ],
      },
      {
        title: "부동산 데이터",
        path: PATH_REALTY.root,
        icon: ICONS.stack,
        children: [
          { title: "지수", path: PATH_REALTY.index },
        ],
      },
    ],
  },
  {
    subheader: "분석",
    items: [
      {
        title: "백테스트",
        path: PATH_BACKTEST_2.root,
        icon: ICONS.analytics,
        children: [
          // { title: "동적 전략 백테스트", path: PATH_BACKTEST_2.dynamicBacktest2 },
          { title: "정적 전략 백테스트 V2", path: PATH_BACKTEST_2.staticBacktest2 },
          // {
          //   title: "외삽 데이터 관리",
          //   path: PATH_BACKTEST_2.proxyData,
          // },
        ],
      },
      // {
      //   title: "데이터 분석",
      //   path: PATH_DATA.root,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     { title: "비교", path: PATH_DATA.comparison },
      //     { title: "엑셀분석", path: PATH_DATA.excel_analysis },
      //   ],
      // },
    ]
  },
  // {
  //   subheader: "모니터링",
  //   items: [
  //     {
  //       title: "상품",
  //       path: PATH_PRODUCT_MONITOR.root,
  //       icon: ICONS.cart,
  //       children: [
  //         {
  //           title: "종목 정보 변경 내역",
  //           path: PATH_PRODUCT_MONITOR.changeLog,
  //         },
  //         { title: "배당 내역", path: PATH_PRODUCT_MONITOR.dividendHistory },
  //       ],
  //     },
  //     // {
  //     //   title: "데이터 관리",
  //     //   path: PATH_DATA_MONITOR.root,
  //     //   icon: ICONS.components,
  //     //   children: [
  //     //     {
  //     //       title: "일일 시계열 데이터 관리",
  //     //       path: PATH_DATA_MONITOR.dailyTimeseries,
  //     //     },
  //     //   ],
  //     // },
  //   ]
  // },
];

export default sidebarConfig;
