import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography, Container } from "@mui/material";
import Page from "@components/Page";

const RootStyle = styled(Page)`
  display: flex;
  min-height: 100%;
  align-items: center;
  padding-top: ${({theme})=> theme.spacing(20) };
  padding-bottom: ${({theme})=> theme.spacing(10) };
`

export default function Page404() {
  return (
    <RootStyle title="프리즘 백테스트 시스템">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h3" paragraph>
            존재하지 않는 페이지입니다.
          </Typography>

          <Typography sx={{ color: "text.secondary" }}>
            궁금하신 사항은 프리즘 투자자문으로 문의해 주시기 바랍니다.
          </Typography>

          <Button
            to="/"
            size="large"
            variant="contained"
            component={RouterLink}
          >
            홈으로
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
