import React, { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  artificailIndexDataState,
  fundKRChartDateState,
  selectedRowState,
} from "@states/fundKr";

import { useOpenSnackbar } from "@hooks/useSnackbar";
import { AssetViewerExtraUndlInfoColumns } from "../../../define";
import { UndlContent } from '../../../detail/UndlContent';

function UndlPanel() {
  const selectedEtfTicker = useRecoilValue(selectedRowState);
  const artificailIndexData = useRecoilValue(artificailIndexDataState);
  const [chartDate, setChartDate] = useRecoilState(fundKRChartDateState);
  
  const openSnackbar = useOpenSnackbar();

  useEffect(() => {
    if (!artificailIndexData) {
      openSnackbar("데이터가 없습니다. 😰", "error");
    } else return;
  }, [artificailIndexData, openSnackbar]);

  return (
    <UndlContent
      columns={AssetViewerExtraUndlInfoColumns}
      indexData={artificailIndexData}
      market="kr"
      assetType="fund"
      selectedAsset={selectedEtfTicker}
      date={chartDate}
      onChangeDate={setChartDate}
    />
  );
}

export default UndlPanel;
