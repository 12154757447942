import _ from "lodash";
import { Button, Card, Stack } from "@mui/material";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import producer from "immer";

import {
  staticBacktest2StrategiesState,
  staticBacktest2BacktestDetailState,
  staticBacktest2ConfigState,
  staticBacktest2LoadingState,
} from "@states/staticBacktest2";
import axios from "@utils/axios";
import { IReqBacktest2Static, IBacktest2StaticPortfolio, IBacktest2StaticStrategy } from "types/backtest2";
import { STATIC_STRATEGY_DEFAULT_3 } from "../../define";

export default function BacktestRunBtn() {
  const backtestConfig = useRecoilValue(staticBacktest2ConfigState);
  const backtestName = backtestConfig.name;
  const startDate = backtestConfig.bt_start;
  const endDate = backtestConfig.bt_end;
  const frequency = backtestConfig.data_freq;
  const benchmarkList = backtestConfig.benchmark;
  const dynamicTradingCostValue = backtestConfig.cost;
  const market = backtestConfig.market;

  const setDynamicLoading = useSetRecoilState(staticBacktest2LoadingState);
  const [strategies, setStrategies] = useRecoilState(staticBacktest2StrategiesState);
  const setDynamicBacktestDetail = useSetRecoilState(
    staticBacktest2BacktestDetailState
  );

  const validationBacktestParam = () => {
    const ratioArray = strategies?.map((strategy) =>
      strategy.assets.reduce((acc, val) => Number(acc) + Number(val.weight), 0)
    );

    if (startDate === "" || endDate === "") {
      alert("백테스트 수행 날짜를 입력하세요.");
      return false;
    }

    if (backtestName === "") {
      alert("백테스트 이름을 입력해주세요.");
      return false;
    }

    if (!ratioArray.every((sum) => sum === 100)) {
      alert("포트폴리오 종목 비율 합을 100%로 설정해주세요.");
      return false;
    }

    if (dynamicTradingCostValue === "") {
      alert("백테스트 거래 수수료를 입력해주세요.");
      return false;
    }

    for (const strategy of strategies) {
      if (strategy.name === "") {
        alert("전략 이름을 입력해주세요.");
        return false;
      }

      if (strategy.assets.length === 0) {
        alert("전략 내 자산이 한 개도 없습니다.");
        return false;
      }

      for (const asset of strategy.assets) {
        if (asset.id === "") {
          alert(
            "지정되지 않은 종목이 있습니다. 종목을 삭제하시거나 지정해주세요."
          );
          return false;
        }
      }
    }
    return true;
  };

  const to_port_list = (strategies: IBacktest2StaticStrategy[]): IBacktest2StaticPortfolio[] => {
    return strategies.map((strategy: IBacktest2StaticStrategy): IBacktest2StaticPortfolio => {
      const port: object = strategy.assets.reduce((acc, curr) => {
        acc[curr.id] = curr.weight * 0.01;
        return acc;
      }, {});

      let value: IBacktest2StaticPortfolio = {
        name: strategy.name,
        port,
      };

      if (strategy.rebal_method === "None") {
        value = {
          name: strategy.name,
          port,
          rebal_method: strategy.rebal_method,
        }
      } else if (strategy.rebal_method === "Static") {
        value = {
          name: strategy.name,
          port,
          rebal_freq: strategy.rebal_freq,
          rebal_method: strategy.rebal_method,
        };
      } else if (strategy.rebal_method === "Dynamic") {
        value = {
          name: strategy.name,
          port,
          threshold: (strategy.threshold || 0) / 100,
          rebal_method: strategy.rebal_method,
        };
      }

      return value;
    });
  }

  const runBacktest = () => {
    if (!validationBacktestParam()) {
      return;
    }
    setDynamicLoading(true);
    const port_list = to_port_list(strategies);
    const req_body: IReqBacktest2Static = {
      name: backtestName,
      bt_start: startDate,
      bt_end: endDate,
      data_freq: frequency,
      cost: parseFloat(dynamicTradingCostValue) / 100,
      benchmark: benchmarkList,
      port_list: port_list,
      strategies: strategies,
      market: market,
    };

    axios
      .post("backtest_2/custom", req_body)
      .then((res) => setDynamicBacktestDetail(res.data.data))
      .catch((error) => {
        alert("error");
      });
  };

  const addStrategy = () => {
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        draft.push(_.cloneDeep(STATIC_STRATEGY_DEFAULT_3));
      })
    );
  };

  return (
    <Card sx={{ p: 3 }}>
      <Stack spacing={2} direction="row">
        <Button
          sx={{ width: "100%", height: 40 }}
          variant="outlined"
          onClick={addStrategy}
        >
          전략 추가
        </Button>
        <Button
          variant="contained"
          onClick={runBacktest}
          size="medium"
          sx={{ width: "100%" }}
        >
          백테스트 실행
        </Button>
      </Stack>
    </Card>
  );
}
