import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { RecoilRoot } from "recoil";

import { AuthProvider } from "./contexts/JWTContext";
import { LicenseInfo } from "@mui/x-data-grid-pro";

import { RecoilLogger } from 'recoil-devtools-logger';
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/react";

LicenseInfo.setLicenseKey(
  "086050d7809ab6e297d5a3e2446ae52dT1JERVI6MjgwMTUsRVhQSVJZPTE2NTk5NjA1MjgwMDAsS0VZVkVSU0lPTj0x"
);


if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <HelmetProvider>
    <RecoilRoot>
      {
        process.env.NODE_ENV !== "production" && <RecoilLogger/>
      }
      <AuthProvider>
        <App />
      </AuthProvider>
    </RecoilRoot>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
