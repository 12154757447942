import { useRecoilValue } from "recoil";
import { selectedRealtyIndexTickerState } from "@states/realtyIndex";
import { AssetTitle } from "@pages/asset/styles"

function Header({ name = "종목명" }) {
  const selectedRow = useRecoilValue(selectedRealtyIndexTickerState);
  return (
    selectedRow && (
      <AssetTitle
        ticker={selectedRow.ticker}
        name={selectedRow[name]}
      />
    )
  );
}

export default Header;
