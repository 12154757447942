
import { useRef, useState } from 'react';
import { Box, Button,  FormControl, InputLabel, Select } from '@mui/material';

import MenuItem from "@mui/material/MenuItem";
import Iconify from '../asset/Iconify';
import { OptionPopover } from './OptionPopover';

const AREA_ITEMS = [
  {
    name: 'Recession',
    value: "Recession"
  },
  {
    name: 'MarginLoan(KOPSI)',
    value: "ML-KOPSI"
  },
  {
    name: 'MarginLoad(KOSDAQ)',
    value: "ML-KOSDAQ"
  }
]

const MarginLoan = [
  {
    name: "코스피시장",
    dates: [
      ["2011-08-05", 0],
      ["2011-08-08", 0],
      ["2011-08-09", 0],
      ["2011-08-26", 1],
      ["2011-08-29", 1],
      ["2011-08-30", 1],
      ["2011-08-31", 1],
      ["2011-09-01", 1],
      ["2011-09-02", 1],
      ["2011-10-11", 1],
      ["2011-10-13", 1],
      ["2018-02-09", 0],
      ["2018-11-01", 1],
      ["2018-11-02", 1],
      ["2018-11-05", 1],
      ["2018-11-06", 1],
      ["2020-03-12", 0],
      ["2020-03-13", 0],
      ["2020-03-24", 1],
      ["2020-03-24", 1],
      ["2020-03-25", 1],
      ["2020-03-26", 1],
      ["2020-03-27", 1],
      ["2020-03-30", 1],
      ["2020-03-31", 1],
      ["2020-06-15", 0],
      ["2020-06-29", 0],
      ["2020-08-20", 0],
      ["2021-02-24", 0],
      ["2022-02-04", 1],
      ["2022-02-07", 1],
      ["2022-02-08", 1],
      ["2022-06-27", 1],
      ["2022-06-28", 1],
      ["2022-06-29", 1],
      ["2022-10-17", 1],
      ["2022-10-18", 1],
    ],
  },
  {
    name: "코스닥시장",
    dates: [
      ["2011-08-08", 0],
      ["2011-08-09", 0],
      ["2011-08-26", 1],
      ["2011-08-27", 1],
      ["2011-08-28", 1],
      ["2011-08-31", 1],
      ["2016-02-12", 0],
      ["2018-10-11", 0],
      ["2018-11-01", 1],
      ["2018-11-02", 1],
      ["2018-11-05", 1],
      ["2018-11-06", 1],
      ["2019-08-08", 1],
      ["2019-08-09", 1],
      ["2019-08-12", 1],
      ["2019-08-13", 1],
      ["2019-08-14", 1],
      ["2019-08-21", 1],
      ["2020-02-24", 0],
      ["2020-02-28", 0],
      ["2020-03-12", 0],
      ["2020-03-13", 0],
      ["2020-03-16", 0],
      ["2020-03-20", 1],
      ["2020-03-23", 1],
      ["2020-03-24", 1],
      ["2020-03-25", 1],
      ["2020-03-26", 1],
      ["2020-03-27", 1],
      ["2020-03-30", 1],
      ["2020-03-31", 1],
      ["2020-04-01", 1],
      ["2020-04-02", 1],
      ["2020-04-03", 1],
      ["2020-04-06", 1],
      ["2020-04-07", 1],
      ["2020-04-08", 1],
      ["2020-04-09", 1],
      ["2020-06-15", 0],
      ["2020-08-18", 0],
      ["2021-08-20", 0],
      ["2022-06-28", 1],
      ["2022-07-07", 1],
      ["2022-07-08", 1],
      ["2022-10-06", 1],
      ["2022-10-18", 1],
    ],
  },
];
  
const SignalTitle = ["채권", " 주식"];
  
const RecessionDates = [
  ["1857-06-01", "1858-12-01"],
  ["1860-10-01", "1861-06-01"],
  ["1865-04-01", "1867-12-01"],
  ["1869-06-01", "1870-12-01"],
  ["1873-10-01", "1879-03-01"],
  ["1882-03-01", "1885-05-01"],
  ["1887-03-01", "1888-04-01"],
  ["1890-07-01", "1891-05-01"],
  ["1893-01-01", "1894-06-01"],
  ["1895-12-01", "1897-06-01"],
  ["1899-06-01", "1900-12-01"],
  ["1902-09-01", "1904-08-01"],
  ["1907-05-01", "1908-06-01"],
  ["1910-01-01", "1912-01-01"],
  ["1913-01-01", "1914-12-01"],
  ["1918-08-01", "1919-03-01"],
  ["1920-01-01", "1921-07-01"],
  ["1923-05-01", "1924-07-01"],
  ["1926-10-01", "1927-11-01"],
  ["1929-08-01", "1933-03-01"],
  ["1937-05-01", "1938-06-01"],
  ["1945-02-01", "1945-10-01"],
  ["1948-11-01", "1949-10-01"],
  ["1953-07-01", "1954-05-01"],
  ["1957-08-01", "1958-04-01"],
  ["1960-04-01", "1961-02-01"],
  ["1969-12-01", "1970-11-01"],
  ["1973-11-01", "1975-03-01"],
  ["1980-01-01", "1980-07-01"],
  ["1981-07-01", "1982-11-01"],
  ["1990-07-01", "1991-03-01"],
  ["2001-03-01", "2001-11-01"],
  ["2007-12-01", "2009-06-01"],
  ["2020-02-01", "2020-04-01"],
];
  
const RecessionAnnotations = RecessionDates.map((dates) => {
  return {
    x: new Date(dates[0]).getTime(),
    x2: new Date(dates[1]).getTime(),
    fillColor: "#aaa",
    opacity: 0.4,
    label: {
      borderColor: "#888",
      style: {
        fontSize: "10px",
        color: "#fff",
        background: "#888",
      },
    },
  };
});

const createMarginLoanAnnotations = (value) => {
  const index = "ML-KOPSI" === value ? 0 : 1;
  const MarginLoanAnnotations = MarginLoan[index]["dates"].map((date) => {
      const signal = date[1];
      return {
        x: new Date(date[0]).getTime(),
        strokeDashArray: 3,
        opacity: 1,
        borderColor: signal === 0 ? "blue" : "red",
        label: {
          borderColor: signal === 0 ? "blue" : "red",
          style: {
            fontSize: "12px",
            color: "#fff",
            background: signal === 0 ? "blue" : "red",
          },
          text: SignalTitle[signal],
        },
      };
  });
  return MarginLoanAnnotations;
}

export  const updateAnnotations = (option, isShowAnnotations, area: string) => {
    if (isShowAnnotations) {
      let extra: any = RecessionAnnotations;
      switch (area) {
        case "Recession":
          break;
        case "ML-KOPSI":
          extra = createMarginLoanAnnotations(area);
          break;
        case "ML-KOSDAQ":
          extra = createMarginLoanAnnotations(area);
          break;
      }
      
      if (option?.annotations?.xaxis) {
        option.annotations.xaxis = [...option.annotations.xaxis, ...extra];
      } else if (option?.annotations) {
        option.annotations.xaxis = [...extra];
      } else {
        option.annotations = {xaxis: [...extra]};
      }
    }
    return option;
};

export default function AnnotationsOption({value, onChange}) {
  
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  
  return (
    <>
      <Button
        ref={anchorRef}
        size="small"
        onClick={handleOpen}
        style={{ width: "35px", minWidth: "35px" }}
        sx={{ p: 0, mt: 1 }}
      >
        <Iconify
          icon="eva:settings-2-outline"
          sx={{ p: 0, width: 20, height: 20 }}
        />
      </Button>
      <OptionPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <FormControl size="small" sx={{ width: "100%", px: 1, pb: 0 }}>
            <InputLabel id="chart-select-area">Area</InputLabel>
            <Select
              labelId="chart-select-area"
              id="chart-select-area"
              label="Area"
              value={value}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            >
              {AREA_ITEMS.map((item, key) => (
                <MenuItem key={key} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </OptionPopover>
    </>
  );
}
