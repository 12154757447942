import { Box, Stack, Grid } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { etfKrDailyState, etfKRDailyDataDateState } from "@states/etfKr";
import { CSToolbar } from "@components/grid/CSToolbar";
import { toCommaNumber } from "@pages/asset/define";
import { DateRange } from "@components/DateRange";
import CandleChart from "@components/chart/CandleChart"

export default function Summary() {
  const etfKrDailyData = useRecoilValue(etfKrDailyState);
  const setEtfKRDailyDataDate = useSetRecoilState(etfKRDailyDataDateState);

  if (!etfKrDailyData) {
    return <></>;
  }

  const { columns, rows } = etfKrDailyData;

  const overviewColumns = columns.map((portName) => ({
    field: portName,
    width: 120,
    align: portName === "날짜" ? "left" : "right",
    valueFormatter: toCommaNumber,
  }));

  const onChangeDate = ({ begin, end }) => {
    setEtfKRDailyDataDate({ begin, end });
  };

  return (
    <Stack spacing={2}>
      <Grid md={4}>
      <DateRange onSubmit={onChangeDate} />
      </Grid>
      <Stack spacing={8}>
      <CandleChart/>
      <Box sx={{ height: 500 }}>
        <DataGridPro
          initialState={{ pinnedColumns: { left: ['날짜'] } }}
          components={{ Toolbar: CSToolbar }}
          columns={overviewColumns}
          rows={rows}
          density="compact"
          disableSelectionOnClick
          getRowId={(row) => row["날짜"]}
          hideFooter
        />
      </Box>
      </Stack>
    </Stack>
  );
}
