import { isEmpty, cloneDeep } from "lodash";

import { PALETTE_COLOR } from "@utils/chart";
import { ChartOptionProp, DataOptionProp } from "./type";

export const defaultOption: ChartOptionProp = {
  type: "line",
  gridShow: true,
  curve: "straight",
  logBase: 2,
  dataOptions: [],
};

export const modifiyConfig = (baseValue, modifyChartConfig) => {
  try {
    if (baseValue.grid) {
      baseValue.grid.show = modifyChartConfig.gridShow;
    } else {
      baseValue.grid = { show: modifyChartConfig.gridShow };
    }

    const strokes = modifyChartConfig.dataOptions.map((v) => v.stroke);
    if (baseValue.stroke) {
      baseValue.stroke.width = strokes;
      baseValue.stroke.curve = modifyChartConfig.curve;
    }

    const colors = modifyChartConfig.dataOptions.map((v) => v.color);
    baseValue.colors = colors;
    if (baseValue.yaxis) {
      baseValue.yaxis.logBase = modifyChartConfig.logBase;
    }
  } catch (ex) {
    console.error("error config update");
  }
};

export const formulaFilter = (config, data) => {
  try {
    return config.dataOptions.map((subOption, i) => {
      const func = eval(`(a, i)=>  ${subOption.formula}`);
      return {
        name: data[i].name,
        data: data[i].data.map((a, i) => func(a, i)),
      };
    });
  } catch (ex) {
    console.error("formula filter error ");
  }
  return data;
};

export const generateDefaultOptions = (config, value) => {
  if (!isEmpty(config)) return config;

  const dataOptions: DataOptionProp[] = value.map((v, i) => ({
    id: i,
    name: v.name,
    stroke: 1,
    hide: false,
    formula: "a",
    color: PALETTE_COLOR[i],
  }));

  const cloneConfig = cloneDeep(defaultOption);
  cloneConfig.dataOptions = dataOptions;
  return cloneConfig;
};
