import { Stack, Switch, Typography, SwitchProps, styled  } from "@mui/material";

export const CustomSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
    },
}));

export function CsSwitch({ onTitle, offTitle, checked, handleChange }) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Typography variant="subtitle2">{offTitle}</Typography>
      <CustomSwitch
        defaultChecked
        sx={{ m: 1 }}
        checked={checked}
        onChange={handleChange}
      />
      <Typography variant="subtitle2">{onTitle}</Typography>
    </Stack>
  );
}