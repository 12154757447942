import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";

export const csvOptions = {
  utf8WithBom: true,
};

export function CSToolbar({ extendComponent }) {
  const Exends = extendComponent ? extendComponent : <></>;
  return (
    <>
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport csvOptions={csvOptions}></GridToolbarExport>
        {Exends}
      </GridToolbarContainer>
    </>
  );
}
