import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

export function ReturnBarChart({ chartValue }) {
  const { values, index } = chartValue;

  if (!values) {
    return <></>;
  }

  const base_dt = values.map((row) => row.index);
  const series = index.map((name) => {
    return {
      name: name,
      data: values.map((row) => (row[name] * 100).toFixed(0)),
    };
  });

  const options: ApexOptions = {
    title: {},
    chart: {
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        fontSize: "12px",
        colors: ["rgb(55, 61, 63)"],
      },
      offsetY: -20,
    },
    stroke: {
      show: true,
      width: 1,
    },
    tooltip: {
      x: {
        format: "yyyy",
      },
      y: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    xaxis: {
      categories: base_dt,
      type: "datetime",
    },
    yaxis: {
      tickAmount: 4,
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      width="100%"
      height="500px"
    />
  );
}
