import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useRecoilValue } from "recoil";
import { AnalysisTableType, assetCompareAnalysisState } from "../../../states/asset";
import { ConvertToPercentTextByName } from "@utils/formatNumber"
import { CSToolbar } from '@components/grid/CSToolbar';

export default function Summary() {
  const overview = useRecoilValue(assetCompareAnalysisState);
  if (!overview) {
    return <></>
  }
  const { values, index }: AnalysisTableType = overview;
  const modify = ConvertToPercentTextByName(values);

  const portColums = index.map((portName) => ({
    field: portName,
    width: 150,
    type: "number",
  }));

  const overviewColumns = [
    { field: "index", headerName: "지표", width: 150, type: "number" },
    ...portColums,
  ];

  return (
    <Box sx={{ height: 500, m: 0, my: 2, mb:7 }}>
        <DataGridPro
          components={{ Toolbar: CSToolbar }}
          columns={overviewColumns}
          rows={modify}
          density="compact"
          disableSelectionOnClick
          getRowId={(row) => row.index}
          initialState={{ pinnedColumns: { left: ['index'] } }}
          hideFooter
        />
    </Box>
  );
}
