import { reducePriceValue } from "@api/asset";
import { atom, selector } from "recoil";
import { AssetType } from "types/common";
import axios from "../utils/axios";

export const ALLCOUNTRY = "전체";

export const valueState = atom({
  key: "stockKRValueState",
  default: 0,
});

export const analysisDataState = atom({
  key: "stockKRAnalysisDataState",
  default: { load: false },
});

export const stockKRChartDateState = atom({
  key: "stockKRChartDateState",
  default: {
    begin: null,
    end: null,
  },
});

export const selectedTickerState = atom<AssetType | null>({
  key: "stockKRSelectedTickerState",
  default: null,
});

export const operationListState = selector({
  key: "stockKROperationListState",
  get: async ({ get }) => {
    const response = await axios.get(`data/asset/stockkr/`);
    return response.data.data;
  },
});

export const simpleOperationListState = selector({
  key: "stockKRSimpleOperationListState",
  get: async ({ get }) => {
    const opList = await get(operationListState);
    const convert = opList.map((value) => (
      {
        rf_id: value.rf_id,
        ticker: value.ticker,
        product_name: value["종목명"],
        label: value.ticker + " - " + value["종목명"],
      }
    ));
    return convert;
  },
});

export const artificailIndexDataState = selector({
  key: "stockKRArtificailIndexDataState",
  get: async ({ get }) => {
    try {
      const selected = get(selectedTickerState);
      if (selected === null) return;
      const { ticker } = selected;
      const dataParam = get(stockKRChartDateState);
      const response = await axios.get(
        `data/asset/stockkr/${ticker}/historical`,
        { params: dataParam }
      );
      const responseObj = response.data.data;
      return reducePriceValue(responseObj);
    } catch (e) {}
  },
});
