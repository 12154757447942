import React, { useState, useMemo } from "react";
import _ from "lodash";
import {
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Autocomplete,
  IconButton,
  Grid,
  Button,
  InputAdornment,
  Stack,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useRecoilState, useSetRecoilState, useRecoilRefresher_UNSTABLE } from "recoil";
import {
  staticBacktest2ConfigState,
  staticBacktest2StrategiesState,
  staticBacktest2OverviewState,
  staticBacktest2InitialConfig,
  staticBacktest2HistoriesQuery,
} from "@states/staticBacktest2";
import { StyledCard } from "@components/ui/styles";
import BacktestHistoryDialog from "@pages/backtest/StaticBacktest2/RunForm/BacktestHistoryDialog";
import { STATIC_STRATEGY_DEFAULT_3 } from "@pages/backtest/define";
import { IBacktestV2StaticConfig, IBacktest2StaticStrategy } from "types/backtest2";

const benchmarkInfoList = [
  { bm_name: "KOSPI200 TR", bm_ticker: "LN-0009" },
  { bm_name: "S&P500 TR(H)", bm_ticker: "LN-0006" },
  { bm_name: "S&P500 TR(UH)", bm_ticker: "LN-0007" },
];

function BacktestNav() {
  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState<boolean>(false);
  const [backtestConfig, setBacktestConfig] = useRecoilState(staticBacktest2ConfigState);
  const setStrategies = useSetRecoilState(staticBacktest2StrategiesState);
  const setOverview = useSetRecoilState(staticBacktest2OverviewState);

  const backtest2HistoriesRefresher = useRecoilRefresher_UNSTABLE(staticBacktest2HistoriesQuery);
  
  const handleChange = (name) => (e) => {
    setBacktestConfig({ ...backtestConfig, [name]: e.target.value });
  };
  const handleChangeConfig = (name, value) => {
    const newValue = { ...backtestConfig, [name]: value };
    setBacktestConfig(newValue);
  };

  const benchMarkValue = useMemo(() => {
    return benchmarkInfoList.filter((v) =>
      backtestConfig.benchmark.includes(v.bm_name)
    );
  }, [backtestConfig.benchmark]);

  const handleReset = () => {
    if (window.confirm("전략을 리셋 하시겠습니까??")) {
      setBacktestConfig(staticBacktest2InitialConfig);
      setStrategies([_.cloneDeep(STATIC_STRATEGY_DEFAULT_3)]);
      setOverview([]);
    } else {
    }
  };

  const setBacktestConfigAndStrategies = (config: IBacktestV2StaticConfig, version?: string) => {
    const _config = {...config};

    _config["cost"] = _config["cost"] * 100;
    setStrategies(_config.strategies || []);
    setBacktestConfig(_config);
  };

  return (
    <StyledCard>
      <Grid container spacing={1}>
        <Grid container item spacing={1}>
          <Grid item xs={12} md={4}>
            <TextField
              label="백테스트 이름"
              size="small"
              type="text"
              sx={{ width: "100%" }}
              value={backtestConfig.name}
              onChange={(e) => handleChangeConfig("name", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <ToggleButtonGroup
              color="primary"
              value={backtestConfig.data_freq}
              exclusive
              size="small"
              fullWidth
              onChange={(e, v) => {
                handleChangeConfig("data_freq", v);
              }}
            >
              <ToggleButton value="D">Daily</ToggleButton>
              <ToggleButton value="M">Monthly</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={2} direction={"row"}>
              <Button
                variant="contained"
                onClick={() => {
                  setIsHistoryDialogOpen(true);
                  backtest2HistoriesRefresher();
                }}
                size="medium"
              >
                백테스트 로드
              </Button>
              <IconButton onClick={handleReset}>
                <RefreshIcon/>
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
        <Grid container item spacing={1}>
          <Grid item xs={12} md={2}>
            <TextField
              label="시작 날짜 (yyyymmdd)"
              size="small"
              type="text"
              sx={{ width: "100%" }}
              value={backtestConfig.bt_start}
              onChange={(e) => {
                handleChangeConfig("bt_start", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <TextField
              label="종료 날짜 (yyyymmdd)"
              size="small"
              type="text"
              sx={{ width: "100%" }}
              value={backtestConfig.bt_end}
              onChange={(e) => {
                handleChangeConfig("bt_end", e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
          <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          value={backtestConfig.cost}
          label="거래 비용"
          size="small"
          type="number"
          sx={{ width: "100%" }}
          onChange={handleChange("cost")}
        />
          </Grid>
        </Grid>
        <Grid container item>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              size="small"
              fullWidth
              value={benchMarkValue}
              options={benchmarkInfoList}
              getOptionLabel={(option) => option.bm_name}
              renderInput={(params) => (
                <TextField {...params} label="벤치마크 지수" />
              )}
              onChange={(e, v) => {
                handleChangeConfig(
                  "benchmark",
                  v.map((v) => v.bm_name)
                );
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box>
        <BacktestHistoryDialog
          open={isHistoryDialogOpen}
          onSelect={(config: IBacktestV2StaticConfig) => {
            setIsHistoryDialogOpen(false);
            setBacktestConfigAndStrategies(config);
          }}
          onClose={() => {
            setIsHistoryDialogOpen(false);
          }}
        />
      </Box>
    </StyledCard>
  );
}

export default BacktestNav;
