import { useRef, useState } from "react";
import { Button } from "@mui/material";

import Iconify from "@components/asset/Iconify";
import { OptionPopover } from "@components/chart/OptionPopover";

import { ChartOptionProps } from "./type";
import { OptionContainer } from "./OptionContainer";

export default function ChartOptions({
  config,
  value,
  onChange,
}: ChartOptionProps) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        ref={anchorRef}
        size="small"
        onClick={handleOpen}
        style={{ width: "35px", minWidth: "35px" }}
        sx={{ p: 0, mt: 1 }}
      >
        <Iconify
          icon="ic:twotone-perm-data-setting"
          sx={{ p: 0, width: 20, height: 20 }}
        />
      </Button>
      <OptionPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 400 }}
      >
        <OptionContainer
          value={value}
          config={config}
          onChange={(e) => {
            if (onChange) onChange(e);
            handleClose();
          }}
          onClose={handleClose}
        />
      </OptionPopover>
    </>
  );
}
