import React, { Suspense, useCallback, useEffect, useState } from "react";
import AssetInformationGrid from "@components/grid/AssetInformationGrid";
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import {
  fundOperationListState,
  paginationState,
  selectedRowState,
  fundSearchTextState,
  enableFilterState,
  familyTickerFilterState,
  fundKrQueryFilterState,
  fundKrSelectedQueryFilterState,
  fundKrSelectedClass
} from "@states/fundKr";

import { COLUMNS } from "../../define";
import { Toolbar } from "./Toolbar";
import _ from "lodash";
import { Grid, Checkbox, FormControlLabel } from "@mui/material";
import LoadingButton from "@components/button/LoadingButton";
import { downloadExcel } from "@utils/download";

function AssetViewerTable({ onChangeChecked, searchText, onSelectAsset }) {
  const setSelectedRow = useSetRecoilState(selectedRowState);
  const setPagination = useSetRecoilState(paginationState);
  const setSearchText = useSetRecoilState(fundSearchTextState);

  useEffect(() => {
    requestDebounce(searchText);
  }, [searchText]);

  const requestDebounce = useCallback(
    _.throttle((text) => {
      text = text.trim();
      if (!text) {
        setSearchText(undefined);
      } else if (text && text.length >= 2) {
        setSearchText(text);
      }
    }, 1500),
    [searchText]
  );

  const onPageChange = (page) => {
    setPagination((prev) => ({ ...prev, page: page + 1 }));
  };
  const onPageSizeChange = (pageSize) => {
    setPagination((prev) => ({ ...prev, page_size: pageSize }));
  };

  const assetFundOperation = useRecoilValue(fundOperationListState);

  const params = {
    hideFooter: false,
    pagination: true,
    paginationMode: "server",
    onPageChange,
    onPageSizeChange,
    rowCount: assetFundOperation.total_count,
  };

  return (
    <>
      <AssetInformationGrid
        market={"FUNDKR"}
        onSelectAsset={(selected) => {
          setSelectedRow(selected);
          if (onSelectAsset) {
            onSelectAsset(selected);
          }
        }}
        columns={COLUMNS.FUNDKR}
        data={assetFundOperation.data}
        pagination={params}
        onChangeChecked={onChangeChecked}
      />
    </>
  );
}

export const WrappedComponent = (props) => {
  const [searchText, setSearch] = useState("");
  const pagination = useRecoilValue(paginationState);
  const [isOperationsDownload, setIsOperationsDownload] = useState(false);
  const [enableFilter, setEnableFilter] = useRecoilState(enableFilterState);
  const [familyTickerFilter, setFamilyTickerFilter] = useRecoilState(familyTickerFilterState);
  const [queryFilter, setQueryFilter] = useRecoilState(fundKrQueryFilterState);
  const setSelectedEtfTicker = useSetRecoilState(fundKrSelectedQueryFilterState);
  const setRadioValue = useSetRecoilState(fundKrSelectedClass)
  async function downloadAssetViewerOperationsExcel() {
    setIsOperationsDownload(true);
    const url = "fund/kr/operations/excel";
    const name = "FUND_Operations";
    const filter = queryFilter;
    const familyTickerChecked = familyTickerFilter;
    let params;

    if (familyTickerFilter) {
      params = {
        search: searchText,
        ...pagination,
        filter,
        familyTickerChecked,
      };
    } else {
      params = { search: searchText, ...pagination, filter };
    }
    await downloadExcel(url, name, params);
    setIsOperationsDownload(false);
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md={4}>
          <Toolbar searchText={searchText} setSearchText={setSearch} />
        </Grid>
        <Grid item xs={12} sm container>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableFilter}
                  onChange={(e) => setEnableFilter(e.target.checked)}
                />
              }
              label="상세검색"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={familyTickerFilter}
                  onChange={(e) => {
                    setQueryFilter({});
                    setSelectedEtfTicker({});
                    setRadioValue({});
                    setFamilyTickerFilter(e.target.checked);
                  }}
                />
              }
              label="모펀드만 보기"
            />
          </Grid>
        </Grid>
        <Grid item>
          <LoadingButton
            onClick={downloadAssetViewerOperationsExcel}
            loading={isOperationsDownload}
          >
            운영정보 다운로드
          </LoadingButton>
        </Grid>
      </Grid>
      <Suspense
        fallback={
          <AssetInformationGrid
            market={"FUNDKR"}
            columns={COLUMNS.FUNDKR}
            loading
            data={{ data: [], total_count: 0 }}
            onSelectAsset={undefined}
            onChangeChecked={undefined}
          />
        }
      >
        <AssetViewerTable
          {...props}
          searchText={searchText}
          onChangeChecked={props.onChangeChecked}
          onSelectAsset={props.onSelectAsset}
        />
      </Suspense>
    </>
  );
};

export default WrappedComponent;
