import { atom, selector, selectorFamily } from "recoil";
import _ from "lodash";
import { STATIC_STRATEGY_DEFAULT_3 } from "@pages/backtest/define";
import { IPaginationRequest, IPagination, ITimeseriesDataFrame } from 'types/api';
import {
  IBTSObject,
  IResBacktest2,
  IResBacktestV2History,
  IBacktest2StaticStrategy,
  IBacktestV2StaticConfig,
} from 'types/backtest2';
import axios from "@utils/axios";

export const staticBacktest2MemoState = atom<string[]>({
  key: "staticBacktest2MemoState",
  default: [],
});

export const staticBacktest2PortPriceIndexState = atom<ITimeseriesDataFrame | null>({
  key: "staticBacktest2PortPriceIndexState",
  default: null,
});

export const staticBacktest2OverviewState = atom<IBTSObject[]>({
  key: "staticBacktest2OverviewState",
  default: [],
});

export const staticBacktest2YearlyReturnState = atom<IBTSObject[]>({
  key: "staticBacktest2YearlyReturnState",
  default: [],
});

export const staticBacktest2PortDrawDownState = atom<IBTSObject[]>({
  key: "staticBacktest2PortDrawDownState",
  default: [],
});

export const staticBacktest2TotalCorrelationDailyState = atom<IBTSObject[]>({
  key: "staticBacktest2TotalCorrelationDailyState",
  default: [],
});

export const staticBacktest2TotalCorrelationWeeklyState = atom<IBTSObject[]>({
  key: "staticBacktest2TotalCorrelationWeeklyState",
  default: [],
});

export const staticBacktest2TotalCorrelationMonthlyState = atom<IBTSObject[]>({
  key: "staticBacktest2TotalCorrelationMonthlyState",
  default: [],
});

export const staticBacktest2AssetCorrelationDailyState = atom<IBTSObject[][]>({
  key: "staticBacktest2AssetCorrelationDailyState",
  default: [],
});

export const staticBacktest2AssetCorrelationWeeklyState = atom<IBTSObject[][]>({
  key: "staticBacktest2AssetCorrelationWeeklyState",
  default: [],
});

export const staticBacktest2AssetCorrelationMonthlyState = atom<IBTSObject[][]>({
  key: "staticBacktest2AssetCorrelationMonthlyState",
  default: [],
});

//strategies
export const staticBacktest2StrategiesState = atom<IBacktest2StaticStrategy[]>({
  key: "staticBacktest2StrategiesState",
  default: [_.cloneDeep(STATIC_STRATEGY_DEFAULT_3)],
});

export const staticBacktest2BacktestDetailState = atom<IResBacktest2 | null>({
  key: "staticBacktest2BacktestDetailState",
  default: null,
});

export const staticBacktest2LoadingState = atom<boolean>({
  key: "staticBacktest2LoadingState",
  default: false,
});

export interface staticBacktest2HistoriesQueryParam extends IPaginationRequest {
  user?: string;
}

export const staticBacktest2HistoriesQueryUser = atom<string | undefined>({
  key: "staticBacktest2HistoriesQueryUser",
  default: undefined,
});

export const staticBacktest2HistoriesQueryPage = atom<number>({
  key: "staticBacktest2HistoriesQueryPage",
  default: 0,
});

export const staticBacktest2HistoriesQueryPageSize = atom<number>({
  key: "staticBacktest2HistoriesQueryPageSize",
  default: 5,
});

export const ALL_USER = "all";

export const staticBacktest2HistoriesQuery = selector<IPagination<IResBacktestV2History>>({
  key: "staticBacktest2Histories",
  get: async ({ get }) => {
    let url = "backtest_2/histories?";
    const user = get(staticBacktest2HistoriesQueryUser);
    const page = get(staticBacktest2HistoriesQueryPage) + 1;
    const size = get(staticBacktest2HistoriesQueryPageSize);

    if (!!user && user != ALL_USER) url += `user=${user}&`;
    if (!!page) url += `page=${page}&`;
    if (!!size) url += `size=${size}&`;
    const response = await axios.get(url);
    return response.data;
  },
});

export const staticBacktest2InitialConfig: IBacktestV2StaticConfig = {
  cost: 0,
  name: "",
  bt_end: "",
  bt_start: "",
  benchmark: [""],
  data_freq: "D",
  port_list: [],
  strategies: [],
  market: "kr",
};

export const staticBacktest2ConfigState = atom<IBacktestV2StaticConfig>({
  key: "staticBacktest2ConfigState",
  default: staticBacktest2InitialConfig,
});

export const staticBacktest2ConfigMarketQuery = selector<string | undefined>({
  key: "staticBacktest2ConfigMarketQuery",
  get: async ({ get }) => {
    return get(staticBacktest2ConfigState).market
  },
});
