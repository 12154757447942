import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useRecoilValue } from "recoil";
import { Card, Checkbox, FormGroup, FormControlLabel, Stack } from "@mui/material";
import { etfKrDailyState } from "@states/etfKr";
import { ffDate } from "@utils/formatTime";

export default function CandleChart() {
  const etfKrDailyData = useRecoilValue(etfKrDailyState);
  const [isOptionChecked, setIsOptionChecked] = useState(false);

  const candleData = [
    {
      data: etfKrDailyData?.rows.map((value) => {
        if (
          value["시가(원)"] === 0 ||
          value["고가(원)"] === 0 ||
          value["저가(원)"] === 0 ||
          value["종가(원)"] === 0
        ) {
          return {
            x: new Date(value["날짜"]).getTime(),
            y: [null, null, null, null],
          };
        } else {
          return {
            x: new Date(value["날짜"]).getTime(),
            y: [
              value["시가(원)"],
              value["고가(원)"],
              value["저가(원)"],
              value["종가(원)"],
            ],
          };
        }
      }),
    },
  ];

  const barData = [
    {
      name: "거래량",
      data: etfKrDailyData?.rows.map((val) => {
        return [new Date(val["날짜"]).getTime(), val["거래량"]];
      }),
    },
  ];

  const candleChartOptions = {
    chart: {
      id: "candles",
      animations: { enabled: false },
      zoom: {
        type: "xy",
        enabled: false,
        autoScaleYaxis: false,
      },
    },
    title: {
      text: "OHLC 캔들 차트",
      align: "center",
    },
    plotOptions: {
      candlestick: {
        colors: {
          upward: "#E33333",
          downward: "#476CEC",
        },
        wick: {
          useFillColor: true,
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (val) {
          return ffDate(val);
        },
      },
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
    stroke: {
      width: 1,
    },
  };

  const barChartOptions = {
    chart: {
      brush: {
        enabled: true,
        target: "candles",
        autoScaleYaxis: true,
      },
      selection: {
        enabled: false,
      },
    },
    title: {
      text: "거래량 바 차트",
      align: "center",
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (val) {
          return ffDate(val);
        },
      },
    },
    tooltip: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#228B22"],
  };

  return (
    <Card sx={{ px: 4, p: 2, pb: 2, textAlign: "center" }}>
      <FormGroup sx={{ marginLeft: "20px", marginTop: 1 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isOptionChecked}
              onChange={(e) => {
                setIsOptionChecked(e.target.checked);
              }}
            />
          }
          label="거래량 옵션"
        />
      </FormGroup>
      <Stack spacing={3}>
        <ReactApexChart
          series={candleData}
          options={candleChartOptions}
          type="candlestick"
          height={350}
        />
        {isOptionChecked && (
          <ReactApexChart
            series={barData}
            options={barChartOptions}
            type="bar"
            height={150}
          />
        )}
      </Stack>
    </Card>
  );
}
