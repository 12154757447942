import { Fragment } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  staticBacktest2StrategiesState,
  staticBacktest2ConfigMarketQuery,
} from "@states/staticBacktest2";
import { simpleOperationListState } from "@states/etfKr";
import { usSimpleOperationListState } from "@states/etfUs";
import { StyledLabel } from "@components/ui/styles";

import producer from "immer";
import {
  Box,
  Button,
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Autocomplete,
  Grid,
} from "@mui/material";
import { autocompleteClasses } from "@mui/material/Autocomplete";

import styled from "styled-components";

import { Delete as DeleteIcon } from "@mui/icons-material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { Help as HelpIcon } from "@mui/icons-material";
import Popper from "@mui/material/Popper";

export const FadeColorAnimate = styled.div`
  @keyframes color_change {
    from {
      background-color: #aaaaaa33;
    }
    to {
      background-color: none;
    }
  }

  background-color: none;
  -webkit-animation: color_change 1s;
  -webkit-animation: color_change 1s;
  -webkit-animation: color_change 1s;
  -webkit-animation: color_change 1s;
  -webkit-animation: color_change 1s;
`;

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: "#eaeaef",
  },
});

const WeightInput = ({ value, onChange }) => {
  return (
    <TextField
      label="비율"
      size="small"
      sx={{ width: "100px" }}
      value={value}
      type="number"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      onChange={onChange}
    />
  );
};

const sumAsset = (strategy) => {
  const sum = strategy.assets
    .map((v) => Number(v.weight))
    .reduce((acc, val) => acc + val, 0)
    .toFixed(2);

  return ` ${sum} %`;
};

const StrategyAssetWeight = ({ strategy, strategyIndex, onOpenDialog }) => {
  const market = useRecoilValue(staticBacktest2ConfigMarketQuery);
  const etfUsOperations = useRecoilValue(usSimpleOperationListState);
  const etfKrOperations = useRecoilValue(simpleOperationListState);
  const [strategies, setStrategies] = useRecoilState(staticBacktest2StrategiesState);

  const getAssetsValue = (assetIndex, effector) => {
    setStrategies((prevState) =>
      producer(prevState, (draft) => {
        effector(
          draft,
          draft[strategyIndex].assets,
          draft[strategyIndex].assets[assetIndex]
        );
      })
    );
  };

  const handleSelectChange = (assetIndex) => (e, value) => {
    getAssetsValue(assetIndex, (strategy, assets, asset) => {
      if (value) {
        asset.id = value.rf_id;
        asset.name = value.product_name;
      } else if (value === null) {
        asset.id = "";
        asset.name = "";
      }
    });
  };

  const handleWeightChange = (assetIndex) => (e) => {
    getAssetsValue(assetIndex, (strategy, assets, asset) => {
      asset.weight = e.target.value;
    });
  };

  const handleMoveIndex =
    (pos, dir = 1) =>
    (e) => {
      getAssetsValue(0, (strategy, assets, asset) => {
        const next = pos + dir;
        [assets[next], assets[pos]] = [assets[pos], assets[next]];
      });
    };

  const handleAddAsset = () => {
    getAssetsValue(0, (strategy, assets, asset) => {
      assets.push({ id: "", name: "", weight: 0 });
    });
  };

  const removeAsset = (assetIndex) => {
    getAssetsValue(assetIndex, (strategy, assets, asset) => {
      assets.splice(assetIndex, 1);
    });
  };

  return (
    <>
      <StyledLabel style={{ marginBottom: 10 }}>종목 구성</StyledLabel>
      <Grid container alignContent="center" spacing={0.5}>
        {strategy.assets.map((asset, assetIndex) => (
          <Fragment key={assetIndex}>
            <Grid item md={6} xs={12}>
              <Autocomplete
                size="small"
                sx={{ width: "100%", mb: 0.9 }}
                options={
                  market === "kr" ? etfKrOperations : etfUsOperations
                }
                value={asset.name}
                renderInput={(params) => (
                  <TextField {...params} label="종목을 입력해주세요" />
                )}
                PopperComponent={StyledPopper}
                onChange={handleSelectChange(assetIndex)}
              />
            </Grid>
            <Grid container item md={6} xs={12} spacing={1} sx={{ pb: 1.5 }}>
              <Grid item>
                <WeightInput
                  value={asset.weight}
                  onChange={handleWeightChange(assetIndex)}
                />
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => removeAsset(assetIndex)}
                  disabled={strategy.assets.length === 1}
                >
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="help"
                  size="small"
                  disabled={asset.name === ""}
                  onClick={() => {
                    onOpenDialog(asset);
                  }}
                >
                  <HelpIcon />
                </IconButton>
              </Grid>
              <Grid item>
                <Stack direction="column">
                  <IconButton
                    sx={{ m: 0, p: 0 }}
                    style={{ marginLeft: "0px" }}
                    onClick={handleMoveIndex(assetIndex, -1)}
                    disabled={assetIndex === 0}
                  >
                    <KeyboardArrowUpIcon style={{ marginBottom: "-3px" }} />
                  </IconButton>
                  <IconButton
                    sx={{ m: 0, p: 0 }}
                    style={{ marginLeft: "0px" }}
                    onClick={handleMoveIndex(assetIndex, +1)}
                    disabled={
                      strategies[strategyIndex].assets.length - 1 === assetIndex
                    }
                  >
                    <KeyboardArrowDownIcon style={{ marginTop: "-3px" }} />
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Grid container spacing={1} direction="column">
        <Grid item>
          <StyledLabel>
            포트폴리오 종목 비율 합:
            {sumAsset(strategy)}
          </StyledLabel>
        </Grid>
        <Grid item>
          <Box>
            <Button
              sx={{ width: "280px" }}
              variant="outlined"
              onClick={handleAddAsset}
            >
              자산 추가
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default StrategyAssetWeight;
