import { Box, Grid } from "@mui/material";
import PriceLineChart from "@components/chart/PriceLineChart";
import { useRecoilValue } from "recoil";
import { assetComparePriceState, assetCompareDateRangeState, ASSET_COMPARISON_API } from "../../../states/asset";
import LoadingButton from "@components/button/LoadingButton";
import { useState } from "react";
import { downloadExcelPost } from "@utils/download";

export default function AssetDetailPage({
  market,
  ticker,
  assetType,
  enableDownload = true,
}) {
  const artificailIndexData = useRecoilValue(assetComparePriceState);
  const assetCompareDateRange = useRecoilValue(assetCompareDateRangeState);
  const [isDailyDownload, setIsDailyDownload] = useState(false);
  const [isWeeklyDownload, setIsWeeklyDownload] = useState(false);
  const [isMonthlyDownload, setIsMonthlyDownload] = useState(false);
  if (!artificailIndexData) {
    return <></>;
  }
  const { values, index } = artificailIndexData;
  

  async function downloadExtraIndexExcel(period) {
    const loadFunc = (period === "D") ? setIsDailyDownload :
                    (period === "W") ? setIsWeeklyDownload :
                    (period === "M") ? setIsMonthlyDownload : () => {};
    const fileSuffix = (period === "D") ? "Daily" :
                      (period === "W") ? "Weekly" :
                      (period === "M") ? "Monthly" : "";
    let url = `${assetType}/${market}/asset_compare/excel`;

    if (ASSET_COMPARISON_API.includes(assetType)) {
      url = `data/asset/compare/excel`;
    }
    
    const filePrefix = "비교데이터";
    loadFunc(true);

    await downloadExcelPost(url, `${filePrefix}-${fileSuffix}`, {
      tickers: ticker,
      period,
      begin: assetCompareDateRange.begin,
      end: assetCompareDateRange.end,
    });

    loadFunc(false);
  }

  return (
    <>
      {enableDownload && (
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <LoadingButton
              onClick={() => downloadExtraIndexExcel("D")}
              loading={isDailyDownload}
              size={"small"}
            >
              일일 데이터 다운로드
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={() => downloadExtraIndexExcel("W")}
              loading={isWeeklyDownload}
              size={"small"}
            >
              주간 데이터 다운로드
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              onClick={() => downloadExtraIndexExcel("M")}
              loading={isMonthlyDownload}
              size={"small"}
            >
              월간 데이터 다운로드
            </LoadingButton>
          </Grid>
        </Grid>
      )}

      <Box sx={{ height: 500, m: 0, my: 5 }}>
        <PriceLineChart index={index} data={values} height={400} />
      </Box>
    </>
  );
}
