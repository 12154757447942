import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useEffect, useCallback } from "react";

// material
import { Box } from "@mui/material";

// utils
import track from "../utils/analytics";

const Page = (({ children, title = "", ...other } ) => {
  const { pathname } = useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box {...other} sx={{ pl: 3, pr: 3 }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  );
});

export default Page;
