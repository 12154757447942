import { Stack } from "@mui/material";
import PriceLineChart from "@components/chart/PriceLineChart";

type Props = {
  data: {
    index?: any;
    values: any; 
  };
  height?: number;
  extraChartOptions?: object;
};

export default function TimeSeriesChart(props: Props) {
  const { data, height = 400, extraChartOptions = {} } = props;
  const defaultChartOptions = {
    chart: {
      id: "timeSeriesChart",
      animations: {
        enabled: false,
      },
      zoom: {
        autoScaleYaxis: true,
      },
      sparkline: {
        enabled: false,
      },
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'index',
            dateFormatter: (timestamp) => timestamp ? new Date(timestamp).toISOString().split("T")[0] : "",
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
        autoSelected: 'zoom' 
      },
    }
  };
  const chartOptions = { ...defaultChartOptions, ...extraChartOptions };
  return (
    <Stack sx={{ width: "100%" }}>
      <PriceLineChart
        index={data.index}
        data={data.values}
        options={chartOptions}
        height={height}
      />
    </Stack>
  );
}
